// https://www.bennadel.com/blog/3579-using-pure-pipes-to-generate-ngfor-trackby-identity-functions-in-angular-7-2-7.htm

import { Pipe, PipeTransform } from '@angular/core';

interface TrackByFunctionCache {
	[propertyName: string]: <T>(index: number, item: T) => any;
}

// Since the resultant TrackBy functions are based purely on a static property name, we
// can cache these Functions across the entire app. No need to generate more than one
// Function for the same property.

@Pipe({
	name: 'trackByProperty',
	pure: true,
})
export class TrackByPropertyPipe implements PipeTransform {
	cache: TrackByFunctionCache = Object.create(null);

	// I return a TrackBy function that plucks the given property from the ngFor item.
	public transform(propertyName: string): any {
		// Ensure cached function exists.
		if (!this.cache[propertyName]) {
			this.cache[propertyName] = function trackByProperty<T>(index: number, item: T): any {
				if (propertyName === 'index') {
					return index;
				} else {
					return item[propertyName];
				}
			};
		}

		return this.cache[propertyName];
	}
}
