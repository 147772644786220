<div class="modal-header">
	<h3 class="modal-title" translate="NEARYBY"></h3>
	<div class="modal-itemname" [innerText]="settings.item.name"></div>
</div>

<nearby class="modal-body fm-audit" [obj]="settings.item" [objtype]="settings.objtype"></nearby>

<div class="modal-footer">
	<button class="fm-button" (click)="close()" translate="CLOSE"></button>
</div>
