import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ControlPanelDirective } from './control-panel.directive';
import { FmExistsDirective } from './fm-exists.directive';
import { FmGlobalDirective } from './fm-global.directive';
import { FmPermissionDirective } from './fm-permission.directive';
import { FmSettingDirective } from './fm-settings.directive';
import { FmUniqueDirective } from './fm-unique.directive';
import { MaxValidatorDirective } from './max-validator.directive';
import { MinValidatorDirective } from './min-validator.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { ZeroBlankDirective } from './zero-blank.directive';
import { NgModelChangeDebouncedDirective } from './ngmodelchange.directive';

// IMPORT DIRECTIVES
const directiveArray = [
	ControlPanelDirective,
	FmExistsDirective,
	FmPermissionDirective,
	FmSettingDirective,
	FmUniqueDirective,
	MaxValidatorDirective,
	MinValidatorDirective,
	NgModelChangeDebouncedDirective,
	StopPropagationDirective,
	ZeroBlankDirective,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		//
		FmGlobalDirective,
	],
	declarations: directiveArray,
	exports: [...directiveArray, FmGlobalDirective],
})
export class DirectiveModule {}
