import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '@fm/services';

@Component({
	selector: 'raw-bts-dialog',
	templateUrl: './rawbts-dialog.component.html',
})
export class RawBTSDialogComponent implements OnInit {
	bts;
	detail;
	timer;
	mode: string;

	isLoading: boolean;
	isFailed: boolean;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<RawBTSDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService
	) {}

	ngOnInit(): void {
		this.timer = {
			isRunning: false,
		};

		this.bts = {
			view: 'assets',
		};

		this.detail = {
			data: [],
			view: 'rawjson',
		};

		this.loadData();
	}

	loadDetail(type: string): void {
		this.bts.view = type;
		this.detail.data = this.bts[type];
	}

	loadData(): void {
		this.timer.isRunning = true;

		const params = {
			sort: '-created',
			per_page: 1,
		};

		this.FM_Api.connect('rawbts').query(params, (res) => {
			this.bts.created = res[0].created;

			this.bts.assets = res[0].content.filter(({ type }) => type === 'iBeacon');
			this.bts.devices = res[0].content.filter(({ type }) => type === 'Unknown');

			this.loadDetail(this.bts.view);

			this.timer = setTimeout(() => {
				this.loadData();
			}, 5000);
		});
	}

	toggleTimer(): void {
		if (this.timer.isRunning) {
			this.timer.isRunning = false;
			clearTimeout(this.timer);
		} else {
			this.loadData();
		}
	}

	reload(): void {
		this.timer.isRunning = false;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.loadData();
		}, 0);
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close();
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
