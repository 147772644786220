<div class="modal-header">
	<h3 class="modal-title" translate="ATTENTION"></h3>
</div>

<div class="modal-body">
	<h4 class="confirm-title" [translate]="message"></h4>
	<h5 *ngIf="obj" [innerText]="obj.name"></h5>

	<div class="confirm-message" translate="CONFIRM_CONTINUE"></div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
