import { Pipe, PipeTransform } from '@angular/core';
import { addMinutes, format } from 'date-fns';
import { DATE_NULL } from '../services/global.service';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
	transform(value: string, monthOnly?: boolean): string {
		if (value && value !== '' && value !== DATE_NULL) {
			const d = new Date(value);
			const utcDate = addMinutes(d, d.getTimezoneOffset());

			const formatString = monthOnly ? 'MMM, yyyy' : 'MMM do, yyyy';

			return format(utcDate, formatString);
		} else {
			return '---';
		}
	}
}
