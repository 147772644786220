<div class="password-help">
	<div class="password-inputs">
		<div class="form-row">
			<label class="label" translate="PASSWORD"></label>
			<div class="field-editor">
				<input
					#passwordMain="ngModel"
					class="fm-input password-input"
					name="setPassword"
					type="password"
					placeholder="{{ 'PASSWORD' | translate }}"
					[required]="true"
					[(ngModel)]="password"
					(ngModelChange)="updatePassword()"
					autocomplete="new-password" />

				<span class="input-action" (click)="togglePassword()">
					<i *ngIf="!showingPassword" class="fa fa-eye"></i>
					<i *ngIf="showingPassword" class="fa fa-eye-slash"></i>
				</span>

				<div class="strength-meter">
					<div class="strength-meter-fill" [attr.data-strength]="passwordStrength"></div>
				</div>

				<div class="field-message" *ngIf="passwordMain.pending" translate="VERIFYING"></div>
				<div class="error-message" *ngIf="warning" [innerText]="warning"></div>
				<div
					class="error-message"
					*ngIf="passwordMain.errors?.matchUsername"
					translate="ERROR_PASSWORD_MATCH_USERNAME"></div>
				<div class="error-message" *ngIf="checks.reused" translate="PASSWORD_REUSED"></div>
				<div
					class="error-message"
					*ngIf="checks.error"
					translate="ERROR_VALIDATING_PASSWORD"></div>
			</div>
		</div>
		<div class="form-row">
			<label class="label wrapped" translate="CONFIRM_PASSWORD"></label>
			<div class="field-editor">
				<input
					#passwordMatch
					class="fm-input password-input"
					name="confimPassword"
					type="password"
					placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
					[(ngModel)]="passwordConfirm"
					(ngModelChange)="updatePassword()" />
			</div>
		</div>
	</div>
	<div class="password-helper">
		<ul>
			<li [ngClass]="checks.lower">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_LOWERCASE"></span>
			</li>
			<li [ngClass]="checks.upper">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_UPPERCASE"></span>
			</li>
			<li [ngClass]="checks.number">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_NUMBER"></span>
			</li>
			<li [ngClass]="checks.length">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_LENGTH"></span>
			</li>
			<li [ngClass]="checks.strength">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_STRENGTH"></span>
			</li>
			<li [ngClass]="checks.match">
				<i class="fa fa-times"></i><i class="fa fa-check"></i
				><span translate="PASSWORD_MATCH"></span>
			</li>
		</ul>
	</div>
</div>
