import { Component, Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'custom-snackbar',
	template: `response timed out
		<div class="loading_dots"><span></span><span></span><span></span></div>`,
})
export class CustomSnackBarComponent {}

@Injectable({ providedIn: 'root' })
export class MessageService {
	private _messageStatus: Subject<boolean> = new Subject<boolean>();

	private messageTimeout = 3000; // GROWL WAS 3500

	constructor(
		private snackBar: MatSnackBar,
		private translate: TranslateService
	) {}

	addSuccessMessage(message: string): void {
		const currentMessage = this.snackBar.open(this.translate.instant(message), '', {
			horizontalPosition: 'end',
			verticalPosition: 'top',
			duration: this.messageTimeout,
			panelClass: ['fm-alert', 'alert-success'],
		});

		currentMessage.afterOpened().subscribe(() => {
			this._messageStatus.next(true);
		});

		currentMessage.afterDismissed().subscribe(() => {
			this._messageStatus.next(false);
		});
	}

	addErrorMessage(message: string): void {
		const currentMessage = this.snackBar.open(this.translate.instant(message), '', {
			horizontalPosition: 'end',
			verticalPosition: 'top',
			duration: this.messageTimeout,
			panelClass: ['fm-alert', 'alert-error'],
		});

		currentMessage.afterOpened().subscribe(() => {
			this._messageStatus.next(true);
		});

		currentMessage.afterDismissed().subscribe(() => {
			this._messageStatus.next(false);
		});
	}

	addTimeoutMessage(): void {
		const currentMessage = this.snackBar.openFromComponent(CustomSnackBarComponent, {
			horizontalPosition: 'end',
			verticalPosition: 'top',
			duration: this.messageTimeout,
			panelClass: ['fm-alert', 'alert-error'],
		});

		currentMessage.afterOpened().subscribe(() => {
			this._messageStatus.next(true);
		});

		currentMessage.afterDismissed().subscribe(() => {
			this._messageStatus.next(false);
		});
	}

	messageUpdate(): Observable<any> {
		return this._messageStatus.asObservable();
	}
}
