<form
	#modalForm="ngForm"
	name="modalForm"
	class="modal-form"
	(ngSubmit)="modalForm.valid && ok()"
	novalidate="novalidate"
	autocomplete="nope">
	<div class="modal-header">
		<h3 class="modal-title" translate="NEW_VALIDATION"></h3>
	</div>

	<div class="modal-body">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="VALIDATION_TYPE"></label>
				<div class="field-help" translate="VALIDATION_TYPE_HELP"></div>
			</div>
			<div class="field-editor">
				<select class="fm-select" [(ngModel)]="validationrule.objType" (change)="setType()">
					<option [value]="undefined">--Choose Type--</option>
					<option
						[value]="op"
						*ngFor="let op of validationTypes"
						[translate]="op"></option>
				</select>
			</div>
		</div>

		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="VALIDATION_PROP"></label>
					<div class="field-help" translate="VALIDATION_PROP_HELP"></div>
				</div>
				<div class="field-editor typeahead">
					<select class="fm-select" [(ngModel)]="validationrule.prop">
						<option [value]="undefined">--Choose Prop--</option>
						<option
							[value]="op"
							*ngFor="let op of validationOptions"
							[translate]="op"></option>
					</select>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer" *ngIf="editor.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!editor.isBusy">
		<button class="fm-button" [disabled]="hasErrors()" translate="SAVE"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
