<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="GOOGLE_AUTH"></h3>
	</div>

	<div class="modal-body item-settings">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="CLIENT_ID"></label>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="first"
					type="text"
					#clientId="ngModel"
					[(ngModel)]="googleapi.client_id"
					placeholder="{{ 'CLIENT_ID' | translate }}"
					required />
			</div>
		</div>

		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="CLIENT_SECRET"></label>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="last"
					type="text"
					#clientSecret="ngModel"
					[(ngModel)]="googleapi.client_secret"
					placeholder="{{ 'CLIENT_SECRET' | translate }}"
					required />
			</div>
		</div>

		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="PROJECT_ID"></label>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="last"
					type="text"
					#projectId="ngModel"
					[(ngModel)]="googleapi.project_id"
					placeholder="{{ 'PROJECT_ID' | translate }}" />
			</div>
		</div>

		<div *ngIf="showFrame">
			{{ googleapi.url }}
			<iframe [src]="googleapi.url"></iframe>
		</div>
	</div>

	<div class="modal-footer">
		<button class="fm-button" translate="CONNECT"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
