import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// IMPORT SERVICES
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { BroadcastService } from './services/broadcast.service';
import { ConfigService } from './services/config.service';
import { DataService } from './services/data.service';
import { ExperienceService } from './services/experience.service';
import { FilterService } from './services/filters.service';
import { GlobalService } from './services/global.service';
import { InterceptorService } from './services/interceptor.service';
import { LogService } from './services/log.service';
import { MapsService } from './services/maps.service';
import { MenuService } from './services/menu.service';
import { CustomSnackBarComponent, MessageService } from './services/messages.service';
import { PlatformtaskService } from './services/platformtask.service';
import { ProfileService } from './services/profile.service';
import { SearchService } from './services/search.service';
import { SortService } from './services/sort.service';
import { ToolsService } from './services/tools.service';
import { SettingsService } from './services/settings.service';
import { UtilsService } from './services/utils.service';

// MAIN COMPONENTS
import { AppBaseComponent } from './components/app-base.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/mainmenu/mainmenu.component';
import { NewsComponent } from './components/news/news.component';

import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarDialogComponent } from './components/calendar/calendar-dialog.component';

// IMPORT MODULES

import { SharedModule } from './components/shared/shared.module';

import { DialogsModule } from './components/dialogs/dialogs.module';
import { NearbyModule } from './components/nearby/nearby.module';

import { NotificationsModule } from './components/notifications/notifications.module';

// import { UserDeviceModule } from './components/userdevice/userdevice.module';

// ROUTES
import { AppRoutingModule } from './app.routes';
import { LoginComponent } from './components/login/login.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,

		// ROUTIUNG
		AppRoutingModule,

		// FM MODULES
		SharedModule,

		DialogsModule,
		NearbyModule,
		NotificationsModule,
		// UserDeviceModule,
		//
		HeaderComponent,
		LoginComponent,
		MainMenuComponent,
		NewsComponent,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: 'auto', hasBackdrop: true } },
		ApiService,
		AppService,
		AuthService,
		BroadcastService,
		ConfigService,
		DataService,
		ExperienceService,
		FilterService,
		GlobalService,
		LogService,
		MapsService,
		MenuService,
		MessageService,
		PlatformtaskService,
		ProfileService,
		SearchService,
		SortService,
		ToolsService,
		SettingsService,
		UtilsService,
	],
	declarations: [
		AppBaseComponent,

		CustomSnackBarComponent,

		CalendarComponent,
		CalendarDialogComponent,
	],
	bootstrap: [AppBaseComponent],
})
export class AppModule {
	/*
	constructor(router: Router) {
		// Use a custom replacer to display function names in the route configs
		// const replacer = (key, value) => (typeof value === 'function') ? value.name : value;
		// console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
	}
	*/
}
