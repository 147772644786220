import { Component, Input, OnInit } from '@angular/core';

import { ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService, LogService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { find } from 'lodash-es';

interface Tooltip {
	objType: ProfileType;
	objProp: string;
	tooltip: string;
}

@Component({
	selector: 'tooltip',
	template: `<div class="field-help" *ngIf="!showTooltip">{{ getHelpText() | translate }}</div>
		<div class="field-help" *ngIf="showTooltip" [innerHTML]="tooltip.tooltip"></div> `,
})
export class TooltipComponent implements OnInit {
	@Input() type: string;
	@Input() prop: string;

	private global: GlobalStore;

	showTooltip: boolean;
	tooltip: Tooltip;

	constructor(
		private FM_Log: LogService,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		const tt = { objType: this.type, objProp: this.prop };
		this.tooltip = find(this.global.tooltips, tt);
		this.showTooltip = false;

		if (!this.tooltip) {
			this.FM_Log.info('generateTooltip', tt);
			this.FM_Api.connect('tooltips').post(tt, (res) => {
				this.global.tooltips.push(res);
			});
		} else if (this.tooltip && this.tooltip.tooltip !== '') {
			if (this.global.user.isDev) {
				this.showTooltip = true;
			}
		}
	}

	getHelpText(): string {
		return this.type.toUpperCase() + '_' + this.prop.toUpperCase() + '_HELP';
	}
}
