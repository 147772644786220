import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { FilterStatus, ProfileType } from '@fmlib/enums';
import { isEqual } from 'lodash-es';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-status',
	templateUrl: './filter-status.component.html',
	styleUrls: ['./filter-status.component.less'],
})
export class FilterStatusComponent implements OnInit, OnChanges {
	@Input() filter: FilterStatus[];
	@Input() objtype: ProfileType;

	@Output() filterChange = new EventEmitter<FilterStatus[]>();
	@Output() update = new EventEmitter<void>();

	private _currentFilter: FilterStatus[];

	options: FilterStatus[];
	FilterStatus = FilterStatus;

	ngOnInit(): void {
		this.options = [];

		if (this.objtype === ProfileType.GATEWAY) {
			this.options = [
				FilterStatus.ACTIVE,
				FilterStatus.NOACTIVITY,
				FilterStatus.INACTIVE,
				FilterStatus.SPARE,
			];
		} else {
			this.options = [FilterStatus.ACTIVE, FilterStatus.ARCHIVED, FilterStatus.INACTIVE];
		}
	}

	ngOnChanges(): void {
		if (!this.filter?.length) {
			if (this.objtype === ProfileType.GATEWAY) {
				this.filter = [FilterStatus.ACTIVE, FilterStatus.NOACTIVITY];
			} else {
				this.filter = [FilterStatus.ACTIVE, FilterStatus.ARCHIVED, FilterStatus.INACTIVE];
			}
		}

		this._currentFilter = [...this.filter];
	}

	close(): void {
		this.clearFilter();
		this.filterUpdate();
	}

	filterUpdate(): void {
		if (!isEqual(this._currentFilter, this.filter)) {
			this._currentFilter = [...this.filter];

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	toggleStatus(status: FilterStatus): void {
		if (this.filter.includes(status)) {
			const index = this.filter.indexOf(status);
			this.filter.splice(index, 1);
		} else {
			this.filter.push(status);
		}

		this.filterUpdate();
	}

	hasFilter(status: FilterStatus): boolean {
		return this.filter.includes(status);
	}

	showAll(): void {
		this.filter = [];
		this.filterUpdate();
	}

	clearFilter(): void {
		this.filter = [];
	}
}
