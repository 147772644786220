import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

@Directive({
	selector: '[fmPermission]',
})
export class FmPermissionDirective {
	@Input() set fmPermission(val) {
		this.perm = val;
		this.updateView();
	}

	private global: GlobalStore;
	private perm;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	/*
	ngOnInit(): void {
		
		// MOVE TO OBSERVABLE 
		this.userService.currentUser.subscribe(user => {
			this.currentUser = user;
			this.updateView();
		});
		
		// this.updateView();
	}
	*/

	updateView(): void {
		if (this.checkPermissions()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	checkPermissions(): boolean {
		const permissionList = this.global.permissions[this.perm.key];
		const hasPermission =
			permissionList &&
			permissionList.actions &&
			permissionList.actions.allowed.indexOf(this.perm.value) !== -1;

		return hasPermission;
	}
}
