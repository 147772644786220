<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="ADD_CONTACT"></h3>
	</div>

	<div class="modal-body">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="NAME"></label>
				<div class="field-help" translate="NAME_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					type="text"
					[(ngModel)]="contact.firstname"
					placeholder="{{ 'FIRST' | translate }}" />
			</div>
		</div>
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="LAST"></label>
				<div class="field-help" translate="LAST_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					type="text"
					[(ngModel)]="contact.lastname"
					placeholder="{{ 'LAST' | translate }}" />
			</div>
		</div>
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="EMAIL"></label>
				<div class="field-help" translate="EMAIL_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					#email="ngModel"
					class="fm-input"
					name="email"
					type="email"
					[(ngModel)]="contact.email"
					placeholder="{{ 'EMAIL' | translate }}"
					required />

				<input-status class="input-status" [obj]="email"></input-status>

				<div *ngIf="email.errors" class="error-message">
					<div *ngIf="email.errors?.required" translate="REQUIRED_FIELD"></div>
					<div *ngIf="email.errors?.email" translate="EMAIL_ERROR"></div>
				</div>
			</div>
		</div>
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="TYPE"></label>
				<div class="field-help" translate="CONTACT_TYPE_HELP"></div>
			</div>
			<div class="field-editor">
				<select
					class="fm-select"
					name="contactType"
					#contactType="ngModel"
					[(ngModel)]="contact.contactType"
					required>
					<option [value]="undefined" translate="SELECT_TYPE"></option>
					<option
						[value]="op.key"
						*ngFor="let op of typeOptions"
						[translate]="op.label"></option>
				</select>
				<span class="input-icons">
					<i class="fa-light fa-circle-chevron-down"></i>
				</span>
				<span
					class="error-message"
					*ngIf="contactType.invalid"
					translate="REQUIRED_FIELD"></span>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button class="fm-button" translate="OK"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
