import { Injectable } from '@angular/core';
import { PlatformTask, PlatformTaskComment, PlatformTaskStatus } from '@fmlib/interfaces';

import { cloneDeep, orderBy } from 'lodash-es';

@Injectable()
export class PlatformtaskService {
	getStatusOptions(): PlatformTaskStatus[] {
		return [
			PlatformTaskStatus.NEW,
			PlatformTaskStatus.VERIFYING,
			PlatformTaskStatus.AWAITING,
			PlatformTaskStatus.INPROGRESS,
			PlatformTaskStatus.COMPLETE,
			PlatformTaskStatus.CANCELLED,
		];
	}
	getTaskTypes(): string[] {
		return ['sites', 'assetranges', 'shippedto'];
	}

	getCurrentComment(platformtask: PlatformTask): PlatformTaskComment {
		// ALWAYS RETURN THE FIRST COMMENT NOW?
		const comment = platformtask.formattedComments[0];
		return comment;
	}

	formatTask(platformtask: PlatformTask): PlatformTask {
		if (platformtask && !platformtask.isParsed) {
			platformtask.formattedComments = cloneDeep(platformtask.comments);
			// FORMAT THE ITEM COMMENTS
			platformtask.formattedComments.forEach((comm, index) => {
				comm.index = index;
				comm.comment = JSON.parse(comm.comment);

				comm.issues = comm.issues.reduce((list, issue) => {
					if (issue) {
						try {
							list.push(JSON.parse(issue));
						} catch (e) {
							console.info('parse issue', issue);
						}
					}

					return list;
				}, []);
			});

			console;

			platformtask.formattedComments = orderBy(
				platformtask.formattedComments,
				'created',
				'desc'
			);
			platformtask.isParsed = true;
		}

		return platformtask;
	}

	updateStatus(platformtask: PlatformTask, status: PlatformTaskStatus): void {
		platformtask.status = status;

		// reset flags
		platformtask.isPickedUp = false;
		platformtask.isVerified = false;
		platformtask.isAccepted = false;
		platformtask.isComplete = false;

		switch (platformtask.status) {
			case PlatformTaskStatus.VERIFYING:
				platformtask.isVerified = false;
				platformtask.isPickedUp = true;
				break;
			case PlatformTaskStatus.AWAITING:
				platformtask.isPickedUp = true;
				platformtask.isVerified = true;
				break;
			case PlatformTaskStatus.INPROGRESS:
				platformtask.isPickedUp = true;
				platformtask.isVerified = true;
				platformtask.isAccepted = true;
				break;
			case PlatformTaskStatus.COMPLETE:
				platformtask.isPickedUp = true;
				platformtask.isVerified = true;
				platformtask.isAccepted = true;
				platformtask.isComplete = true;
				break;
			default:
				break;
		}
	}
}
