import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stateAbbrev' })
export class StateAbbrevPipe implements PipeTransform {
	transform(value: string): string {
		let abbrev;

		if (value && value.length > 2) {
			const a = value.split(' ');

			if (a.length > 1) {
				abbrev = a[0].substring(0, 1) + a[1].substring(0, 1);
			} else {
				abbrev = value.substring(0, 2);
			}
		} else {
			abbrev = value;
		}

		return abbrev;
	}
}
