<div class="status-group" matTooltip="{{ statusText }}">
	<!-- ACTIVE -->
	<div *ngIf="item.isActive">
		<label translate="ACTIVE"></label>

		<!-- NOT APP, BEACON, NFC, GATEWAY, PUSH, EXPERIENCE, JOURNEY, ADMINUSER -->
		<ng-container
			*ngIf="
				objtype !== ProfileType.BEACON &&
				objtype !== ProfileType.NFC &&
				objtype !== ProfileType.GATEWAY &&
				objtype !== ProfileType.PUSH &&
				objtype !== ProfileType.EXPERIENCE &&
				objtype !== ProfileType.JOURNEY &&
				objtype !== ProfileType.ADMINUSER
			">
			<div class="detail-info"><span class="status-dot active"></span></div>
		</ng-container>

		<!-- BEACON, NFC ACTIVE -->
		<ng-container *ngIf="objtype === ProfileType.BEACON || objtype === ProfileType.NFC">
			<div class="detail-info" *ngIf="!item.site || item.site === ''">
				<i
					class="fa fa-exclamation-triangle"
					matTooltip="{{ 'NO_LOCATION_SET' | translate }}"
					matTooltipPosition="left"></i>
			</div>
			<div class="detail-info" *ngIf="item.site && item.site !== ''">
				<span class="status-dot active"></span>
			</div>
		</ng-container>

		<!--  GATEWAY ACTIVE CHECK -->
		<ng-container *ngIf="objtype === ProfileType.GATEWAY">
			<div class="detail-info" *ngIf="!gatewaySeen(item.lastReceivedData)">
				<i class="fa fa-exclamation-triangle"></i>
			</div>
			<div class="detail-info" *ngIf="gatewaySeen(item.lastReceivedData)">
				<span class="status-dot active"></span>
			</div>
		</ng-container>

		<!-- PUSH ACTIVE -->
		<ng-container *ngIf="objtype === ProfileType.PUSH">
			<div class="detail-info" *ngIf="item.message === '' || item.isExpired">
				<i
					class="fa fa-exclamation-triangle"
					matTooltip="{{ 'PUSH_INCOMPLETE' | translate }}"></i>
			</div>
			<div class="detail-info" *ngIf="item.message !== '' && !item.isExpired">
				<span class="status-dot active"></span>
			</div>
		</ng-container>

		<!-- EXPERIENCE ACTIVE -->
		<ng-container *ngIf="objtype === ProfileType.EXPERIENCE">
			<div
				class="detail-info"
				*ngIf="
					(item.assetCount === 0 && item.zoneCount === 0) ||
					!item.payload ||
					item.isExpired
				">
				<i
					class="fa fa-exclamation-triangle"
					matTooltip="{{ 'EXPERIENCE_INCOMPLETE' | translate }}"></i>
			</div>
			<div
				class="detail-info"
				*ngIf="
					(item.zoneCount !== 0 || item.assetCount !== 0) &&
					item.payload &&
					!item.isExpired
				">
				<span class="status-dot active"></span>
			</div>
		</ng-container>

		<!-- JOURNEY ACTIVE -->
		<ng-container *ngIf="objtype === ProfileType.JOURNEY">
			<div class="detail-info" *ngIf="item.steps.length === 0">
				<i
					class="fa fa-exclamation-triangle"
					matTooltip="{{ 'JOURNEY_INCOMPLETE' | translate }}"></i>
			</div>

			<div class="detail-info" *ngIf="item.steps.length !== 0">
				<span class="status-dot active"></span>
			</div>
		</ng-container>

		<ng-container *ngIf="objtype === ProfileType.ADMINUSER">
			<div class="detail-info" *ngIf="item.isLockedOut">
				<i
					class="fa fa-exclamation-triangle"
					matTooltip="{{ 'USER_IS_LOCKEDOUT' | translate }}"></i>
			</div>
			<div class="detail-info" *ngIf="!item.isLockedOut">
				<span class="status-dot active"></span>
			</div>
		</ng-container>
	</div>

	<!-- INACTIVE -->
	<div *ngIf="!item.isActive">
		<label translate="INACTIVE"></label>
		<div class="detail-info"><span class="status-dot inactive"></span></div>
	</div>

	<!-- ARCHIVED -->
	<div *ngIf="item.isArchived">
		<label translate="ARCHIVED"></label>
		<div class="detail-info"><span class="fa fa-archive"></span></div>
	</div>

	<!--  GATEWAY SPARE CHECK -->
	<div *ngIf="objtype === ProfileType.GATEWAY && item.isSpare">
		<div class="detail-info" *ngIf="item.isSpare">
			<span class="status-dot spare"></span>
		</div>
	</div>
</div>
