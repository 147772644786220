import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'filter-dialog',
	templateUrl: './filter-dialog.component.html',
})
export class FilterDialogComponent implements OnInit {
	global: GlobalStore;
	filter;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<FilterDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.filter = cloneDeep(this.data.filter);
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.filter);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
