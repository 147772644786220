<h3 translate="FILTER_BY_PARTNER"></h3>
<div class="filter-wrap">
	<ng-container *ngIf="partners.length <= editor.listLimit">
		<input
			type="text"
			class="fm-input"
			[(ngModel)]="editor.qs"
			placeholder="{{ 'SEARCH' | translate }}" />
		<ul class="partnerfilter-options">
			<li
				class="fm-partnerfilter select-all"
				(click)="selectAll()"
				[ngClass]="{ active: filter === 'all' }">
				<span translate="ALL_PARTNERS"></span>
			</li>
			<li
				class="fm-partnerfilter"
				*ngFor="
					let partner of filterList(editor.list, editor.qs);
					trackBy: 'id' | trackByProperty
				"
				(click)="filterClick(partner)"
				[ngClass]="{ active: isActive(partner) }">
				<profile-image
					[objtype]="ProfileType.COMPANY"
					[obj]="partner"
					mode="size_20"></profile-image>
				<span [translate]="partner.name"></span>
			</li>
		</ul>
	</ng-container>

	<ng-container *ngIf="partners.length > editor.listLimit">
		<div class="filter-loader" *ngIf="editor.isLoading">
			<span class="fa fa-circle-notch fa-spin"></span>
		</div>
		<div class="typeahead" *ngIf="!editor.isLoading">
			<div class="field-editor">
				<input
					type="text"
					class="fm-input"
					[(ngModel)]="editor.qs"
					placeholder="{{ 'SEARCH' | translate }}"
					(ngModelChange)="getList(editor.qs)" />
				<div class="typeahead-loader" *ngIf="editor.isLoading">
					<span class="fa fa-circle-notch fa-spin"></span>
				</div>
			</div>

			<ul class="filter-list" *ngIf="editor.list.length">
				<li
					class="fm-partnerfilter"
					*ngFor="let partner of editor.list; trackBy: 'index' | trackByProperty"
					(click)="setItem(partner)">
					<profile-image
						[objtype]="ProfileType.COMPANY"
						[obj]="partner"
						mode="size_20"></profile-image>
					<span [translate]="partner.name"></span>
				</li>
			</ul>

			<div *ngIf="noResults && editor.qs" class="no-results" translate="NO_RESULTS"></div>

			<ul class="filter-list" *ngIf="selected.length">
				<li
					class="fm-partnerfilter"
					*ngFor="let partner of selected; trackBy: 'index' | trackByProperty">
					<profile-image
						[objtype]="ProfileType.COMPANY"
						[obj]="partner"
						mode="size_20"></profile-image>
					<span [translate]="partner.name"></span>
					<div class="remove-tag">
						<i class="fa fa-times" (click)="clearItem(partner)"></i>
					</div>
				</li>
			</ul>
		</div>
	</ng-container>
</div>
