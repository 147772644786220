import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs';

import { GlobalService } from '../services/global.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private globalService: GlobalService) {}

	canLoad(next: ActivatedRouteSnapshot): any {
		return this.globalService.isLoggedIn().pipe(map((isLoggedIn) => isLoggedIn));
	}
	canLoadChildren(next: ActivatedRouteSnapshot): any {
		return this.globalService.isLoggedIn().pipe(map((isLoggedIn) => isLoggedIn));
	}
}
