export enum PlatformTaskStatus {
	NEW = 'new',
	VERIFYING = 'verifying',
	PRIORITY = 'priority',
	AWAITING = 'awaiting',
	CANCELLED = 'cancelled',
	INPROGRESS = 'inprogress',
	COMPLETE = 'complete',
}

export enum PlatformTaskType {
	Sites = 'sites',
	AssetRanges = 'AssetRanges',
	ShippedTo = 'shippedto',
}

export interface PlatformTaskComment {
	index?: number;
	status: string;
	step: string;
	created: number;
	issues: any[];
	comment: any;

	duplicateCount?: number;
	recordCount?: number;
	skipCount?: number;
	issueCount?: number;
}

export interface PlatformTask {
	id?: string;
	name?: string;
	adminUser?: any;
	asset?: any;
	status?: PlatformTaskStatus;
	type?: any;
	msg?: string;

	created?: string;
	updated?: string;

	comments?: PlatformTaskComment[];
	headerMap?: any[];

	filename?: string;
	pathfragment?: string;
	sas?: string;
	log?: string;

	isActive?: boolean;
	isAccepted?: boolean;
	isComplete?: boolean;
	isPickedUp?: boolean;
	isVerified?: boolean;
	hasError?: boolean;
	hasWarning?: boolean;
	processing?: boolean;
	simulate?: boolean;
	updateExisting?: boolean;
	usePlaces?: boolean;

	// UI ONLY PROPS
	isParsed?: boolean;
	formattedComments?: any[];
}
