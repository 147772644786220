import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileType } from '@fmlib/enums';
import { GlobalService, MapsService } from '@fm/services';
import { FMImage, GlobalStore } from '@fmlib/interfaces';

import { isObject } from 'lodash-es';

import { UploadCdnImageComponent } from '../../upload/upload-cdn-image.component';
import { FmIconComponent } from '../../shared/fm-icon.component';
import { PayloadPreviewComponent } from '../../shared/payload-preview/payload-preview.component';
import { FmDeliveredComponent } from '../../shared/fm-delivered.component';

const GOOGLE_API_KEY = 'AIzaSyA9xz9GwKCZQv4qVQ11OFPGqlO_JGMJbZU';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FmDeliveredComponent,
		FmIconComponent,
		PayloadPreviewComponent,
		UploadCdnImageComponent,
	],
	selector: 'profile-image',
	templateUrl: './profile-image.component.html',
	styleUrls: ['./profile-image.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class ProfileImageComponent implements OnInit {
	@Input() mode: string;
	@Input() obj;
	@Input() objtype: ProfileType;
	@Input() onclick;
	@Input() showEdit: boolean;

	global: GlobalStore;
	ProfileType = ProfileType;

	protected MAX_WIDTH = 400;
	protected MAX_HEIGHT = 400;

	get gatewayType(): string {
		return this.obj.gatewayType ? this.obj.gatewayType : 'standard';
	}

	constructor(
		private router: Router,
		private FM_Global: GlobalService,
		private FM_Maps: MapsService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		if (this.obj && !this.showEdit) {
			// this.checkImage(this.obj.image);
		}
	}

	hasLatLng(): boolean {
		return this.obj && this.obj.lat !== 0 && this.obj.lng !== 0;
	}

	getMap(label: string): string {
		let location;
		if (this.objtype === ProfileType.GEOFENCE) {
			location = this.obj.vertex.x + ',' + this.obj.vertex.y;
		} else {
			location = this.obj.lat + ',' + this.obj.lng;
		}

		const mapZoom =
			this.obj.mapZoom && this.obj.mapZoom !== 0
				? this.obj.mapZoom
				: this.FM_Maps.DEFAULT_ZOOM;

		return (
			'https://maps.googleapis.com/maps/api/staticmap?zoom=' +
			mapZoom +
			'&size=150x150&maptype=roadmap&markers=color:blue%7Clabel:' +
			label +
			'%7C' +
			location +
			'&key=' +
			GOOGLE_API_KEY
		);
	}

	getCdnImage(img: FMImage): string {
		return this.global.cdn.external + img.pathFragment;
	}

	getBeaconImage(type: any): string {
		if (isObject(type)) {
			return '/assets/images/beacontypes/' + type['beaconType'].toUpperCase() + '.png';
		} else {
			return '/assets/images/beacontypes/' + type.toUpperCase() + '.png';
		}
	}

	getNFCImage(type: any): string {
		if (isObject(type)) {
			return '/assets/images/nfctypes/' + type['nfcType'].toUpperCase() + '.png';
		} else {
			return '/assets/images/nfctypes/' + type.toUpperCase() + '.png';
		}
	}

	checkImage(imgSrc: string): void {
		const img = new Image();
		img.onload = (): void => {
			let width = img.width;
			let height = img.height;

			if (width > height) {
				if (width > this.MAX_WIDTH) {
					height *= this.MAX_WIDTH / width;
					width = this.MAX_WIDTH;
				}
			} else {
				if (height > this.MAX_HEIGHT) {
					width *= this.MAX_HEIGHT / height;
					height = this.MAX_HEIGHT;
				}
			}

			const canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;

			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0, width, height);

			const s = canvas.toDataURL('image/png');

			/*
			if (imgSrc.length > s.length) {
				console.log('resize', this.obj.name, ((imgSrc.length - s.length) / imgSrc.length) * 100 + '%');
			}
			*/

			if (imgSrc.length !== s.length) {
				setTimeout(() => {
					this.obj.image = canvas.toDataURL('image/png');
				}, 1);
			}
		};
		img.src = imgSrc;
	}

	goToState(section: string): void {
		this.router.navigate([this.objtype, this.obj.id, section]);
	}

	isType(type: ProfileType): boolean {
		return this.objtype === type;
	}

	/*
	onClick () {
		if(this.onclick){
			this.onclick();
		}
	});
	}
	*/
}
