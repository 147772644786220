<login-bkgd id="login-bkgd"></login-bkgd>

<div class="login-wrapper">
	<div class="login-container">
		<div class="login-logo"></div>

		<div class="fm-loader" *ngIf="isLoading">
			<h5
				*ngIf="view.loading_message !== 'WELCOME'"
				translate="{{ view.loading_message }}"></h5>
			<h5 *ngIf="view.loading_message === 'WELCOME'">
				<span translate="WELCOME"></span> {{ global.user.firstname }}
			</h5>
			<i class="fa fa-circle-notch fa-spin"></i>
			<!--
			<div class="lds-ripple">
				<div></div>
				<div></div>
			</div>
			-->
		</div>

		<!-- LOGIN -->
		<form
			#loginForm="ngForm"
			class="signin-panel"
			(ngSubmit)="loginForm.valid && loginUser()"
			*ngIf="isLogin"
			novalidate>
			<div class="fm-message error" *ngIf="view.login_message">
				<h4 translate="{{ view.login_message.title }}"></h4>
				<div class="message-inner" translate="{{ view.login_message.message }}"></div>
			</div>

			<login-envs></login-envs>

			<section class="signin-inner" *ngIf="!login.isMs">
				<div class="form-row">
					<div class="field-editor">
						<input
							name="username"
							autocomplete="username"
							type="text"
							placeholder="{{ 'USERNAME_EMAIL' | translate }}"
							class="fm-input"
							[(ngModel)]="login.email"
							(ngModelChange)="updateUsername($event)"
							autofocus
							required />
					</div>
				</div>
				<div class="form-row">
					<div class="field-editor">
						<input
							name="password"
							type="password"
							autocomplete="current-password"
							placeholder="{{ 'PASSWORD' | translate }}"
							class="fm-input"
							[(ngModel)]="login.password"
							required />
					</div>
				</div>
				<div class="form-row">
					<button type="submit" class="fm-button" translate="LOGIN"></button>
				</div>

				<div class="form-row">
					<a
						(click)="setView(LoginState.VERIFY)"
						[routerLink]=""
						translate="FORGOT_PASSWORD"></a>
				</div>
			</section>

			<section class="signin-inner" *ngIf="login.isMs">
				<div class="form-row">
					<div class="ms-account">
						<i class="fab fa-windows" aria-hidden="true"></i>
						<h5 translate="MICROSOFT_ACCOUNT"></h5>
						<div [innerText]="login.email"></div>
					</div>

					<a [routerLink]="" (click)="msSignIn()" class="fm-button" translate="LOGIN"></a>
				</div>

				<div class="form-row">
					<a (click)="cancelLogin()" [routerLink]="" translate="BACK"></a>
				</div>
			</section>
		</form>

		<!-- MFA -->
		<form
			#codeForm="ngForm"
			class="signin-panel"
			(ngSubmit)="codeForm.valid && loginUser()"
			*ngIf="isMfa">
			<div class="fm-message error" *ngIf="view.login_message">
				<h4 [translate]="view.login_message.title"></h4>
				<div class="message-inner" [translate]="view.login_message.message"></div>
			</div>

			<div class="fm-message" *ngIf="!view.login_message">
				<h4 translate="MFA_CODE"></h4>
				<div class="message-inner" translate="MFA_LOGIN_HELP"></div>
			</div>

			<section class="signin-inner">
				<div class="form-row">
					<div class="field-editor">
						<input
							name="totpcode"
							type="text"
							placeholder="{{ 'VERIFICATION_CODE' | translate }}"
							class="fm-input"
							[(ngModel)]="login.totpcode"
							autofocus
							required />
					</div>
				</div>
				<div class="form-row">
					<button
						type="submit"
						[disabled]="codeForm.invalid"
						class="fm-button"
						translate="VALIDATE"></button>
				</div>
				<div class="form-row">
					<a (click)="cancelReset()" [routerLink]="" translate="CANCEL"></a>
				</div>
				<div class="form-note" translate="VERIFICATION_NOTE"></div>
			</section>
		</form>

		<!-- RESET 1 -->
		<form
			#resetForm="ngForm"
			class="signin-panel"
			(ngSubmit)="resetForm.valid && verifyEmail()"
			*ngIf="isVerify">
			<div class="fm-message">
				<h4 translate="PASSWORD_RESET"></h4>
				<div class="message-inner" translate="PASSWORD_RESET_EMAIL"></div>
			</div>

			<section class="signin-inner">
				<div class="form-row">
					<div class="field-editor">
						<input
							name="username"
							autocomplete="username"
							type="text"
							placeholder="{{ 'USERNAME_EMAIL' | translate }}"
							class="fm-input"
							[(ngModel)]="login.email"
							fm-focus="isView('verify')"
							required />
					</div>
				</div>
				<div class="form-row">
					<button
						type="submit"
						class="fm-button"
						[disabled]="resetForm.invalid"
						translate="SUBMIT"></button>
				</div>
				<div class="form-row">
					<a (click)="cancelReset()" [routerLink]="" translate="CANCEL"></a>
				</div>
			</section>
		</form>

		<!-- RESET 2 -->
		<form
			#passwordForm="ngForm"
			class="signin-panel"
			(ngSubmit)="passwordForm.valid && resetPassword()"
			*ngIf="isReset">
			<ng-container *ngIf="validatingToken">
				<div class="fm-message">
					<h4 translate="LOADING"></h4>
				</div>
			</ng-container>

			<ng-container *ngIf="!validatingToken && !resetTokenValid">
				<div class="fm-message error">
					<h4 translate="ATTENTION"></h4>
					<div class="message-inner" translate="RESET_TOKEN_ERROR"></div>
				</div>

				<section class="signin-inner">
					<div class="form-row signin-actions">
						<a
							(click)="setView(LoginState.VERIFY)"
							[routerLink]=""
							translate="RESET_PASSWORD"></a>

						<a (click)="cancelReset()" [routerLink]="" translate="RETURN_TO_LOGIN"></a>
					</div>
				</section>
			</ng-container>

			<ng-container *ngIf="!validatingToken && resetTokenValid">
				<div class="fm-message">
					<h4 translate="PASSWORD_RESET"></h4>
					<div class="message-inner" translate="PASSWORD_RESET_MESSAGE"></div>
				</div>

				<section class="signin-inner">
					<password-validate
						#passwordValidate
						name="passwordValidate"
						[(ngModel)]="login.password"
						[token]="resetToken"
						[user]="login"></password-validate>

					<div class="form-row">
						<button
							type="submit"
							class="fm-button"
							[disabled]="passwordForm.invalid"
							translate="SUBMIT"></button>
					</div>
					<div class="form-row">
						<a (click)="cancelReset()" [routerLink]="" translate="CANCEL"></a>
					</div>
				</section>
			</ng-container>
		</form>

		<!-- EXPIRED -->
		<form
			#expireForm="ngForm"
			class="signin-panel"
			(ngSubmit)="expireForm.valid && expirePassword()"
			*ngIf="isExpired">
			<div class="fm-message" *ngIf="!view.login_message">
				<h4 translate="PASSWORD_EXPIRED"></h4>
				<div class="message-inner" translate="PASSWORD_EXPIRED_MESSAGE"></div>
			</div>

			<div class="fm-message error" *ngIf="view.login_message">
				<h4 translate="{{ view.login_message.title }}"></h4>
				<div class="message-inner" translate="{{ view.login_message.message }}"></div>
			</div>

			<section class="signin-inner">
				<div class="form-row">
					<div class="field-editor">
						<input
							#currentPassword
							name="currentPassword"
							type="password"
							placeholder="{{ 'CURRENT_PASSWORD' | translate }}"
							class="fm-input"
							[(ngModel)]="login.old_password"
							required />
					</div>
				</div>

				<password-validate
					#passwordValidate
					name="passwordValidate"
					[(ngModel)]="login.password"
					[user]="login"></password-validate>

				<div class="form-row">
					<button
						type="submit"
						class="fm-button"
						[disabled]="expireForm.invalid"
						translate="SUBMIT"></button>
				</div>
				<div class="form-row">
					<a (click)="cancelReset()" [routerLink]="" translate="CANCEL"></a>
				</div>
			</section>
		</form>
	</div>

	<div class="login-footer">
		<!--
			<a class="language-select" [routerLink]="" (click)="showMenu = !showMenu">
			<span>Language - {{ global.language.name }}</span>
			<ul class="sub-menu" [ngClass]="{ active: showMenu }">
				<li [ngClass]="{ active: global.language.key === lang.key }" (click)="setLanguage(lang)" *ngFor="let lang of languages">{{ lang.name }}</li>
			</ul>
		</a>
		-->
		<a
			href="http://www.footmarks.com/contact/"
			target="_blank"
			rel="noopener"
			translate="REQUEST_DEMO"></a>
		<a
			href="http://www.footmarks.com/terms/"
			target="_blank"
			rel="noopener"
			translate="TERMS_OF_SERVICE"></a>
	</div>
</div>
