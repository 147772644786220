import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'filter-toggle',
	template: '<fm-toggle [(ngModel)]="filter" (ngModelChange)="updateFilter()"></fm-toggle>',
})
export class FilterToggleComponent {
	@Input() filter: boolean;
	@Output() filterChange = new EventEmitter<boolean>();
	@Output() update = new EventEmitter<void>();

	close(): void {
		this.filter = false;
		this.update.emit();
	}

	updateFilter(): void {
		this.filterChange.emit(this.filter);
		this.update.emit();
	}
}
