import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, MessageService } from '@fm/services';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'push-dialog',
	templateUrl: './push-dialog.component.html',
})
export class PushDialogComponent implements OnInit {
	obj;
	profile;
	view;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<PushDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(MessageService) private FM_Messages: MessageService
	) {}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.view = {
			isLoading: false,
			isFailed: false,
			mode: 'send',
		};

		this.profile = {};

		this.obj = cloneDeep(this.data.obj);
	}

	triggerPush(): void {
		this.profile.isBusy = true;
		this.profile.isError = false;

		const temp = {
			push: this.obj.id,
		};

		this.FM_Api.connect('push/triggerpush').post({}, temp, (response) => {
			this.profile.isBusy = false;
			if (response.success) {
				this.FM_Messages.addSuccessMessage('PUSH_SENT');
				this.dialogRef.close();
			} else {
				this.profile.isError = true;
			}
		});
	}

	sendPush(): void {
		this.profile.isBusy = true;
		this.profile.isError = false;

		const temp = {
			deviceUID: this.obj.deviceUID,
			app: this.obj.app,
			message: this.obj.message,
		};

		this.FM_Api.connect('push/pushtodevice').post({}, temp, (response) => {
			this.profile.isBusy = false;
			if (response.success) {
				this.FM_Messages.addSuccessMessage('PUSH_SENT');
				this.dialogRef.close();
			} else {
				this.profile.isError = true;
			}
		});
	}

	// MODAL ACTIONS
	ok(): void {
		if (this.view.mode === 'trigger') {
			this.triggerPush();
		} else {
			this.sendPush();
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
