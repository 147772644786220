import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileType } from '@fmlib/enums';
import { GlobalStore } from '@fmlib/interfaces';
import { GlobalService } from '@fm/services';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'confirm-duplicate',
	templateUrl: './confirm-duplicate-dialog.component.html',
	styleUrls: ['./confirm-duplicate-dialog.component.less'],
})
export class ConfirmDuplicateDialogComponent implements OnInit {
	message: string;
	obj;
	objtype: ProfileType;

	ProfileType = ProfileType;

	cloneProps;
	confirmAgain: boolean;
	userinput;

	global: GlobalStore;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ConfirmDuplicateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(GlobalService) public FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.message = this.data.message ? this.data.message : '';

		if (this.data.obj) {
			this.obj = cloneDeep(this.data.obj);
			this.obj.name = this.data.obj.name + ' copy';
		}

		if (this.data.objtype) {
			this.objtype = this.data.objtype;
		}

		this.userinput = {};
		this.cloneProps = {};
	}

	checkConfirm(): void {
		this.confirmAgain = this.cloneProps.ranges;
	}

	ok(): void {
		this.dialogRef;
		this.dialogRef.close({ obj: this.obj, cloneRanges: this.cloneProps?.ranges });
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
