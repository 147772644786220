import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../services/data.service';

@Pipe({ name: 'regionName' })
export class RegionNamePipe implements PipeTransform {
	constructor(private FM_Data: DataService) {}

	transform(regionId: string): string {
		const regions = this.FM_Data.beaconRegions();
		const region = regions.find(({ id }) => id === regionId);
		return region ? region.name : '';
	}
}
