import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'control-panel',
	templateUrl: './control-panel.component.html',
	styleUrls: ['./control-panel.component.less'],
})
export class ControlPanelComponent {
	@Input() active: string;
	@Input() panel: string;
}
