import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'confirm',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.less'],
})
export class ConfirmDialogComponent implements OnInit {
	message: string;
	obj;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.message = this.data.message ? this.data.message : '';

		if (this.data.obj) {
			this.obj = this.data.obj;
		}
	}

	ok(): void {
		this.dialogRef.close(true);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
