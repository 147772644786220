import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { EMPTY, Subject, Subscription } from 'rxjs';
import {
	catchError,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	finalize,
	take,
} from 'rxjs/operators';

import { AppService, AppView, AuthService, GlobalService, MessageService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { LoginState } from 'projects/manage/src/app/services/app.service';

import { PublicClientApplication } from '@azure/msal-browser';

import { SharedModule } from '../shared/shared.module';

import { LoginBkgdComponent } from './login-bkgd/login-bkgd.component';
import { LoginEnvsComponent } from './login-envs/login-envs.component';
import { PasswordValidateComponent } from '../shared/password-validate/password-validate.component';

@Component({
	standalone: true,
	imports: [SharedModule, LoginBkgdComponent, LoginEnvsComponent, PasswordValidateComponent],
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.less'],
	providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class LoginComponent implements OnInit, OnDestroy {
	private _msal;
	private _subscriptions = new Subscription();

	global: GlobalStore;

	login;
	languages;
	login_timer;

	validatingToken: boolean;
	resetTokenValid: boolean;

	LoginState = LoginState;

	usernameChanged: Subject<string> = new Subject<string>();

	get view(): AppView {
		return this.FM_App.view;
	}

	get resetToken(): string {
		return this.FM_App.resetToken;
	}

	get isExpired(): boolean {
		return this.FM_App.view.loginPanel === LoginState.EXPIRED;
	}

	get isReset(): boolean {
		return this.FM_App.view.loginPanel === LoginState.RESET;
	}

	get isLogin(): boolean {
		return this.FM_App.view.loginPanel === LoginState.LOGIN;
	}

	get isLoading(): boolean {
		return this.FM_App.view.loginPanel === LoginState.LOADING;
	}

	get isVerify(): boolean {
		return this.FM_App.view.loginPanel === LoginState.VERIFY;
	}

	get isMfa(): boolean {
		return this.FM_App.view.loginPanel === LoginState.MFA;
	}

	constructor(
		private FM_App: AppService,
		private FM_Auth: AuthService,
		private FM_Global: GlobalService,
		private FM_Message: MessageService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		// GET THE SUPPORTED LANGUAGES
		this.languages = this.FM_Global.getLanguages();

		// This object will be filled by the form
		this.login = {};

		if (this.global && this.global.config) {
			this.setUpMsal();
		}

		this.usernameChanged
			.pipe(
				filter((text) => text.length > 3),
				debounceTime(300),
				distinctUntilChanged()
			)
			.subscribe((model) => {
				this.checkUserAuth(model);
			});

		this.validatingToken = true;
		setTimeout(() => {
			if (this.isReset) {
				this.validateResetToken();
			}
		}, 1000);
	}

	async setUpMsal(): Promise<void> {
		const redirectSite =
			this.global.config.server !== 'local'
				? this.global.config.endpoints.site
				: 'http://localhost:3000';

		// Config object to be passed to MSAL on creation
		const msalConfig = {
			auth: {
				clientId: 'd9574f21-68c8-4cd7-853a-47241f3e9e6d',
				authority: 'https://login.microsoftonline.com/9942d4e9-4fef-449c-bf6b-b11d7efc6f4f',
				redirectUri: redirectSite,
			},
			cache: {
				cacheLocation: 'sessionStorage', // This configures where your cache will be stored
				storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
			},
		};
		this._msal = new PublicClientApplication(msalConfig);
		await this._msal.initialize();
	}

	updateUsername(query: string): void {
		this.usernameChanged.next(query);
	}

	resetLogin(): void {
		this.login = {};
		this.FM_App.resetLogin();
	}

	// PASSWORD RESET
	verifyEmail(): void {
		this.FM_App.setView(LoginState.LOADING);

		this.FM_Auth.verifyEmail(this.login.email.trim())
			.pipe(
				delay(this.global.timeout.loading),
				take(1),
				catchError(() => {
					this.FM_Message.addErrorMessage('PASSWORD_EMAIL_ERROR');
					this.FM_App.setView(LoginState.VERIFY);

					return EMPTY;
				})
			)
			.subscribe(() => {
				this.FM_Message.addSuccessMessage('PASSWORD_EMAIL_SUCCESS');
				this.resetLogin();
			});
	}

	validateResetToken(): void {
		this.FM_Auth.verifyToken(this.resetToken)
			.pipe(
				catchError(() => {
					this.resetTokenValid = false;
					return EMPTY;
				}),
				finalize(() => {
					this.validatingToken = false;
				})
			)
			.subscribe(() => {
				this.resetTokenValid = true;
			});
	}
	resetPassword(): void {
		this.FM_App.setView(LoginState.LOADING);

		this.FM_Auth.resetPassword(this.FM_App.resetToken, this.login.password.trim())
			.pipe(
				delay(this.global.timeout.loading),
				take(1),
				catchError(() => {
					this.FM_Message.addErrorMessage('PASSWORD_RESET_ERROR');
					this.FM_App.setView(LoginState.RESET);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.FM_Message.addSuccessMessage('PASSWORD_RESET_SUCCESS');
				this.resetLogin();
			});
	}

	expirePassword(): void {
		this.FM_App.setView(LoginState.LOADING);

		this.FM_Auth.expirePassword(
			this.login.email.trim(),
			this.login.old_password.trim(),
			this.login.password.trim()
		)
			.pipe(
				delay(this.global.timeout.loading),
				take(1),
				catchError(() => {
					this.FM_Message.addErrorMessage('PASSWORD_RESET_ERROR');
					this.FM_App.setView(LoginState.EXPIRED);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.FM_Message.addSuccessMessage('PASSWORD_RESET_SUCCESS');
				this.resetLogin();
			});
	}

	loginUser(): void {
		this.FM_App.setView(LoginState.LOADING);

		this.FM_Auth.getToken(
			this.login.email.trim(),
			this.login.password.trim(),
			this.login.totpcode?.trim()
		)
			.pipe(
				delay(this.global.timeout.loading),
				take(1),
				catchError((res) => {
					// ERROR
					if (res && res.error) {
						if (res.error.message === 'expired') {
							this.login.password = '';
							this.FM_App.setView(LoginState.EXPIRED);
						} else if (res.error.name === 'Invalid2FACode') {
							this.FM_App.setView(LoginState.MFA);
						} else {
							this.FM_App.setView(LoginState.LOGIN);
						}
					} else {
						this.login.totpcode = '';
						// this.FM_Messages.addErrorMessage('LOGIN_ERROR');
						this.FM_App.setView(LoginState.LOGIN);
					}

					return EMPTY;
				})
			)
			.subscribe((res) => {
				//SUCCESS
				this.FM_App.clearMessage();
				if (res.codeRequired) {
					this.FM_App.setView(LoginState.MFA);
				} else {
					this.FM_App.checkAuth();
				}
			});
	}

	msHandleResponse(response): void {
		/**
		 * To see the full list of response object properties, visit:
		 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
		 */

		if (response.accessToken && response.idToken) {
			this.FM_Auth.swapToken(response.accessToken, response.idToken).subscribe(() => {
				this.FM_App.checkAuth();
			});
		}
	}

	// NEW MSAL SIGNIN
	msSignIn(): void {
		const loginRequest = {
			scopes: ['User.ReadWrite'],
			state: 'testinfo',
		};

		this._msal
			.loginPopup(loginRequest)
			.then((response) => {
				this.msHandleResponse(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	cancelReset(): void {
		this.resetLogin();
	}

	checkUserAuth(username: string): void {
		this.FM_Auth.check(username)
			.pipe(
				catchError(() => {
					this.login.isMs = false;
					return EMPTY;
				})
			)
			.subscribe((u) => {
				this.login.isMs = u.provider === 'ms';
			});
	}

	cancelLogin(): void {
		this.login.isMs = false;
	}

	setView(view: LoginState): void {
		this.FM_App.setView(view);
	}

	updateEnv(env: string): void {
		this.FM_Global.setEnv(env);
		this.FM_App.initGlobal();
	}

	ngOnDestroy(): void {
		this.usernameChanged.unsubscribe();
		this._subscriptions.unsubscribe;
	}
}
