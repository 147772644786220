import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DataType } from '@fmlib/enums';
import { DataService } from '@fm/services';
import { Attribute } from '@fmlib/interfaces';

import { remove } from 'lodash-es';

@Component({
	selector: 'attribute-dialog',
	templateUrl: './attribute-dialog.component.html',
	styleUrls: ['./attribute-dialog.component.less'],
})
export class AttributeDialogComponent implements OnInit {
	attributes: Attribute[];
	dataTypeOptions: DataType[];
	hasDuplicates: boolean;

	DataType = DataType;

	@ViewChild('attributeEditor', { static: false }) attributeEditor: ElementRef;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { attributes: Attribute[] },
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<AttributeDialogComponent>,
		@Inject(DataService) private FM_Data: DataService
	) {}

	ngOnInit(): void {
		this.attributes = this.FM_Data.clone(this.data.attributes);

		// CONVERT ATTRIBUTES
		this.attributes.forEach((attr: Attribute) => {
			if (!attr.dataType) {
				attr.dataType = DataType.STRING;
			} else if (attr.dataType === DataType.CURRENCY || attr.dataType === DataType.NUMBER) {
				attr.value = parseFloat(attr.value);
			}
			/*
			// NO LONGER NEEDED
			else if (attr.dataType === DataType.Datetime) {
				item.value = new Date(item.value);
			}
			*/
		});

		this.dataTypeOptions = this.FM_Data.dataTypes().filter((o) => o !== DataType.LIST);
	}

	addAttribute(): void {
		const attr: Attribute = { key: '', value: '', dataType: DataType.STRING };
		this.attributes.unshift(attr);

		if (this.attributeEditor) {
			this.attributeEditor.nativeElement.scrollTop = 0;
		}
	}

	checkKeys(): void {
		const seen = {};
		this.hasDuplicates = this.attributes.some((obj) => {
			return (
				(obj.key !== '' && Object.prototype.hasOwnProperty.call(seen, obj.key)) ||
				(seen[obj.key] = false)
			);
		});
	}

	changeType(attr: Attribute): void {
		delete attr.value;
	}

	removeAttribute(index: number): void {
		this.attributes.splice(index, 1);
		this.checkKeys();
	}

	// CLEAR ALL
	clearAll(): void {
		this.attributes = remove(this.attributes, { isLocked: true });
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.attributes);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
