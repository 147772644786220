import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ProfileType } from '@fmlib/enums';

import { differenceInHours } from 'date-fns';

@Component({
	standalone: true,
	imports: [CommonModule, MatTooltipModule, TranslateModule],
	selector: 'lib-fm-status',
	templateUrl: './fm-status.component.html',
	styleUrls: ['./fm-status.component.less'],
})
export class FmStatusComponent implements OnInit {
	@Input() item;
	@Input() objtype: ProfileType;

	ProfileType = ProfileType;
	statusText: string;

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {
		const statuses = [];

		this.translate.instant('WAIT');

		if (this.item.isActive) {
			if (
				this.objtype === ProfileType.GATEWAY &&
				!this.gatewaySeen(this.item.lastReceivedData)
			) {
				statuses.push(this.translate.instant('NO_ACTIVITY'));
			} else {
				statuses.push(this.translate.instant('ACTIVE'));
			}
		} else {
			statuses.push(this.translate.instant('INACTIVE'));
		}

		if (this.item.isArchived) {
			statuses.push(this.translate.instant('ARCHIVED'));
		}

		if (this.item.isSpare) {
			statuses.push(this.translate.instant('SPARE'));
		}

		this.statusText = statuses.join(' + ');
	}

	gatewaySeen(date: string): boolean {
		const hours = differenceInHours(new Date(), new Date(date));
		return hours < 24;
	}
}
