import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MenuService } from '@fm/services';
import { SharedModule } from '../shared/shared.module';

interface MenuItem {
	title: string;
	icon: string;
	parent: string;
	state: any;
}

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'main-menu',
	templateUrl: './mainmenu.component.html',
	styleUrls: ['./mainmenu.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class MainMenuComponent implements OnInit {
	menus: {
		reports: MenuItem[];
		system: MenuItem[];
		network: MenuItem[];
		asset: MenuItem[];
		associate: MenuItem[];
		connect: MenuItem[];
	};

	constructor(private FM_Menu: MenuService) {}

	ngOnInit(): void {
		this.FM_Menu.getMenu().subscribe((menu) => {
			this.menus = menu;
		});

		this.FM_Menu.update();
	}

	stateActive(item): boolean {
		return false;
		// return this.router.isActive(item, false);
	}
}
