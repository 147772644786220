import { Component, Input, OnInit } from '@angular/core';

import { DataService } from '@fm/services';
import { TypeInfo } from '@fmlib/interfaces';

@Component({
	selector: 'list-message',
	template: `<div class="fm-message notice" *ngIf="showMessage()">
		<i class="fm-icon" [ngClass]="icon"></i>
		<span [translate]="message"></span>
	</div>`,
})
export class ListMessageComponent implements OnInit {
	@Input() key;
	@Input() count: number;
	@Input() message: string;
	@Input() search;

	icon: string;

	constructor(private FM_Data: DataService) {}

	ngOnInit(): void {
		const typeInfo: TypeInfo = this.FM_Data.typeInfo(this.key);

		this.icon = typeInfo ? typeInfo.icon : '';

		if (!this.message) {
			this.message = typeInfo ? typeInfo.message : '';
		}
	}

	showMessage(): boolean {
		let test = false;

		if (this.search) {
			test = this.icon && this.count <= 0 && !this.search.isLoading && !this.search.isFailed;
		} else {
			test = this.icon && this.count <= 0;
		}

		return test;
	}
}
