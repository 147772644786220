<div class="modal-header">
	<h3 class="modal-title" translate="ATTENTION"></h3>
</div>

<div class="modal-body">
	<h4 class="confirm-title" [translate]="message"></h4>

	<h5 *ngIf="obj" [innerText]="obj.name"></h5>

	<div class="form-row confirm-message">
		<div class="fm-message remove">
			<mat-checkbox [(ngModel)]="userinput.agreed" labelPosition="before"
				><span class="message" [translate]="remove.agreement"></span
			></mat-checkbox>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" [disabled]="!userinput.agreed" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
