<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="SEND"></h3>
		<div class="modal-itemname" [innerText]="obj.name"></div>
	</div>

	<div class="modal-body">
		<fm-loader [loading]="view.isLoading" [error]="view.isFailed"></fm-loader>

		<div class="modal-body item-settings" *ngIf="!view.isLoading && !view.isFailed">
			<div class="form-options">
				<div class="btn-group">
					<label
						class="btn"
						(click)="view.mode = 'send'"
						[ngClass]="{ active: view.mode === 'send' }">
						<h5 class="title" translate="SEND_TO_DEVICE"></h5>
					</label>
					<label
						class="btn"
						(click)="view.mode = 'trigger'"
						[ngClass]="{ active: view.mode === 'trigger' }">
						<h5 class="title" translate="TRIGGER_PUSH"></h5>
					</label>
				</div>
			</div>

			<div *ngIf="view.mode === 'trigger'">
				<div class="form-row">
					<div class="fm-message basic" translate="PUSH_TRIGGER_DEVICES"></div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="APPS"></label>
					</div>
					<div class="field-info">
						<ul>
							<li *ngFor="let a of obj.apps" [innerText]="a.name"></li>
						</ul>
					</div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="TARGETING"></label>
					</div>
					<div class="field-info">
						<ul>
							<li *ngIf="obj.city">
								<label translate="CITY"></label> : {{ obj.city }}
							</li>
							<li *ngIf="obj.state">
								<label translate="STATE"></label> : {{ obj.state }}
							</li>
							<li *ngIf="obj.postalCode">
								<label translate="POSTAL_CODE"></label> : {{ obj.postalCode }}
							</li>
						</ul>
					</div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="MESSAGE"></label>
					</div>
					<div class="field-info" [innerText]="obj.message"></div>
				</div>
			</div>

			<div *ngIf="view.mode === 'send'">
				<div class="form-row">
					<div class="fm-message basic" translate="PUSH_SEND_DEVICE"></div>
				</div>

				<div class="form-row" *ngIf="profile.isError">
					<div class="fm-message error" translate="ERROR_PUSH_DEVICE"></div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="DEVICE"></label>
					</div>
					<div class="field-editor">
						<input
							class="fm-input"
							name="deviceUID"
							type="text"
							#deviceUID="ngModel"
							[(ngModel)]="obj.deviceUID"
							placeholder="{{ 'DEVICE_UID' | translate }}"
							required />
						<input-status class="input-status" [obj]="deviceUID"></input-status>
						<span
							class="error-message"
							*ngIf="deviceUID.errors.required"
							translate="REQUIRED_FIELD"></span>
					</div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="APP"></label>
					</div>
					<div class="field-editor">
						<select
							class="fm-select"
							name="app"
							#app="ngModel"
							[(ngModel)]="obj.app"
							required>
							<option [value]="undefined" translate="SELECT_APP"></option>
							<option
								[value]="a.id"
								*ngFor="let a of obj.apps"
								[innerText]="a.name"></option>
						</select>
						<span class="input-icons"
							><i class="fa-light fa-circle-chevron-down"></i
						></span>
						<span class="error-message" *ngIf="app.invalid" translate="REQUIRED"></span>
					</div>
				</div>

				<div class="form-row fm-setting">
					<div class="field-text">
						<label class="label" translate="MESSAGE"></label>
					</div>
					<div class="field-info" [innerText]="obj.message"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer" *ngIf="profile.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!profile.isBusy">
		<button class="fm-button" translate="OK"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
