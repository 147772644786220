<div class="filter-quicksearch">
	<ul class="quicksearch-terms" *ngIf="tags.length">
		<li *ngFor="let tag of tags; trackBy: 'index' | trackByProperty">
			<span [innerText]="tag"></span>
			<span class="remove-tag" (click)="removeTag(tag)"><i class="fa fa-times"></i></span>
		</li>
	</ul>
	<input
		#searchInput
		type="text"
		class="fm-input"
		[(ngModel)]="searchText"
		(ngModelChange)="performSearch($event)"
		(keydown)="checkKey($event)"
		(blur)="setText()"
		placeholder="{{ 'SEARCH' | translate }}..."
		minlength="3"
		autofocus />

	<a
		[routerLink]=""
		*ngIf="!allowTags && hasText"
		class="quicksearch-clear"
		(click)="clearFilter()"
		><i class="fa fa-times"></i
	></a>
</div>
