<div class="prefix-input">
	<span class="input-addon">{{ prefix }}</span>
	<input
		class="fm-input"
		type="number"
		#propValue="ngModel"
		[ngModel]="value"
		(ngModelChange)="updateValue($event)"
		[min]="0.0"
		step="0.01"
		[placeholder]="placeholder"
		[required]="required"
		[readonly]="readonly" />
	<input-status class="input-status" [obj]="propValue"></input-status>
</div>
