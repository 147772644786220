<div class="filter-wrap">
	<div class="date-select">
		<select class="fm-select" name="branding" [(ngModel)]="dateMode" (change)="setDateMode()">
			<option
				*ngFor="let type of dateModes; trackBy: 'index' | trackByProperty"
				[value]="type.key"
				[translate]="type.label"></option>
		</select>
		<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span>
	</div>
	<ul>
		<li
			class="fm-savedfilter"
			*ngFor="let filter of dateFilters; trackBy: 'id' | trackByProperty"
			(click)="loadFilter(filter)"
			[ngClass]="{ active: isActive(filter) }">
			<a [routerLink]="">
				<span class="filter-status"><i class="fa fa-check"></i></span>
				{{ filter.name }}
			</a>
		</li>
	</ul>
	<ul *ngIf="activeFilter?.id === 'custom'">
		<li class="datepicker">
			<input
				class="fm-input"
				placeholder="{{ 'AFTER' | translate }}"
				matInput
				[value]="dates.startDate"
				[matDatepicker]="startPicker"
				(dateChange)="updateDate('start', $event)"
				(click)="startPicker.open()" />
			<mat-datepicker #startPicker></mat-datepicker>
			<i class="fa fa-times" (click)="clearDate('start')" *ngIf="dates.startDate"></i>
		</li>
		<li class="datepicker">
			<input
				class="fm-input"
				placeholder="{{ 'BEFORE' | translate }}"
				matInput
				[value]="dates.endDate"
				[matDatepicker]="endPicker"
				(dateChange)="updateDate('end', $event)"
				(click)="endPicker.open()" />
			<mat-datepicker #endPicker></mat-datepicker>
			<i class="fa fa-times" (click)="clearDate('end')" *ngIf="dates.endDate"></i>
		</li>
	</ul>
</div>
