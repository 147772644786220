<h3 translate="FILTER_BY_STATUS"></h3>
<div class="filter-wrap">
	<ul class="fm-filter-actions">
		<li
			(click)="toggleStatus(op)"
			[ngClass]="{ active: hasFilter(op) }"
			*ngFor="let op of options"
			[translate]="op | uppercase"></li>
	</ul>
</div>
