<div class="section-header">
	<h3 translate="TAGS"></h3>
	<div class="section-help" translate="TAGS_HELP"></div>
</div>
<div class="section-content">
	<fieldset ngModelGroup="tags" class="fm-tags">
		<div class="field-editor" *ngIf="!readonly">
			<input
				type="text"
				class="fm-input"
				#tagInput="ngModel"
				[(ngModel)]="searchInput"
				(keydown)="checkKey($event)"
				placeholder="{{ 'ADD_TAG' | translate }}"
				minlength="2"
				[ngModelOptions]="{ standalone: true }" />
			<input-status class="input-status" [obj]="tagInput"></input-status>
			<!--<div class="error-message" *ngIf="tagInput.invalid" translate="TAG_ERROR"></div>-->
		</div>

		<ul class="tag-list" *ngIf="tags.length">
			<li class="tag-item" *ngFor="let tag of tags; trackBy: 'index' | trackByProperty">
				<span [innerText]="tag"></span>
				<span class="remove-tag" (click)="removeTag(tag)"><i class="fa fa-times"></i></span>
			</li>
		</ul>

		<div class="fm-message notice" *ngIf="!tags.length">
			<i class="fm-icon fa fa-tag"></i>
			<span translate="NO_TAGS_SET"></span>
		</div>
	</fieldset>
</div>
