import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'fm-toggle',
	template: `<div
		role="radio"
		class="fm-toggle"
		(click)="toggle()"
		[ngClass]="{ readonly: readonly, active: value }">
		<div class="fm-toggle-bg">
			<div class="fm-toggle-switch"><i class="fa fa-lock" *ngIf="readonly"></i></div>
		</div>
	</div>`,
	// template: '<div role="radio" class="fm-toggle" (click)="toggle()" [class]="{ \'readonly\': readonly, \'active\': model}" }"><div class="fm-toggle-bg"><div class="fm-toggle-switch"><i class="fa fa-lock" *ngIf="readonly" matTooltip="{{\'READ_ONLY\' | translate}}"></i></div></div></div>',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FmToggleComponent),
			multi: true,
		},
	],
})
export class FmToggleComponent implements ControlValueAccessor {
	@Input() value;
	@Input() readonly: boolean;

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// do nothing
	}

	onChange = (_: any): any => {};
	onTouched = (_: any): any => {};

	toggle(): void {
		if (!this.readonly) {
			this.value = !this.value;
			this.onChange(this.value);
		}
	}
}
