<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_EXPERIENCES"></h3>
</div>
<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<h4 class="panel-title" translate="AVAILABLE"></h4>

			<div class="modal-actions">
				<a
					[routerLink]=""
					class="select-all"
					(click)="selectAll()"
					translate="SELECT_ALL"></a>
			</div>

			<div class="fm-quicksearch">
				<input
					#searchInput
					type="text"
					class="fm-input"
					[(ngModel)]="search.qs"
					placeholder="{{ 'SEARCH' | translate }}..."
					(ngModelChange)="initSearch()"
					[ngModelOptions]="{ standalone: true }" />
				<a
					[routerLink]=""
					class="quicksearch-clear"
					(click)="clearQuicksearch()"
					*ngIf="search.qs"
					><i class="fa fa-times"></i
				></a>
			</div>

			<section
				class="fm-picker-list"
				infinite-scroll
				[infiniteScrollDisabled]="search.isLoading"
				[scrollWindow]="false"
				[infiniteScrollThrottle]="0"
				(scrolled)="loadList()">
				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of search.list; trackBy: 'id' | trackByProperty"
						(click)="selectItem(item)"
						[ngClass]="{ selected: item.isSelected }">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								{{ item.name }}
								<ul class="detail">
									<li [innerText]="item.payload?.type | emptyString"></li>
								</ul>
							</div>
							<div
								class="detail-info"
								*ngIf="item.isActive && (!item.payload || item.zoneCount === 0)">
								<i
									class="fa fa-exclamation-triangle"
									matTooltip="{{ 'EXPERIENCE_INCOMPLETE' | translate }}"
									matTooltipPosition="left"></i>
							</div>
							<div
								class="detail-info"
								*ngIf="item.isActive && item.payload && item.zoneCount !== 0">
								<span class="status-dot active"></span>
							</div>
							<div class="detail-info" *ngIf="!item.isActive">
								<span class="status-dot inactive"></span>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="search.isLoading" [error]="search.isFailed"></fm-loader>

			<list-status
				[loading]="search.isLoading"
				[count]="search.count"
				[total]="search.total"></list-status>
		</div>

		<div class="select-panel">
			<h4 class="panel-title" translate="SELECTED"></h4>
			<div class="modal-actions">
				<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL"></a>
			</div>
			<section class="fm-picker-list selected-list">
				<div
					class="fm-message notice"
					*ngIf="selected.length === 0"
					translate="NO_SELECTED"></div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of selected"
						(click)="removeItem(item)">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								{{ item.name }}
								<ul class="detail">
									<li [innerText]="item.payload?.type | emptyString"></li>
								</ul>
							</div>
							<div
								class="detail-info"
								*ngIf="item.isActive && (!item.payload || item.zoneCount === 0)">
								<i
									class="fa fa-exclamation-triangle"
									matTooltip="{{ 'EXPERIENCE_INCOMPLETE' | translate }}"></i>
							</div>
							<div
								class="detail-info"
								*ngIf="item.isActive && item.payload && item.zoneCount !== 0">
								<span class="status-dot active"></span>
							</div>
							<div class="detail-info" *ngIf="!item.isActive">
								<span class="status-dot inactive"></span>
							</div>
						</div>
					</a>
				</div>
			</section>

			<div
				class="fm-list-count"
				translate="SELECTED_COUNT"
				[translateParams]="{ count: selected.length }"></div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
