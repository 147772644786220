<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_PROFILES"></h3>
</div>
<div class="modal-body">
	<div class="fm-picker">
		<!-- AVAILABLE -->
		<div class="source-panel" *ngIf="!currentGroup">
			<h4 class="panel-title" (click)="toggleProfiles()" translate="SELECT_GROUP"></h4>

			<div class="fm-quicksearch">
				<input
					type="text"
					class="fm-input"
					[(ngModel)]="userinput.searchText"
					placeholder="{{ 'SEARCH_PROFILES' | translate }}..."
					(change)="initSearch()" />
			</div>

			<section class="fm-picker-list">
				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let group of profileGroups; trackBy: 'index' | trackByProperty"
						(click)="setProfileGroup(group)">
						<div class="list-cell">
							<span [translate]="group.key"></span>
							<span class="list-icon"><i class="fa fa-chevron-right"></i></span>
							<ul class="detail">
								<li>{{ group.profiles.length }} Profiles</li>
								<li [innerText]="group.activity"></li>
							</ul>
						</div>
					</a>
				</div>
			</section>

			<list-status [loading]="view.isLoading" [count]="count" [total]="total"></list-status>
		</div>

		<div class="source-panel" *ngIf="currentGroup">
			<h4 class="panel-title" (click)="clearProfileGroup()">
				<a [routerLink]=""
					><i class="fa fa-chevron-left"></i
					><span translate="{{ currentGroup.key }}"></span
				></a>
			</h4>
			<section class="fm-picker-list selected-list">
				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="
							let profile of currentGroup.profiles;
							trackBy: 'index' | trackByProperty
						"
						(click)="selectItem(profile)"
						[ngClass]="{ selected: isProfileActive(profile) }">
						<div class="list-cell">
							<span translate="{{ profile.name }}"></span>
							<ul class="detail">
								<li>{{ getProfileActivity(profile) }}</li>
							</ul>
						</div>
					</a>
				</div>
			</section>

			<list-status [loading]="view.isLoading" [count]="count" [total]="total"></list-status>
		</div>

		<!-- SELECTED -->
		<div class="select-panel">
			<h4 class="panel-title" translate="SELECTED"></h4>
			<div class="modal-actions">
				<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL"></a>
			</div>

			<section class="fm-picker-list selected-list">
				<div
					class="fm-message notice"
					*ngIf="selected.length === 0"
					translate="NO_SELECTED"></div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of selected"
						(click)="removeItem(item)">
						<div class="list-cell">
							{{ item.name | translate }}
							<ul class="detail">
								<li>{{ getProfileActivity(item) }}</li>
							</ul>
						</div>
					</a>
				</div>
			</section>

			<div
				class="fm-list-count"
				translate="SELECTED_COUNT"
				[translateParams]="{ count: selected.length }"></div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
