<ng-container *ngIf="!isEllipsis"
	><div class="fm-loader" *ngIf="loading">
		<h5 translate="LOADING"></h5>
		<span class="fa fa-circle-notch fa-spin"></span>
	</div>

	<div class="fm-message loaderror" *ngIf="!loading && error">
		<i class="fm-icon fa fa-exclamation-triangle"></i>
		<h5 translate="LOADING_ERROR"></h5>
	</div>
</ng-container>

<ng-container *ngIf="isEllipsis">
	<div class="lds-ellipsis">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</ng-container>
