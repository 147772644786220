export enum FilterStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	ARCHIVED = 'archived',

	// ASSET
	FROZEN = 'frozen',

	// GATEWAY
	NOACTIVITY = 'no_activity',
	SPARE = 'spare',
	//
	ALL = 'ALL',
}
