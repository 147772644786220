import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, FilterService, GlobalService } from '@fm/services';
import { GlobalStore, Notification, PlatformTask, SearchParams } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'notifications-dialog',
	templateUrl: './notifications-dialog.component.html',
})
export class NotificationsDialogComponent implements OnInit {
	global: GlobalStore;

	search;
	dateFilters;
	filters;
	currentFilter;
	selected;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { notif: Notification },
		@Inject(Router) private router: Router,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<NotificationsDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(FilterService) private FM_Filters: FilterService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		// LOAD THE GLOBALS
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.search = {
			isLoading: false,
			isFailed: false,
		};

		this.dateFilters = this.FM_Filters.getDateFilters();

		// ONLY NON ARCHIVED
		this.filters = {
			isArchived: false,
		};

		// INIT LIST
		this.initList();
	}

	// LOAD ITEM
	selectItem(item: Notification): void {
		this.selected = item;
	}

	// LOAD LIST
	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isFailed = false;
		this.search.isLoading = true;

		const params: SearchParams = {
			page: this.search.page,
			sort: '-created',
			conditions: {},
		};

		if (this.filters) {
			params.conditions = cloneDeep(this.filters);
		}

		params.conditions.adminUser = this.global.user.id;

		// GET THE AUDITS
		this.FM_Api.connect('notifications').query(
			params,
			(data, headers) => {
				this.search.pages = parseInt(headers.get('X-Pages-Total'), 10);
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);
				this.search.page = this.search.page + 1;

				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;

				this.search.isLoading = false;

				if (this.search.list.length) {
					if (this.data.notif) {
						this.selectItem(this.data.notif);
					} else {
						this.selectItem(this.search.list[0]);
					}
				} else {
					this.selected = null;
				}
			},
			() => {
				this.search.isLoading = false;
				this.search.isFailed = true;
			}
		);
	}

	initList(): void {
		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];
		this.search.count = 0;

		this.loadList();
	}

	// FILTERING
	loadFilter(obj): void {
		this.currentFilter = obj;

		if (obj.filter) {
			this.filters.created = cloneDeep(obj.filter);
		} else {
			delete this.filters.created;
		}

		this.initList();
	}

	// FILTERING
	filterDate(obj): void {
		if (obj.filter) {
			const start = new Date(obj.filter.date + '-01-01');
			const end = new Date(obj.filter.date + '-12-31');
			this.filters.created = { $gte: start, $lt: end };
		} else {
			delete this.filters.created;
		}

		this.initList();
	}

	filterStatus(status: string): void {
		if (status) {
			this.filters.isArchived = status === 'archived';
		} else {
			delete this.filters.isArchived;
		}

		this.initList();
	}

	filterSeverity(action?: string): void {
		if (action) {
			this.filters.severity = action;
		} else {
			delete this.filters.severity;
		}

		this.initList();
	}

	isActive(f): boolean {
		return this.currentFilter && this.currentFilter.id === f.id;
	}

	// MANAGE NOTIFICATIONS
	setArchive(notification: Notification, state: boolean): void {
		this.FM_Api.connect('notifications')
			.put({ id: notification.id }, { isArchived: state })
			.subscribe(() => {
				this.initList();
			});
	}

	// DELETE NOTIFICATIONS
	deleteNotification(notification: Notification): void {
		this.FM_Api.connect('notifications')
			.delete({ id: notification.id })
			.subscribe(() => {
				this.initList();
			});
	}

	// ARCHIVE ALL NOTIFICATIONS
	archiveAllNotifications(): void {
		const obs = [];
		this.search.list.forEach((item) => {
			obs.push(
				this.FM_Api.connect('notifications').put({ id: item.id }, { isArchived: true })
			);
		});

		forkJoin(obs).subscribe(() => {
			this.initList();
		});
	}

	// DELETE NOTIFICATIONS
	deleteAllNotifications(): void {
		const obs = [];
		this.search.list.forEach((item) => {
			obs.push(this.FM_Api.connect('notifications').delete({ id: item.id }));
		});

		forkJoin(obs).subscribe(() => {
			this.initList();
		});
	}

	goToTask(platformtask: PlatformTask): void {
		if (platformtask.type === 'shippedto') {
			this.router.navigate(['assets', platformtask.asset, 'shippedto']);
		} else {
			this.router.navigate(['platformtasks', platformtask.id]);
		}

		// CLOSE CURRENT AND LOAD NEW
		this.dialogRef.close();
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close();
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
