import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { Observable } from 'rxjs';

import { reject, some } from 'lodash-es';

@Injectable()
export class ToolsService {
	private global: GlobalStore;

	constructor(
		private http: HttpClient,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	// CHECK THE NEWS
	versionCheck(current: string, saved: string): boolean {
		let curr, sav;

		if (current) {
			curr = current.split('.');
		}
		if (saved) {
			sav = saved.split('.');
		}

		// CHECK THE FIRST VERSION BITS ONLY TRUE FOR VERISON MAJOR/MINOR
		if (curr[0] !== sav[0]) return true;
		if (curr[1] !== sav[1]) return true;

		// PATCH VERISON
		// if (curr[2] !== sav[2]) return true;

		return false;
	}

	setNews(): void {
		const news = this.global.usersettings.find(({ key }) => key === 'fm_news');
		if (!news) {
			this.FM_Api.connect('usersettings').post({
				adminUser: this.global.user.id,
				key: 'fm_news',
				value: this.global.config.version,
			});
		} else {
			news.value = this.global.config.version;

			// UPDATE NEW VALUE
			this.FM_Api.connect('usersettings')
				.put({
					id: news.id,
					value: this.global.config.version,
				})
				.subscribe();
		}
	}

	recentItem(itemId: string, type: string): void {
		const now = new Date();

		const RECENT_COUNT = 10;
		let recentKey = 'fm_recents';

		if (type === 'switched') {
			recentKey = 'recent_switched';
			type = 'company';
		}

		if (this.global.usersettings) {
			let recentItems = this.global.usersettings.find(({ key }) => key === recentKey);

			if (!recentItems || !recentItems.value) {
				recentItems = {
					adminUser: this.global.user.id,
					key: recentKey,
					value: [],
				};

				recentItems.value.unshift({
					item: itemId,
					type: type,
					date: now,
				});

				// ADD THE NEW SETTING
				this.FM_Api.connect('usersettings').post(recentItems, (response) => {
					this.global.usersettings.push(response);
				});
			} else {
				const item = recentItems.value.find((obj) => obj && obj.item === itemId);

				// do not add item again
				if (!item) {
					recentItems.value.unshift({
						item: itemId,
						type: type,
						date: now,
					});
					recentItems.value.length = Math.min(recentItems.value.length, RECENT_COUNT);

					this.FM_Api.connect('usersettings')
						.put({ id: recentItems.id }, recentItems)
						.subscribe();
				} else if (item && recentItems.value.length > 1) {
					// shuffle item to beginning
					recentItems.value.splice(
						recentItems.value.findIndex(
							(obj) => obj.item === itemId && obj.type === type
						),
						1
					);

					recentItems.value.unshift({
						item: itemId,
						type: type,
						date: now,
					});
					recentItems.value.length = Math.min(recentItems.value.length, RECENT_COUNT);

					this.FM_Api.connect('usersettings')
						.put({ id: recentItems.id }, recentItems)
						.subscribe();
				}
			}
		}
	}

	getFavorites(): any {
		if (this.global.usersettings) {
			return this.global.usersettings.find(({ key }) => key === 'fm_favorites');
		} else {
			return null;
		}
	}

	addToFavorites(itemId: string, type: string): void {
		if (this.global.usersettings) {
			let favorites = this.getFavorites();

			if (!favorites) {
				favorites = {
					adminUser: this.global.user.id,
					key: 'fm_favorites',
					value: [],
				};

				favorites.value.push({
					item: itemId,
					type: type,
				});

				this.FM_Api.connect('usersettings').post(favorites, (response) => {
					// ADD THE NEW SETTING
					this.global.usersettings.push(response);
				});
			} else {
				favorites.value.unshift({
					item: itemId,
					type: type,
				});

				this.FM_Api.connect('usersettings').put(favorites).subscribe();
			}
		}
	}

	removeFromFavorites(itemId: string, type: string): void {
		const favorites = this.getFavorites();

		if (favorites) {
			favorites.value = reject(favorites.value, {
				item: itemId,
				type: type,
			});

			this.FM_Api.connect('usersettings').put(favorites).subscribe();
		}
	}

	checkFavorites(itemId: string, type: string): boolean {
		const favorites = this.getFavorites();

		let isFavorite = false;

		if (favorites) {
			isFavorite = some(favorites.value, {
				item: itemId,
				type: type,
			});
		}

		return isFavorite;
	}

	isUnique(field: string, value: string): Observable<any> {
		const httpOptions = {
			headers: { Authorization: 'Bearer ' + this.global.config.token },
		};

		const params = {};
		params[field] = value;

		const base = this.global.config.endpoints.api;
		return this.http.post(base + '/security/available', params, httpOptions);

		/*
		const obs = [];
		// TEST BOTH NA AND EU
		if (
			this.global.config.current === 'production' ||
			this.global.config.current === 'production-eu'
		) {
			const prodAPI = this.global.config.options['production'].api;
			const prodEUAPI = this.global.config.options['production-eu'].api;

			obs.push(this.http.post(prodAPI + '/security/available', params, httpOptions));
			obs.push(this.http.post(prodEUAPI + '/security/available', params, httpOptions));
		} else {
			const base = this.global.config.endpoints.api;

			obs.push(this.http.post(base + '/security/available', params, httpOptions));
		}

		// THIS FORK JOIN NOT WORKING
		return forkJoin([obs]).pipe(
			map((results) => {
				console.log('results', results);

				return results.every((data) => {
					return data;
				});
			}),
			tap((r) => {
				console.log('r', r);
			})
		);
		*/
	}

	exists(resource: string, field: string, params): Observable<any> {
		const httpOptions = { headers: { Authorization: 'Bearer ' + this.global.config.token } };
		const baseUrl = this.global.config.endpoints.api;
		return this.http.post(
			baseUrl + '/available/' + resource + '/' + field,
			params,
			httpOptions
		);
	}

	passwordReuse(params): Observable<any> {
		const httpOptions = { headers: { Authorization: 'Bearer ' + this.global.config.token } };
		const baseUrl = this.global.config.endpoints.api;
		return this.http.post(baseUrl + '/security/reuse/', params, httpOptions);
	}

	switchCompany(itemId: string): void {
		const RECENT_COUNT = 5;
		const recentKey = 'fm_switched';

		// const now = new Date();
		// const type = 'company';

		const switchList = localStorage.getItem(recentKey);

		// do we have list
		if (switchList) {
			const switchArray = JSON.parse(switchList);
			const currentIndex = switchArray.indexOf(itemId);

			// CHECK IF WE HAVE ITEM
			if (currentIndex !== -1) {
				switchArray.splice(currentIndex, 1);
				switchArray.unshift(itemId);

				switchArray.length = RECENT_COUNT;

				localStorage.setItem(recentKey, JSON.stringify(switchArray));
			} else {
				switchArray.unshift(itemId);
				switchArray.length = RECENT_COUNT;
				localStorage.setItem(recentKey, JSON.stringify(switchArray));
			}
		} else {
			localStorage.setItem(recentKey, JSON.stringify([itemId]));
		}
	}
}
