import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'fm-tags',
	templateUrl: './fm-tags.component.html',
	styleUrls: ['./fm-tags.component.less'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FmTagsComponent implements OnInit {
	@Input() tags: string[];
	@Input() readonly;

	@Output() tagsChange = new EventEmitter<string[]>();

	searchInput: string;

	// allowed-tags-pattern
	// pattern ="^[a-zA-Z0-9-s]+$"

	// constructor() {}
	ngOnInit(): void {
		if (!this.tags) {
			this.tags = [];
		}
	}

	runupdate(): void {
		this.tagsChange.emit(this.tags);
	}

	checkKey(e: KeyboardEvent): void {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (this.searchInput && this.searchInput !== '') {
				const exists = this.tags.includes(this.searchInput);

				if (!exists) {
					this.tags.push(this.searchInput);
					this.searchInput = '';
					this.runupdate();
				}
			}
		}
	}

	removeTag(tag: string): void {
		this.tags.splice(this.tags.indexOf(tag), 1);
		this.runupdate();
	}
}
