<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="EDIT_ACCOUNT"></h3>

		<a
			[routerLink]=""
			class="fm-button"
			(click)="viewProfile()"
			translate="VIEW_ACCOUNT"
			*fmPermission="{ key: 'adminuser', value: 'update' }"></a>
	</div>

	<div class="modal-body item-settings">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="FIRST"></label>
				<div class="field-help" translate="FIRST_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="first"
					type="text"
					#firstname="ngModel"
					[(ngModel)]="adminuser.firstname"
					placeholder="{{ 'FIRST' | translate }}"
					[readonly]="adminuser.isReadonly"
					required />
				<input-status class="input-status" [obj]="firstname"></input-status>
				<span
					class="error-message"
					*ngIf="firstname.errors?.required"
					translate="REQUIRED_FIELD"></span>
			</div>
		</div>

		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="LAST"></label>
				<div class="field-help" translate="LAST_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="last"
					type="text"
					#lastname="ngModel"
					[(ngModel)]="adminuser.lastname"
					placeholder="{{ 'LAST' | translate }}"
					[readonly]="adminuser.isReadonly"
					required />
				<input-status class="input-status" [obj]="lastname"></input-status>
				<span
					class="error-message"
					*ngIf="lastname.errors?.required"
					translate="REQUIRED_FIELD"></span>
			</div>
		</div>

		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="EMAIL"></label>
				<div class="field-help" translate="EMAIL_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="email"
					type="email"
					#email="ngModel"
					[(ngModel)]="adminuser.email"
					placeholder="{{ 'EMAIL' | translate }}"
					[fmUnique]="{ field: 'email' }"
					[readonly]="adminuser.isReadonly"
					autocomplete="nope"
					required />

				<input-status class="input-status" [obj]="email"></input-status>
				<div *ngIf="email.errors" class="error-message">
					<div *ngIf="email.errors.required" translate="REQUIRED_FIELD"></div>
					<div *ngIf="email.errors.email" translate="EMAIL_ERROR"></div>
					<div *ngIf="email.errors.unique" translate="EMAIL_NOT_UNIQUE"></div>
					<div *ngIf="email.errors.valid" translate="EMAIL_NOT_VALID"></div>
				</div>
			</div>
		</div>

		<div class="form-row" *ngIf="adminuser.id">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="SET_PASSWORD"></label>
					<div class="field-help" translate="SET_PASSWORD_HELP"></div>
				</div>

				<div class="field-toggle-wrap">
					<fm-toggle
						name="setPassword"
						[(ngModel)]="view.setPassword"
						[readonly]="adminuser.isReadonly"></fm-toggle>
				</div>
			</div>

			<div class="sub-settings settings-group" *ngIf="view.setPassword">
				<password-validate
					name="passwordValidate"
					[(ngModel)]="adminuser.password"
					[user]="adminuser"></password-validate>
			</div>
		</div>

		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="REQUIRE_MFA"></label>
					<div class="field-help" translate="REQUIRE_MFA_HELP"></div>
				</div>
				<div class="field-toggle-wrap">
					<fm-toggle
						name="require2FA"
						[(ngModel)]="adminuser.require2FA"
						(ngModelChange)="enable2FA()"
						[readonly]="adminuser.isReadonly"></fm-toggle>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer" *ngIf="view.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!view.isBusy">
		<button class="fm-button" translate="UPDATE" [disabled]="modalForm.invalid"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
