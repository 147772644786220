<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_CATEGORIES"></h3>
</div>
<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<h4 class="panel-title" translate="AVAILABLE"></h4>

			<div class="fm-quicksearch">
				<input
					type="text"
					class="fm-input"
					[(ngModel)]="qs"
					placeholder="{{ 'SEARCH' | translate }}..." />
				<a [routerLink]="" class="quicksearch-clear" (click)="clearQs()" *ngIf="qs"
					><i class="fa fa-times"></i
				></a>
			</div>

			<section class="fm-picker-list">
				<div class="fm-message notice" *ngIf="!isLoading && settings.length === 0">
					<i class="fa fa-dot-circle-o"></i>
					<span [translate]="'NO_SETTINGS'"></span>
				</div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="
							let item of filterList(settings, qs);
							trackBy: 'id' | trackByProperty
						"
						(click)="selectItem(item)"
						[ngClass]="{ selected: isSelected(item) }">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								<span [translate]="item"></span>
								<ul class="detail"></ul>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="isLoading" [error]="isFailed"></fm-loader>
			<list-status
				[loading]="isLoading"
				[count]="filtered?.length"
				[total]="settingsTotal"></list-status>
		</div>

		<div class="select-panel">
			<h4 class="panel-title" translate="SELECTED"></h4>
			<div class="modal-actions">
				<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL"></a>
			</div>

			<section class="fm-picker-list selected-list">
				<div
					class="fm-message notice"
					*ngIf="!selected.length"
					translate="NO_SELECTED"></div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of selected"
						(click)="removeItem(item)">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								<span [translate]="item"></span>
								<ul class="detail"></ul>
							</div>
						</div>
					</a>
				</div>
			</section>

			<div
				class="fm-list-count"
				translate="SELECTED_COUNT"
				[translateParams]="{ count: selected.length }"></div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" [disabled]="!selected.length" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
