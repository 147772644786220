export enum PayloadType {
	Alert = 'alert',
	Api = 'api',
	Left = 'demo',
	Html = 'html',
	Image = 'image',
	Passive = 'passive',
	Video = 'video',
	Url = 'url',
	Custom = 'custom',
	Demo = 'demo',
}
