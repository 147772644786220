<div class="filter-wrap">
	<ul class="filter-options" *ngIf="!editor.isLoading && optionList.length <= lengthLimit">
		<li
			class="fm-savedfilter"
			*ngFor="let filter of optionList; trackBy: 'id' | trackByProperty"
			(click)="filterClick(filter)"
			[ngClass]="{ active: isActive(filter) }">
			<a [routerLink]="">
				<span class="filter-status"><i class="fa fa-check"></i></span>
				<span [translate]="filter | uppercase"></span>
			</a>
		</li>
	</ul>
	<div class="typeahead" *ngIf="!editor.isLoading && optionList.length > lengthLimit">
		<div class="filter-editor">
			<input
				type="text"
				class="fm-input"
				[(ngModel)]="editor.qs"
				placeholder="{{ 'SEARCH_TYPES' | translate }}" />
			<span *ngIf="noResults && editor.qs" class="no-results" translate="NO_RESULTS"></span>
		</div>
		<ul class="filter-list" *ngIf="selected.length">
			<li class="fm-tag" *ngFor="let item of selected; trackBy: 'index' | trackByProperty">
				{{ item }}
				<div class="remove-tag">
					<i class="fa fa-times" (click)="clearItem(item)"></i>
				</div>
			</li>
		</ul>
	</div>
</div>
