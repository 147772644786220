import { Injectable } from '@angular/core';

import { GlobalConfig } from '@fmlib/interfaces';

import { LogService } from './log.service';

@Injectable({ providedIn: 'root' })
export class ConfigService {
	private config: GlobalConfig;
	private configOptions: any[];

	constructor(private FM_Log: LogService) {}

	init(): Promise<any> {
		return new Promise((resolve) => {
			if (this.config) {
				resolve(null);
			} else {
				fetch('/config')
					.then((res) => res.json())
					.then((response) => {
						this.set(response);
						resolve(response);

						/*
						// ADD IP LOOKUP
						fetch('https://extreme-ip-lookup.com/json/')
							.then((res) => res.json())
							.then((response) => {
								config.country = response.countryCode;
								this.setConfig(config);
								resolve(null);
							})
							.catch((reason) => {
								this.setConfig(config);
								resolve(null);
							});
							*/
					});
			}
		});
	}

	get(): GlobalConfig {
		return this.config;
	}

	set(config: GlobalConfig): void {
		// CHECK IF PROD OR EU
		let currentEnv = localStorage.getItem('fm_env');

		if (!currentEnv) {
			currentEnv = config.server;

			/*
			const sub = this.getSubDomain();
			if (sub === 'manage-eu' || sub === 'local-eu') {
				this.currentEnv = 'production-eu';
			}
			*/

			if (currentEnv === 'production') {
				// SET BASED ON TIMEZONE
				// IF NOT PACIFIC - EASTERN TZ
				// 660 === ALASKA
				// 180 === ARGENTINA
				/*
				const d = new Date(new Date().getFullYear(), 0, 1);
				const n = d.getTimezoneOffset();
				// console.log('TZ Offset : ', n);
				if (n > 660 || n < 180) {
					this.currentEnv = 'production-eu';
				}
				*/
			}
		}

		this.config = config;

		// store options
		this.configOptions = this.config.options;
		delete this.config.options;

		this.config.endpoints = this.configOptions[currentEnv];
		this.config.current = this.config.endpoints.env;

		this.config.isDebug =
			this.config.server === 'local' || window.location.hostname === 'localhost';

		this.config.timeout = {
			api: 30000,
			loading: 500,
			search: 500,
		};

		// SET ON WINDOW OBJ
		window['fm_config'] = this.config;

		this.FM_Log.info('config : init', this.config);
	}

	updateEnv(newEnv: string): GlobalConfig {
		this.config.endpoints = this.configOptions[newEnv];
		this.config.current = this.config.endpoints.env;

		// SET ON WINDOW OBJ
		window['fm_config'] = this.config;
		localStorage.setItem('fm_env', this.config.current);

		return this.config;
	}
}
