<div class="preview-content" *ngIf="isType(payloadType.Passive)">
	<div class="preview-missing">
		<h4 translate="PASSIVE_PAYLOAD"></h4>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Demo)">
	<div class="preview-missing">
		<h4 translate="DEMO"></h4>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Alert)">
	<div class="preview-missing">
		<i class="fa fa-exclamation-circle"></i>
		<h4 translate="ALERT"></h4>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Custom)">
	<div class="preview-missing">
		<h4 translate="CUSTOM_PAYLOAD"></h4>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Api)">
	<div class="preview-missing">
		<h4 translate="CUSTOM_API"></h4>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Html)">
	<div class="preview-missing">
		<h4 translate="HTML"></h4>
		<div class="missing-notice" translate="NO_PREVIEW"></div>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Url)">
	<div class="preview-missing">
		<h4 translate="URL"></h4>
		<a
			class="link-external"
			[href]="payload.content.url"
			target="_blank"
			translate="VIEW_PREVIEW"></a>
	</div>
</div>

<div class="preview-content" *ngIf="isType(payloadType.Image)">
	<img *ngIf="hasContent" [src]="payload.content.url" />

	<div *ngIf="!hasContent" class="preview-missing">
		<i class="fa fa-search"></i>
		<h4 translate="PREVIEW_MISSING"></h4>
	</div>
</div>

<ng-container *ngIf="isType(payloadType.Video)">
	<div class="preview-content" *ngIf="!hasContent">
		<div class="preview-missing">
			<i class="fa fa-search"></i>
			<h4 translate="PREVIEW_MISSING"></h4>
		</div>
	</div>
	<ng-container *ngIf="hasContent">
		<div
			class="preview-content"
			*ngIf="payload.content.provider === 'youtube'"
			[ngClass]="payload.type">
			<iframe
				width="100%"
				height=""
				[src]="cleanURL(videoURL)"
				frameborder="0"
				allowfullscreen></iframe>
		</div>

		<div
			class="preview-content"
			*ngIf="payload.content.provider === 'vimeo'"
			[ngClass]="payload.type">
			<iframe
				width="100%"
				height=""
				[src]="cleanURL(videoURL)"
				frameborder="0"
				allowfullscreen></iframe>
		</div>

		<div
			class="preview-content"
			*ngIf="payload.content.provider === 'custom'"
			[ngClass]="payload.type">
			<video width="100%" controls>
				<source [src]="cleanURL(videoURL)" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	</ng-container>
</ng-container>
