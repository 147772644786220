import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'contact-dialog',
	templateUrl: './contact-dialog.component.html',
})
export class ContactDialogComponent implements OnInit {
	list_timer;
	map_timer1;
	map_timer2;

	contact;
	typeOptions;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ContactDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		if (!this.data.contact) {
			this.data.contact = {};
		}

		this.contact = cloneDeep(this.data.contact);

		this.typeOptions = [
			{ key: 'admin', label: 'admin' },
			{ key: 'technical', label: 'technical' },
			{ key: 'billing', label: 'billing' },
		];
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.contact);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
