import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Event, NavigationStart, Router } from '@angular/router';

@Component({
	selector: 'nearby-dialog',
	templateUrl: './nearby-dialog.component.html',
})
export class NearbyDialogComponent implements OnInit {
	settings;

	constructor(
		@Inject(Router) private router: Router,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<NearbyDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.settings = {
			item: this.data.item,
			objtype: this.data.objtype,
		};

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show progress spinner or progress bar
				this.dialogRef.close();
			}
		});
	}

	// MODAL ACTIONS
	close(): void {
		this.dialogRef.close();
	}
}
