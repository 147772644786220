<ng-container *ngIf="isBasic">
	<router-outlet #routerOutlet="outlet"></router-outlet>
</ng-container>

<ng-container *ngIf="!isBasic && global">
	<!--
		<a [routerLink]="" class="login-controls" (click)="view.showLogin = !view.showLogin">Hide Login</a>
	-->

	<ng-container *ngIf="view.showLogin">
		<login class="login-page"></login>
	</ng-container>

	<section class="site-main" @openComp *ngIf="view.showMain">
		<fm-header></fm-header>

		<!-- SITE CONTENT -->
		<section class="fm-container">
			<main-menu class="site-nav" [class]="{ active: view.showMainMenu }"></main-menu>
			<div class="site-content">
				<router-outlet #routerOutlet="outlet"></router-outlet>
			</div>
		</section>

		<ng-container *ngIf="view.showNews">
			<!-- CURRENT UPDATES -->
			<section class="fm-popup">
				<div class="popup-bkgd"></div>
				<fm-news [view]="view"></fm-news>
			</section>
		</ng-container>

		<!-- COMPANY SWITCH -->
		<ng-container *ngIf="changingCompany">
			<section class="fm-popup">
				<div class="popup-bkgd"></div>
				<div class="popup-content">
					<div class="popup-header">
						<h2 translate="SWITCHING_COMPANIES"></h2>
					</div>
					<div class="popup-body">
						<div class="switch-wrap" [ngClass]="{ changed: newCompany }">
							<div class="company-switcher">
								<profile-image
									[objtype]="ProfileType.COMPANY"
									[obj]="global.company"></profile-image>
								<h4 [innerText]="global.company.name"></h4>
							</div>
							<div class="company-switcher" *ngIf="newCompany">
								<profile-image
									[objtype]="ProfileType.COMPANY"
									[obj]="newCompany"></profile-image>
								<h4 [innerText]="newCompany.name"></h4>
							</div>
						</div>
					</div>
					<div class="popup-footer">
						<h4 translate="LOADING"></h4>
						<i class="fa fa-circle-notch fa-spin"></i>
					</div>
				</div>
			</section>
		</ng-container>
	</section>
</ng-container>
