export enum DataType {
	BOOLEAN = 'bool',
	CURRENCY = 'currency',
	DATETIME = 'datetime',
	NUMBER = 'number',
	STRING = 'string',
	LIST = 'list',
	JSON = 'json',

	// OLD
	DOUBLE = 'double',
	INTEGER = 'int',
}
