import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'fm-delivered',
	template: `<ul class="delivered-info">
		<li [innerText]="deliveredDisplay"></li>
		<li *ngIf="showLimit" class="limit-seperator">of</li>
		<li class="limit-info" *ngIf="showLimit" [innerText]="maxDisplay"></li>
	</ul>`,
})
export class FmDeliveredComponent implements OnChanges {
	@Input() obj;

	deliveredDisplay: string;
	maxAllowed: number;
	maxDisplay: string;
	showLimit: boolean;

	ngOnChanges(): void {
		this.showLimit = false;
		this.obj.timesDelivered = !this.obj.timesDelivered ? 0 : this.obj.timesDelivered;

		this.deliveredDisplay = this.shortenLargeNumber(this.obj.timesDelivered, 1);

		if (this.obj.maxAllowed && this.obj.maxAllowed !== 0) {
			this.showLimit = true;
			this.maxDisplay = this.shortenLargeNumber(this.obj.maxAllowed, 1);
		}
	}

	shortenLargeNumber(num: number, digits: number): string {
		const units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
		let ret = num ? num.toString() : '0';

		for (let i = units.length - 1; i >= 0; i--) {
			const decimal = Math.pow(1000, i + 1);

			if (num <= -decimal || num >= decimal) {
				ret = +(num / decimal).toFixed(digits) + units[i];
				break;
			}
		}

		return ret;
	}
}
