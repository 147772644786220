import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { PayloadType } from '@fmlib/enums';
import { Payload } from '@fmlib/interfaces';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [CommonModule, TranslateModule],
	selector: 'payload-preview',
	templateUrl: './payload-preview.component.html',
	styleUrls: ['./payload-preview.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class PayloadPreviewComponent implements OnChanges {
	@Input() payload: Payload;

	hasContent: boolean;
	videoThumb: string;
	videoURL: string;

	get payloadType(): typeof PayloadType {
		return PayloadType;
	}

	constructor(private sanitizer: DomSanitizer) {
		this.videoURL = '';
	}

	watchVideo(): void {
		// console.log('this.videoURL', this.videoURL);
	}

	setupVideo(): void {
		let test, key;
		const url = this.payload.content.url;

		if (this.payload.content.provider === 'youtube') {
			if (url) {
				key = url.substr(url.lastIndexOf('/') + 1);

				// CHECK IF WE HAVE A EQUAL SIGN
				test = key.substr(key.lastIndexOf('=') + 1);

				if (test) {
					key = test;
				}

				this.videoURL = 'https://www.youtube.com/embed/' + key;
				this.videoThumb = 'https://img.youtube.com/vi/' + key + '/hqdefault.jpg';
			}
		} else if (this.payload.content.provider === 'vimeo') {
			if (url) {
				key = url.substr(url.lastIndexOf('/') + 1);
				this.videoURL = 'https://player.vimeo.com/video/' + key;
			}
		} else if (this.payload.content.provider === 'custom') {
			if (url) {
				this.videoURL = url;
			}
		}
	}

	ngOnChanges(): void {
		if (this.payload) {
			this.hasContent = this.payload.content && this.payload.content.url;

			if (this.hasContent && this.payload.type === PayloadType.Video) {
				this.setupVideo();
			}
		}
	}

	isType(type: PayloadType): boolean {
		return this.payload.type === type;
	}

	cleanURL(url: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
