import { Component, Input } from '@angular/core';

@Component({
	selector: 'input-status',
	template: `<span class="input-loader" *ngIf="isLoading()"
			><i class="fa fa-circle-notch fa-spin"></i
		></span>
		<span class="input-error" *ngIf="isError()"><i class="fa fa-ban"></i></span>
		<span class="input-success" *ngIf="isSuccess()"><i class="fa fa-check"></i></span>
		<span class="input-edited" *ngIf="isEdited()"><i class="fa fa-pencil"></i></span>`,
})
export class InputStatusComponent {
	@Input() obj;
	@Input() loading: boolean;

	isLoading(): boolean {
		return this.obj && (this.obj.pending || this.loading);
	}

	isError(): boolean {
		return this.obj && !this.loading && this.obj.invalid;
	}

	isSuccess(): boolean {
		return this.obj && !this.loading && this.obj.valid;
	}

	isEdited(): boolean {
		return this.obj && !this.loading && this.obj.dirty;
	}
}
