<div class="modal-header">
	<h3 class="modal-title" translate="HISTORY"></h3>
	<div class="modal-itemname" [innerText]="data.obj.name"></div>
</div>

<audit
	class="modal-body fm-audit"
	[obj]="data.obj"
	[objtype]="data.objtype"
	[filters]="data.filter"></audit>

<div class="modal-footer">
	<button class="fm-button" (click)="close()" translate="CLOSE"></button>
</div>
