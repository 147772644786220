import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitString' })
export class LimitStringPipe implements PipeTransform {
	transform(text: string, length?: number): string {
		const lengthLimit = length ? length : 70;

		if (!text || text === '') {
			text = '---';
		} else if (text.length > lengthLimit) {
			text = text.substring(0, lengthLimit) + '...';
		}

		return text;
	}
}
