import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DataService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { reject } from 'lodash-es';

@Component({
	selector: 'select-states',
	templateUrl: './select-states.component.html',
})
export class SelectStatesDialogComponent implements OnInit, AfterViewInit {
	global: GlobalStore;
	selected: { id: string; label: string }[];
	states: { id: string; label: string }[];
	search: string;

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SelectStatesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(DataService) private FM_Data: DataService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.initSearch();
	}

	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	loadList(): void {
		// LOAD STATES OPTIONS
		this.FM_Data.getStates().subscribe((response) => {
			this.selected = response.filter((item) => {
				return this.data.selected.indexOf(item.id) !== -1;
			});
			this.states = response;
		});
	}

	initSearch(): void {
		this.loadList();
	}

	selectItem(obj): void {
		this.selected.push(obj);
	}

	removeItem(item): void {
		this.selected = reject(this.selected, item);
	}

	isSelected(obj): boolean {
		return this.selected.indexOf(obj) !== -1;
	}

	clearQuicksearch(): void {
		this.search = null;
	}

	clearAll(): void {
		this.selected = [];
	}

	// MODAL ACTIONS
	ok(): void {
		const selectedIds = this.selected.map(({ id }) => id);
		this.dialogRef.close(selectedIds);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
