import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ApiService } from '@fm/services';

import { cloneDeep, isEqual, map, some } from 'lodash-es';
import { SearchParams } from '@fmlib/interfaces';

@Component({
	selector: 'filter-range',
	templateUrl: './filter-range.component.html',
})
export class FilterRangeComponent implements OnInit, OnChanges, OnDestroy {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	currentFilter;
	editor;
	list;
	range;
	settings: { api: string; placeholder: string; prop: string };

	rangeChanged: Subject<any> = new Subject<any>();

	constructor(private FM_Api: ApiService) {}

	ngOnInit(): void {
		this.editor = {
			typeahead: {},
		};

		this.range = {};

		this.list = [];

		this.settings = {
			api: 'assetranges',
			placeholder: 'RANGES',
			prop: 'name',
		};

		this.open();

		this.rangeChanged.pipe(debounceTime(400)).subscribe((model) => {
			this.range = model;

			if (this.range.start && this.range.end) {
				this.list = [{ name: this.range.start + '-' + this.range.end }];
				this.runupdate();
			} else if (!this.range.start && !this.range.end) {
				this.list = [];
				this.runupdate();
			}
		});
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = cloneDeep(this.filter);
		}
	}

	open(): void {
		this.getList(null);
		this.initFilter();
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	/*
	filterClick(filter): void {
		if (this.list === 'all') {
			this.list = [];
		}

		let check = some(this.list, { id: filter.id });

		if (!check) {
			this.list.push(filter);
		} else {
			this.list = reject(this.list, { id: filter.id });
		}

		this.runupdate();
	}
	*/

	// CHECK
	isActive(filter): boolean {
		const check = some(this.list, { id: filter.id });
		return check;
	}

	setItem(val): void {
		delete this.range.start;
		delete this.range.end;

		this.list = [val];

		this.runupdate();
	}

	getList(val: string): any {
		this.editor.typeahead.isLoading = true;

		const params: SearchParams = {
			select: 'name ' + this.settings.prop,
			sort: 'name',
			per_page: 10,
			conditions: {},
		};

		if (val && val !== '') {
			params.conditions.searchText = { $regex: val.toLowerCase() };
		}

		//REMOVE EXISTING
		if (this.list.length) {
			params.conditions[this.settings.prop] = {
				$nin: map(this.list, this.settings.prop),
			};
		}

		return this.FM_Api.connect(this.settings.api)
			.query(params, (data, headers) => {
				this.editor.typeahead.list = data;
				this.editor.typeahead.isLoading = false;

				if (!this.editor.typeahead.total) {
					this.editor.typeahead.total = parseInt(headers.get('X-Records-Total'), 10);
				}
			})
			.then((response) => {
				return response;
			});
	}

	initFilter(): void {
		const list = cloneDeep(this.filter);

		if (list) {
			this.editor.isLoading = true;
			const obj = {};
			obj[this.settings.prop] = { $in: list };
			this.FM_Api.connect(this.settings.api).query(
				{
					page: 1,
					per_page: 300,
					select: 'name ' + this.settings.prop,
					conditions: obj,
				},
				(res) => {
					if (res.length) {
						res.forEach((item) => {
							this.list.push(item);
						});
					} else {
						const r = list[0].split('-');

						if (r) {
							this.range.start = r[0];
							this.range.end = r[1];
						}
					}

					this.editor.isLoading = false;
				}
			);
		}
	}

	clearCustom(): void {
		this.range.start = null;
		this.range.end = null;
		this.rangeChanged.next(this.range);
	}

	clearFilter(): void {
		this.list = [];
	}

	updateRange(): void {
		this.rangeChanged.next(this.range);
	}

	runupdate(): void {
		if (this.list.length === 0) {
			delete this.filter;
		} else {
			this.filter = map(this.list, this.settings.prop);
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);
			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	ngOnDestroy(): void {
		this.rangeChanged.unsubscribe();
	}
}
