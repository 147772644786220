import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'emptyNumber',
})
export class EmptyNumberPipe implements PipeTransform {
	transform(value: number): string {
		if (!value || value === 0) {
			value = 0;
		}

		// UP TO 10K
		if (value <= 9999) {
			return value.toString();
		}
		// OVER 10K
		else if (value >= 10000 && value <= 999999) {
			return (value / 1000).toFixed(1) + 'K';
		}
		// millions
		else if (value >= 1000000 && value <= 999999999) {
			return (value / 1000000).toFixed(1) + 'M';
		}
		// billions
		else if (value >= 1000000000 && value <= 999999999999) {
			return (value / 1000000000).toFixed(1) + 'B';
		} else {
			return value.toString();
		}
	}
}
