import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { DataType } from '@fmlib/enums';
import { ApiService, DataService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { isEqual } from 'lodash-es';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-attributes',
	templateUrl: './filter-attributes.component.html',
})
export class FilterAttributesComponent implements OnInit, OnChanges {
	@Input() filter;

	@Input() type;
	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	global: GlobalStore;

	attribute;
	attributeOptions;
	currentFilter;
	dataTypeOptions: DataType[];

	DataType = DataType;

	constructor(
		private FM_Api: ApiService,
		private FM_Data: DataService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.attribute = {};
		this.dataTypeOptions = this.FM_Data.dataTypes();
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		}
	}

	open(): void {
		this.initFilter();
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		// CLEAR THE PROP
		if (this.attribute && this.attribute.key && this.attribute.value) {
			this.filter = {
				$elemMatch: {
					key: this.attribute.key,
					value: this.attribute.value,
				},
			};
		} else {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = this.FM_Data.clone(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	getType(key: string): DataType {
		const t = this.dataTypeOptions.find((id) => id === key);
		return t ? t : DataType.STRING;
	}

	setAttribute(): void {
		if (this.attribute.key) {
			this.attribute.dataType = this.attributeOptions.find(
				({ key }) => key === this.attribute.key
			).dataType;
			this.runupdate();
		}
	}

	clearAttribute(): void {
		delete this.attribute.value;
		this.runupdate();
	}

	clearFilter(): void {
		if (this.attribute && this.attribute.key && this.attribute.value) {
			this.attribute = {};
		}

		delete this.filter;
	}

	initFilter(): void {
		this.FM_Api.connect('companies').get(
			{
				id: this.global.company.id,
				select: 'attributeSearch',
			},
			(response) => {
				this.attributeOptions = response.attributeSearch.filter(
					({ objectType }) => objectType === this.type
				);

				if (this.filter) {
					this.currentFilter = this.FM_Data.clone(this.filter);
					this.attribute = this.FM_Data.clone(this.filter.$elemMatch);
					this.attribute.dataType = this.attributeOptions.find(
						({ key }) => key === this.attribute.key
					).dataType;
				}
			}
		);
	}
}
