import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep, reject } from 'lodash-es';

@Component({
	selector: 'select-experience-dialog',
	templateUrl: './select-experiences-dialog.component.html',
})
export class SelectExperiencesDialogComponent implements OnInit {
	global: GlobalStore;
	search;
	selected;

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SelectExperiencesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.search = {
			endpoint: this.FM_Api.connect('experiences'),
			filters: {},
			isLoading: false,
			isFailed: false,
		};

		this.selected = cloneDeep(this.data.selected);

		this.initSearch();
	}

	checkSelected(): void {
		// FIND THE SELECTED ITEMS
		this.search.list.forEach((item) => {
			item.isSelected = this.selected.some((i) => {
				return i.id === item.id;
			});
		});
	}

	initSearch(): void {
		this.search.count = 0;
		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];

		this.loadList();
	}

	performSearch(params): any {
		return new Promise((resolve) => {
			if (this.search.qs && this.search.qs !== '') {
				params.conditions.searchText = { $regex: this.search.qs.toLowerCase() };
			} else {
				delete params.conditions.searchText;
			}

			this.search.endpoint.query(params, (data, headers) => {
				this.search.pages = parseInt(headers.get('X-Pages-Total'), 10);
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);

				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;

				this.search.page = this.search.page + 1;

				this.search.isLoading = false;

				// ON COMPLETE
				resolve(null);
			});
		});
	}

	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isLoading = true;

		const params = {
			page: this.search.page,
			sort: 'name',
			populate: 'payload',
			conditions: {},
		};

		this.performSearch(params).then(() => {
			this.checkSelected();
		});
	}

	// SELECT ALL
	selectAll(): void {
		// CHECK IF ALL LOADED
		if (this.search.list.length === this.search.total) {
			this.selected = this.search.list;

			this.search.list.forEach((item) => {
				item.isSelected = true;
			});
		} else {
			this.search.list = [];

			if (this.search.isLoading) return;
			this.search.isLoading = true;

			const params = {
				page: 1,
				sort: 'name',
				conditions: {},
				per_page: this.search.total,
			};

			this.performSearch(params).then(() => {
				this.selected = this.search.list;

				this.search.list.forEach((item) => {
					item.isSelected = true;
				});
			});
		}
	}

	// CLEAR ALL
	clearAll(): void {
		this.selected = [];

		this.search.list.forEach((item) => {
			item.isSelected = false;
		});
	}

	// ADD ITEM TO SELECTED
	selectItem(obj): void {
		if (!obj.isSelected) {
			obj.isSelected = true;

			this.selected.push(obj);
		} else {
			this.removeItem(obj);
		}
	}

	// REMOVE ITEM FROM SELECTED
	removeItem(obj): void {
		const item = this.search.list.find(({ id }) => id === obj.id);
		//IS THE ITEM LOADED SEAT STATE
		if (item) {
			item.isSelected = false;
		}
		this.selected = reject(this.selected, { id: obj.id });
	}

	clearQuicksearch(): void {
		this.search.qs = null;
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.selected);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
