<div class="popup-content">
	<div class="popup-header">
		<h2 translate="WHATS_NEW"></h2>
		<span class="version" [innerText]="global.config.version"></span>
	</div>
	<div class="popup-body">
		<ul *ngIf="news">
			<li *ngFor="let item of news" [innerText]="item"></li>
		</ul>
		<ul *ngIf="news">
			<li>Lots of great improvements</li>
			<li>More info coming soon</li>
		</ul>
	</div>

	<div class="popup-footer">
		<a [routerLink]="" class="fm-button" (click)="closeNews()" translate="OK"></a>
	</div>
</div>
