<form
	#modalForm="ngForm"
	name="modalForm"
	class="modal-form"
	(ngSubmit)="ok(modalForm)"
	novalidate="novalidate"
	autocomplete="nope">
	<div class="modal-header">
		<h3 class="modal-title" translate="BATCH_ADD"></h3>
	</div>

	<div class="modal-profile">
		<div class="form-row">
			<label for="" class="label">Type of Item </label>
			<div class="field-editor">
				<select [(ngModel)]="batch.type" required>
					<option
						[value]="op.id"
						*ngFor="let op of batchTypes"
						[translate]="op.name"></option>
				</select>
			</div>
		</div>
		<div class="form-row">
			<label for="" class="label">Items to create </label>
			<div class="field-editor">
				<input class="fm-input" type="number" [(ngModel)]="batch.count" required />
			</div>
		</div>
		<div class="form-row">
			<label for="" class="label">Base Name</label>
			<div class="field-editor">
				<input class="fm-input" type="text" [(ngModel)]="batch.name" required />
			</div>
		</div>
	</div>
	<div class="modal-footer" *ngIf="view.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!view.isBusy">
		<button class="fm-button" translate="SAVE"></button>
		<a class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
