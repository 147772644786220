import { Component } from '@angular/core';

@Component({
	selector: 'updating-message',
	template: `<div class="updating-message">
		<h4 translate="UPDATING"></h4>
		<fm-loader [type]="'ellipsis'"></fm-loader>
	</div>`,
})
export class UpdatingMessageComponent {}
