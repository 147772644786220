<div class="modal-header">
	<h3 class="modal-title" translate="ATTENTION"></h3>
</div>

<div class="modal-body">
	<h4 class="confirm-title" [translate]="message"></h4>

	<div class="form-row">
		<label translate="NAME"></label>
		<input class="fm-input" [(ngModel)]="obj.name" />
	</div>

	<ng-container *ngIf="global.user.isSuper">
		<ng-container *ngIf="objtype === ProfileType.ASSET">
			<div class="form-row confirm-message">
				<div class="fm-message remove">
					<mat-checkbox
						[(ngModel)]="cloneProps.ranges"
						(change)="checkConfirm()"
						labelPosition="before"
						><span class="message" translate="INCLUDE_RANGES"></span
					></mat-checkbox>
				</div>
				<div class="fm-message remove" *ngIf="confirmAgain">
					<mat-checkbox [(ngModel)]="userinput.agreed" labelPosition="before"
						><span class="message" translate="USER_ACTION_AGREE"></span
					></mat-checkbox>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>

<div class="modal-footer">
	<button
		class="fm-button"
		[disabled]="cloneProps.ranges && !userinput.agreed"
		(click)="ok()"
		translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
