import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { ProfileType } from '@fmlib/enums';
import { GlobalStore, Partner, Schedule } from '@fmlib/interfaces';
import { GlobalService } from '@fm/services';

import { addMinutes, addSeconds, format, startOfDay } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class UtilsService {
	private global: GlobalStore;

	constructor(
		private FM_Global: GlobalService,
		private translate: TranslateService
	) {
		this.global = this.FM_Global.get();
	}

	/** get utc date */
	getUtcDate(date: string): Date {
		const d = new Date(date);
		const utcDate = addMinutes(d, d.getTimezoneOffset());
		return utcDate;
	}

	/** get utc date string at start of date */
	getUtcStart(date: string): string {
		const dates = date.split('T');
		const timestring = 'T00:00:00Z';
		const todayUtc = dates[0] + timestring;

		return todayUtc;
	}

	/** get utc date string at end of date */
	getUtcEnd(date: string): string {
		const dates = date.split('T');
		const timestring = 'T23:59:59Z';
		const todayUtc = dates[0] + timestring;

		return todayUtc;
	}

	getNamePluralized(name: ProfileType): string {
		if (name === ProfileType.COMPANY) {
			return 'companies';
		} else if (name === ProfileType.PUSH) {
			return 'pushes';
		} else {
			return name + 's';
		}
	}

	getPageName(type: ProfileType): string {
		return this.getNamePluralized(type).toUpperCase();
	}

	getPartner(cid: string): Partner {
		const p = this.global.partners.to.find((obj) => obj.partner && obj.partner.id === cid);

		if (p && p.partner) {
			return p.partner;
		} else {
			return null;
		}
	}

	formatSchedule(schedule: Schedule): any {
		let end, startTime, endTime, t;

		const formatted: any = {};
		const datePipe = new DatePipe('en-US');
		const dates = [];
		const times = [];

		const start = format(this.getUtcDate(schedule.startDate), 'LLL d, yyyy');

		dates.push(start);

		if (schedule.endDate) {
			end = format(this.getUtcDate(schedule.endDate), 'LLL d, yyyy');
			dates.push(end);
		}

		const d = dates.join(' - ');

		if (schedule.limitTimes) {
			startTime = addSeconds(startOfDay(new Date()), schedule.startTime);

			times.push(datePipe.transform(startTime, 'shortTime'));
			if (schedule.endTime !== 0) {
				endTime = addSeconds(startOfDay(new Date()), schedule.endTime);
				times.push(datePipe.transform(endTime, 'shortTime'));
			}

			t = times.join(' - ');
		} else {
			t = this.translate.instant('ALL_TIME');
		}

		const days = schedule.limitDays
			? schedule.daysOfWeek.join(', ')
			: this.translate.instant('ALL_DAYS');

		formatted.dates = d;
		formatted.times = t;
		formatted.days = days;

		return formatted;
	}

	// GENERATE RANDOMS
	getRandomColor(): string {
		const r = Math.round(Math.random() * 255);
		const g = Math.round(Math.random() * 255);
		const b = Math.round(Math.random() * 255);

		return 'rgb(' + r + ',' + g + ',' + b + ')';
	}

	getRandomInt(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	getRandom(min: number, max: number): number {
		return Math.random() * (max - min) + min;
	}

	queryToJson(q: string): any {
		const pairs = q.split('&');
		const result = {};
		pairs.forEach((pair) => {
			const split = pair.split('=');
			result[pair[0]] = decodeURIComponent(split[1] || '');
		});

		return JSON.parse(JSON.stringify(result));
	}

	convertToCSV(objArray: any[], headers: string[]): string {
		let csvData = headers.join(',') + '\r\n';
		for (let i = 0; i < objArray.length; i++) {
			const lines = [];
			for (const index in headers) {
				const head = headers[index];
				lines.push(JSON.stringify(objArray[i][head]));
			}
			csvData += lines.join(',') + '\r\n';
		}
		return csvData;
	}

	downloadFile(data: any[], headers: string[], filename = 'data'): void {
		const csvData = this.convertToCSV(data, headers);
		const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		const dwldLink = document.createElement('a');
		const url = URL.createObjectURL(blob);
		const isSafariBrowser =
			navigator.userAgent.indexOf('Safari') !== -1 &&
			navigator.userAgent.indexOf('Chrome') === -1;
		if (isSafariBrowser) {
			//if Safari open in new window to save file with random filename.
			dwldLink.setAttribute('target', '_blank');
		}
		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', filename + '.csv');
		dwldLink.style.visibility = 'hidden';
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}
}
