import { NgModule } from '@angular/core';

import { FiltersModule } from '../filter/filter.module';
import { SharedModule } from '../shared/shared.module';

import { NearbyComponent } from './nearby.component';
import { NearbyDialogComponent } from './nearby-dialog.component';

const components = [
	//
	NearbyComponent,
	NearbyDialogComponent,
];

@NgModule({
	imports: [
		//
		FiltersModule,
		SharedModule,
	],
	declarations: components,
	exports: components,
})
export class NearbyModule {}
