import { Injectable } from '@angular/core';

import { ProfileType } from '@fmlib/enums';

export enum SortOrder {
	ASC = '',
	DESC = '-',
}

export interface SortOption {
	id: string;
	name: string;
}

export interface SortItem {
	option: SortOption;
	order: SortOrder;
}

@Injectable()
export class SortService {
	getDefault(type: ProfileType): SortItem {
		switch (type) {
			case ProfileType.ASSET:
			case ProfileType.PROGRAM:
				return {
					option: { id: 'created', name: 'CREATED' },
					order: SortOrder.DESC,
				};
			case ProfileType.BEACON:
				return {
					option: { id: 'lastSeen', name: 'LAST_SEEN' },
					order: SortOrder.DESC,
				};

			case ProfileType.PARTNER:
				return {
					option: { id: 'partnerName', name: 'NAME' },
					order: SortOrder.ASC,
				};

			case ProfileType.GATEWAY:
				return {
					option: { id: 'siteName', name: 'SITE' },
					order: SortOrder.ASC,
				};

			default:
				return {
					option: { id: 'updated', name: 'UPDATED' },
					order: SortOrder.DESC,
				};
		}
	}

	sortOptions(type: ProfileType): SortOption[] {
		switch (type) {
			case ProfileType.ASSET:
				return [
					{ id: 'name', name: 'NAME' },
					{ id: 'created', name: 'CREATED' },
					{ id: 'updated', name: 'UPDATED' },
					{ id: 'percentExecuted', name: 'EXECUTION' },
				];
			case ProfileType.PROGRAM:
				return [
					{ id: 'name', name: 'NAME' },
					{ id: 'created', name: 'CREATED' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.ADMINUSER:
				return [
					{ id: 'firstname', name: 'FIRSTNAME' },
					{ id: 'lastname', name: 'LASTNAME' },
					{ id: 'email', name: 'EMAIL' },
					{ id: 'lastLogin', name: 'LAST_LOGIN' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.GATEWAY:
				return [
					{ id: 'siteName', name: 'SITE' },
					{ id: 'lastReceivedData', name: 'LAST_SEEN' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.GEOFENCE:
				return [
					{ id: 'siteName', name: 'NAME' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.BEACON:
			case ProfileType.NFC:
				return [
					{ id: 'lastSeen', name: 'LAST_SEEN' },
					{ id: 'lastLocationUpdate', name: 'LOCATION_CHANGE' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.PARTNER:
				return [
					{ id: 'partnerName', name: 'NAME' },
					{ id: 'updated', name: 'UPDATED' },
				];
			case ProfileType.SITE:
				return [{ id: 'updated', name: 'UPDATED' }];
			default:
				return [
					{ id: 'name', name: 'NAME' },
					{ id: 'updated', name: 'UPDATED' },
				];
		}
	}

	setSort(sort): void {
		sessionStorage.setItem('fm_sort', JSON.stringify(sort));
	}
}
