<div class="server-env" *ngIf="global.config.isDebug" [innerText]="global.config.server"></div>

<div class="form-options" *ngIf="envOptions?.length > 1">
	<div class="btn-group">
		<label
			class="btn"
			[ngClass]="{ active: this.global.config.current === env.key }"
			(click)="updateEnv(env.key)"
			*ngFor="let env of envOptions">
			<span [translate]="env.name"></span>
		</label>
	</div>
</div>
