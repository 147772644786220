import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [CommonModule, TranslateModule],
	selector: 'fm-loader',
	templateUrl: './fm-loader.component.html',
})
export class FmLoaderComponent {
	@Input() loading: boolean;
	@Input() error: boolean;
	@Input() type: string;

	get isEllipsis(): boolean {
		return this.type === 'ellipsis';
	}
}
