import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { GlobalConfig } from '@fmlib/interfaces';
import { ConfigService, LogService } from '@fm/services';

import { isObject } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ApiService {
	private config: GlobalConfig;
	private api_timeout: number;
	private basePath: string;
	private httpOptions: any;

	constructor(
		private http: HttpClient,
		private FM_Config: ConfigService,
		private FM_Log: LogService
	) {}

	// INIT WITH CONFIG
	init(): void {
		this.config = this.FM_Config.get();

		this.FM_Log.info('api : init');

		this.basePath = this.config.endpoints.api;
		this.api_timeout = this.config.timeout.api;

		this.setHeaders();
	}

	setHeaders(): void {
		this.httpOptions = {
			headers: {
				Authorization: 'Bearer ' + this.config.token,
			},
		};
	}

	// WHO IS LOGGED IN
	me(): Observable<any> {
		this.setHeaders();

		return this.http
			.get(`${this.basePath}/me`, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	// BASIC GET
	get(endpoint: string, params?: any, observe?: string): Observable<any> {
		const options = Object.assign({}, this.httpOptions);

		if (params) {
			options.params = params;
		}

		if (observe) {
			options.observe = observe;
		}

		return this.http
			.get<any[]>(`${this.basePath}/${endpoint}`, options)
			.pipe(timeout(this.api_timeout));
	}

	post(endpoint: string, params: any): Observable<any> {
		return this.http
			.post<any>(`${this.basePath}/${endpoint}`, params, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	put(endpoint: string, params: any): Observable<any> {
		return this.http
			.put<any>(`${this.basePath}/${endpoint}`, params, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	delete(endpoint: string, id?: string): Observable<any> {
		const path = id ? `${this.basePath}/${endpoint}/${id}` : `${this.basePath}/${endpoint}/`;
		return this.http.delete<any>(path, this.httpOptions).pipe(timeout(this.api_timeout));
	}

	formatParams(params: HttpParams): any {
		const httpParams: any = {};

		for (const property in params) {
			if (isObject(params[property])) {
				httpParams[property] = JSON.stringify(params[property]);
			} else {
				httpParams[property] = params[property];
			}
		}

		return httpParams;
	}

	connect(type: string): any {
		return {
			get: (params: HttpParams, success, error): Promise<any> => {
				const id = params['id'];
				delete params['id'];

				let path = '';

				if (id) {
					path = `${this.basePath}/${type}/${id}`;
				} else {
					path = `${this.basePath}/${type}`;
				}

				const options = Object.assign({}, this.httpOptions);
				options.observe = 'response';
				if (params) {
					options.params = this.formatParams(params);
				}

				return this.http
					.get(path, options)
					.toPromise()
					.then(
						(res: any) => {
							if (success) {
								success(res?.body, res?.headers);
							}
						},
						(e) => {
							if (error) {
								error(e);
							}
						}
					);
			},
			query: (params: HttpParams, success, error): Promise<any> => {
				const options = Object.assign({}, this.httpOptions);
				options.observe = 'response';
				if (params) {
					options.params = this.formatParams(params);
				}
				return this.http
					.get(`${this.basePath}/${type}`, options)
					.toPromise()
					.then(
						(res) => {
							if (success) {
								success(res['body'], res['headers']);
							}
						},
						(e) => {
							if (error) {
								error(e);
							}
						}
					);
			},
			post: (data, success, error): Promise<any> => {
				const params = data;
				return this.http
					.post(`${this.basePath}/${type}`, params, this.httpOptions)
					.toPromise()
					.then(
						(res) => {
							if (success) {
								success(res);
							}
						},
						(e) => {
							if (error) {
								error(e);
							}
						}
					);
			},
			put: (params, data): Observable<any> => {
				const id = params.id;
				delete params.id;
				const query = new URLSearchParams(params).toString();
				const path = id ? `${type}/${id}?${query}` : `${type}?${query}`;

				return this.http
					.put(`${this.basePath}/${path}`, data, this.httpOptions)
					.pipe(timeout(this.api_timeout));
			},
			delete: (params: any): Observable<ArrayBuffer> => {
				const id = params.id;
				const path = id ? `${type}/${id}` : `${type}`;

				return this.http
					.delete(`${this.basePath}/${path}`, this.httpOptions)
					.pipe(timeout(this.api_timeout));
			},
		};
	}

	// GET SETTING
	setting(key: string): Observable<any> {
		return this.http
			.get(`${this.basePath}/setting?key=${key}`, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	timezone(data): Observable<any> {
		return this.http
			.get(`${this.basePath}/utils/timezone/?${data}`, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	upload(path: string, file: File): Observable<any> {
		const options = {
			headers: {
				Authorization: 'Bearer ' + this.config.token,
				'Content-Type': file.type,
				Filename: file.name,
			},
		};

		return this.http
			.post(`${this.config.endpoints.cdn}/${path}/upload`, file, options)
			.pipe(timeout(this.api_timeout));
	}

	downloadLink(data: any): Observable<any> {
		return this.http
			.post(`${this.config.endpoints.cdn}/access/sastoken`, data, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	// DASHBOARD API
	overview(): Observable<any> {
		return this.http
			.get(`${this.basePath}/dashboard/overview`, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	quicksearch(search: string): Observable<any> {
		return this.http
			.get(
				`${this.basePath}/dashboard/quicksearch?search=${search.toLowerCase()}`,
				this.httpOptions
			)
			.pipe(timeout(this.api_timeout));
	}

	recents(): Observable<any> {
		return this.http
			.get(`${this.basePath}/dashboard/recents`, this.httpOptions)
			.pipe(timeout(this.api_timeout));
	}

	gatewayStatus(id: string): Observable<any> {
		return this.http.get(`${this.basePath}/gw/status?id=${id}`);
	}
}
