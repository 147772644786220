import { Pipe, PipeTransform } from '@angular/core';
import { addMinutes, format } from 'date-fns';

@Pipe({ name: 'customDateTime' })
export class CustomDateTimePipe implements PipeTransform {
	transform(value: string | Date): string {
		if (value && value !== '') {
			const d = new Date(value);
			return format(addMinutes(d, d.getTimezoneOffset()), 'MMM do, yyyy h:mm a');
		} else {
			return '---';
		}
	}
}
