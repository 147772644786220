<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="SEND_SMS"></h3>
	</div>
	<div class="modal-body item-settings">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="PHONE_NUMBER"></label>
				<div class="field-help" translate="PHONE_NUMBER_HELP"></div>
			</div>
			<div class="field-editor datepicker">
				<input
					[(ngModel)]="userinput.phoneNumber"
					#phoneNumber="ngModel"
					placeholder="{{ 'PHONE_NUMBER' | translate }}"
					name="phoneNumber"
					class="fm-input"
					type="text"
					required />

				<input-status class="input-status" [obj]="phoneNumber"></input-status>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button class="fm-button" translate="OK"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
