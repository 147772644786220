import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'emptyString',
})
export class EmptyStringPipe implements PipeTransform {
	transform(value: string): string {
		if (!value || value === undefined || value === '') {
			value = '---';
		}
		return value;
	}
}
