<div class="modal-header">
	<h3 class="modal-title" translate="{{ 'CHOOSE_' + settings.type.toUpperCase() }}"></h3>
</div>

<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<div class="fm-picker-list-header">
				<ng-container *ngIf="settings.type === ProfileType.COMPANY && global.user.isSuper">
					<company-quickswitch (selected)="quickSwitch($event)"></company-quickswitch>
				</ng-container>

				<div class="fm-quicksearch">
					<input
						#searchInput
						type="text"
						class="fm-input"
						placeholder="{{ 'SEARCH' | translate }}..."
						[ngModel]="search.qs"
						(ngModelChange)="updateSearch($event)"
						[ngModelOptions]="{ standalone: true }" />
					<a
						[routerLink]=""
						class="quicksearch-clear"
						(click)="clearQuicksearch()"
						*ngIf="search.qs"
						><i class="fa fa-times"></i
					></a>
				</div>
			</div>

			<section
				class="fm-picker-list"
				infinite-scroll
				[infiniteScrollDisabled]="search.isLoading"
				[scrollWindow]="false"
				[infiniteScrollThrottle]="0"
				(scrolled)="loadList()">
				<div
					class="fm-message notice"
					*ngIf="search.list.length === 0 && !search.isLoading">
					<span translate="NO_RECORDS"></span>
				</div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of search.list; trackBy: 'id' | trackByProperty"
						(click)="selectItem(item)"
						[ngClass]="{ selected: selected?.id === item.id }"
						stop-propagation>
						<div class="list-cell wrapped" *ngIf="showProfileImage()">
							<profile-image
								[objtype]="settings.type"
								[obj]="item"
								mode="size_50"></profile-image>
						</div>
						<div class="list-cell wrapped" *ngIf="settings.type === 'site'">
							<div class="profile-image size_50">
								<div class="image-wrap">
									<div class="text" [innerText]="item.state | stateAbbrev"></div>
								</div>
							</div>
						</div>

						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								<span [translate]="item.name"></span>
								<ul class="detail">
									<li
										*ngIf="item.description"
										[translate]="item.description | uppercase"></li>
								</ul>
							</div>

							<div class="detail-info" *ngIf="item.isActive">
								<span class="status-dot active"></span>
							</div>
							<div class="detail-info" *ngIf="!item.isActive">
								<span class="status-dot inactive"></span>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="search.isLoading" [error]="search.isFailed"></fm-loader>

			<list-status
				[loading]="search.isLoading"
				[count]="search.count"
				[total]="search.total"></list-status>
		</div>

		<div class="select-panel" *ngIf="settings.multiselect">
			<h4 class="panel-title" translate="SELECTED"></h4>
			<div class="modal-actions">
				<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL"></a>
			</div>

			<section class="fm-picker-list selected-list">
				<div
					class="fm-message notice"
					*ngIf="selected.length === 0"
					translate="NO_SELECTED"></div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of selected; trackBy: 'id' | trackByProperty"
						(click)="removeItem(item)">
						<div class="list-cell">
							<span [translate]="item.name"></span>
							<ul class="detail">
								<li [translate]="item.description | uppercase"></li>
							</ul>
						</div>
					</a>
				</div>
			</section>

			<div
				class="fm-list-count"
				translate="SELECTED_COUNT"
				[translateParams]="{ count: selected.length }"></div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
