<div class="section-header">
	<h3 translate="TAXONOMIES"></h3>
	<div class="section-help" translate="TAXONOMIES_HELP"></div>
</div>
<!--SECTION ACTIONS-->
<div class="section-actions" *ngIf="!readonly">
	<a [routerLink]="" (click)="loadModal()" translate="CHOOSE_TAXONOMIES"></a>
	<a
		[routerLink]=""
		*ngIf="value && value.length"
		(click)="removeAll()"
		translate="REMOVE_ALL"></a>
</div>
<!--SECTION CONTENT-->
<div class="section-content">
	<div *ngIf="value && value.length">
		<div class="quicksearch">
			<input
				type="text"
				class="fm-input"
				placeholder="{{ 'QUICKSEARCH' | translate }}"
				[(ngModel)]="search.qs"
				[ngModelOptions]="{ standalone: true }" />
		</div>

		<ul class="fm-list">
			<li
				class="fm-list-item"
				*ngFor="let item of filterList(value, search.qs); trackBy: 'id' | trackByProperty"
				[ngClass]="{ readonly: readonly }">
				<div class="list-cell detail-main">
					<div class="nesting" [innerText]="item.nesting | nesting"></div>
					<div class="title" [innerText]="item.name"></div>
				</div>
				<div class="item-controls" *ngIf="!readonly">
					<a [routerLink]="" class="remove-item" (click)="removeItem(item)">
						<i class="fa fa-times"></i>
					</a>
				</div>
			</li>
		</ul>

		<div class="list-info">
			<div
				translate="LOADED_COUNT"
				[translateParams]="{ count: search.filtered.length, total: value.length }"></div>
		</div>
	</div>

	<div class="fm-message notice" *ngIf="!value || !value.length">
		<i class="fm-icon fa fa-bookmark"></i>
		<span translate="NO_TAXONOMIES_SET"></span>
	</div>
</div>
