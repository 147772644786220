<div class="filter-wrap">
	<ul>
		<li class="form-row">
			<div class="field-editor">
				<select
					class="fm-select"
					name="key"
					[(ngModel)]="attribute.key"
					(change)="setAttribute()">
					<option [value]="undefined" translate="SELECT_ATTRIBUTE"></option>
					<option
						*ngFor="let op of attributeOptions"
						[value]="op.key"
						[translate]="op.key"></option>
				</select>
				<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span>
			</div>
		</li>
		<li class="form-row" *ngIf="attribute && attribute.key">
			<div class="field-editor" [ngSwitch]="attribute.dataType">
				<span
					class="input-type"
					[translate]="getType(attribute.dataType) | uppercase"></span>
				<input
					*ngSwitchCase="DataType.NUMBER"
					name="attributeValue"
					class="fm-input"
					type="number"
					[(ngModel)]="attribute.value"
					placeholder="{{ 'VALUE' | translate }}"
					(change)="runupdate()" />
				<input
					*ngSwitchDefault
					name="attributeValue"
					class="fm-input"
					type="text"
					[(ngModel)]="attribute.value"
					placeholder="{{ 'VALUE' | translate }}"
					(change)="runupdate()" />
				<span class="input-action" (click)="clearAttribute()" *ngIf="attribute.value">
					<i class="fa fa-times"></i>
				</span>
			</div>
		</li>
	</ul>
</div>
