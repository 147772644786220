<div class="filter-wrap">
	<div class="filter-loader" *ngIf="editor.isLoading">
		<span class="fa fa-circle-notch fa-spin"></span>
	</div>

	<ul class="filter-options" *ngIf="!editor.isLoading">
		<li class="filter-custom-input">
			<div>
				<input
					placeholder="{{ 'START' | translate }}"
					name="start"
					class="fm-input"
					type="number"
					[min]="0"
					[(ngModel)]="range.start"
					(ngModelChange)="updateRange()"
					autofocus />
			</div>

			<div>
				<input
					placeholder="{{ 'END' | translate }}"
					name="end"
					class="fm-input"
					type="number"
					[min]="range.start"
					[(ngModel)]="range.end"
					(ngModelChange)="updateRange()" />
			</div>

			<div
				class="filter-custom-clear"
				*ngIf="range.start || range.end"
				(click)="clearCustom()">
				<i class="fa fa-times"></i>
			</div>
		</li>

		<li
			class="fm-savedfilter"
			*ngFor="let filter of editor.typeahead.list; trackBy: 'id' | trackByProperty"
			(click)="setItem(filter)"
			[ngClass]="{ active: isActive(filter) }">
			<a [routerLink]="">
				<span class="filter-status"><i class="fa fa-check"></i></span>
				<span translate="{{ filter.name }}"></span>
			</a>
		</li>
	</ul>
</div>
