import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GlobalStore } from '@fmlib/interfaces';
import { ApiService, GlobalService } from '@fm/services';

@Component({
	selector: 'sms-dialog',
	templateUrl: './sms-dialog.component.html',
})
export class SmsDialogComponent implements OnInit {
	global: GlobalStore;
	userinput;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SmsDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		// GLOBAL GOODIES
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.userinput = {};
	}

	sendSms(): void {
		this.FM_Api.connect('sendsms').post({ number: this.userinput.phoneNumber }, () => {
			this.dialogRef.close();
		});
	}

	// MODAL ACTIONS
	ok(): void {
		this.sendSms();
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
