import { Directive, Input } from '@angular/core';
import {
	AbstractControl,
	AsyncValidator,
	NG_ASYNC_VALIDATORS,
	ValidationErrors,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { ToolsService } from '../services/tools.service';

@Directive({
	selector: '[fmUnique]',
	providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: FmUniqueDirective, multi: true }],
})
export class FmUniqueDirective implements AsyncValidator {
	@Input() fmUnique;

	private original;

	constructor(private FM_Tools: ToolsService) {}

	validate(control: AbstractControl): Observable<ValidationErrors | null> {
		if (!control.value) {
			return of({});
		}

		if (control.pristine) {
			this.original = control.value;
		}

		if (this.original === control.value) {
			return of({});
		}

		if (!control.valueChanges || control.pristine) {
			return of(null);
		} else {
			return of(control.value).pipe(
				delay(400),
				distinctUntilChanged(),
				switchMap((value) => this.FM_Tools.isUnique(this.fmUnique.field, value)),
				map((isAvail) => (isAvail ? null : { exists: true }), first())
			);
		}
	}
}
