import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable } from 'rxjs';
import { debounceTime, filter, finalize, startWith, switchMap, tap } from 'rxjs/operators';

import { GlobalStore, SearchParams } from '@fmlib/interfaces';
import { ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService, UtilsService } from '@fm/services';

import { cloneDeep, includes, isEqual, map, reject } from 'lodash-es';

@Component({
	selector: 'filter-search',
	templateUrl: './filter-search.component.html',
	styleUrls: ['./filter-search.component.less'],
})
export class FilterSearchComponent implements OnInit, OnChanges {
	@Input() api: string;
	@Input() searchtype: ProfileType;
	@Input() filter;
	@Input() placeholder: string;
	@Input() prop: string;
	@Input() limit: number;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	private SEARCH_LIMIT = 5;

	global: GlobalStore;

	search: {
		isLoading: boolean;
		list: any[];
		total: number;
	};
	selected: any[];

	currentFilter;
	isLoading: boolean;
	isSearching: boolean;

	filteredOptions: Observable<any[]>;
	myControl = new FormControl();

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		private FM_Api: ApiService,
		private FM_Global: GlobalService,
		private FM_Utils: UtilsService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.search = {
			isLoading: false,
			list: [],
			total: null,
		};

		this.selected = [];

		if (!this.api) {
			this.api = this.FM_Utils.getNamePluralized(this.searchtype);
		}

		if (!this.prop) {
			this.prop = 'id';
		}

		if (this.limit) {
			this.SEARCH_LIMIT = this.limit;
		}

		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			debounceTime(400),
			filter((query: string) => !query || (query && query.length > 2)),
			tap(() => (this.isSearching = true)),
			switchMap((value) =>
				this.filterList(value).pipe(finalize(() => (this.isSearching = false)))
			)
		);

		if (this.filter) {
			this.initFilter().then(() => {
				this.open();
			});
		}
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = cloneDeep(this.filter);
		}
	}

	buildFilter(): void {
		if (this.search.total > 5) {
			if (this.searchInput) {
				this.searchInput.nativeElement.focus();
			}
		} else {
			this.selected = this.search.list?.filter((item) => includes(this.filter, item.id));
		}
	}

	open(): void {
		if (this.searchtype && this.global.overview[this.searchtype]) {
			this.search.total = this.global.overview[this.searchtype];
		}

		if (!this.search.total || this.search.total <= this.SEARCH_LIMIT) {
			if (!this.search.total) {
				this.getList().then(() => {
					this.buildFilter();
				});
			}
		} else {
			this.buildFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	filterList(searchTerm: string): Observable<any[]> {
		const params: SearchParams = {
			select: 'name ' + this.prop,
			// sort: 'name',
			per_page: this.SEARCH_LIMIT,
			conditions: {},
		};

		if (this.api === 'sites') {
			params.filterList = true;
		}

		if (searchTerm && searchTerm !== '') {
			params.conditions.searchText = { $regex: searchTerm.toLowerCase() };
		}

		if (this.selected.length) {
			params.conditions[this.prop] = { $nin: map(this.selected, this.prop) };
		}

		// LAME!
		params.conditions = JSON.stringify(params.conditions);

		return this.FM_Api.get(this.api, params);
	}

	runupdate(): void {
		// CLEAR THE PROP
		if (this.selected.length === 0) {
			delete this.filter;
		} else {
			this.filter = map(this.selected, this.prop);
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);
			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	// SITE FILTER
	clearItem(item): void {
		const obj = {};
		obj[this.prop] = item[this.prop];
		this.selected = reject(this.selected, obj);
		this.runupdate();
	}

	filterClick(filter): void {
		if (this.selected[0] === 'all') {
			this.selected = [];
		}

		const check = this.selected.some(({ id }) => id === filter.id);

		if (!check) {
			this.selected.push(filter);
		} else {
			this.selected = reject(this.selected, { id: filter.id });
		}

		this.runupdate();
	}

	// CHECK
	isActive(filter): boolean {
		const check = this.selected.some(({ id }) => id === filter.id);
		return check;
	}

	setItem(event: MatAutocompleteSelectedEvent): void {
		event.option.deselect();
		this.selected.push(event.option.value);

		this.searchInput.nativeElement.blur();
		this.myControl.reset();

		this.runupdate();
	}

	getList(searchTerm?: string): Promise<any> {
		this.search.isLoading = true;

		const params: SearchParams = {
			select: 'name ' + this.prop,
			// sort: 'name',
			per_page: 10,
			conditions: {},
		};

		if (searchTerm && searchTerm !== '') {
			params.conditions.searchText = { $regex: searchTerm.toLowerCase() };
		}

		if (this.api === 'sites') {
			params.filterList = true;
		}

		return this.FM_Api.connect(this.api).query(params, (data, headers) => {
			this.search.list = data;
			this.search.isLoading = false;

			if (!this.search.total) {
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);
			}
		});
	}

	clearFilter(): void {
		this.selected = [];
	}

	initFilter(): Promise<null> {
		return new Promise((resolve) => {
			const list = cloneDeep(this.filter);

			if (list) {
				this.isLoading = true;
				const params = {
					page: 1,
					per_page: list.length,
					select: 'name ' + this.prop,
					conditions: {},
				};

				params.conditions[this.prop] = { $in: list };

				return this.FM_Api.connect(this.api).query(params, (res) => {
					this.selected = res.map((item) => item);
					this.isLoading = false;
					resolve(null);
				});
			} else {
				resolve(null);
			}
		});
	}
}
