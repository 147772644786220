import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from './api.service';
import { GlobalService } from './global.service';

import { GlobalStore, UserFilter } from '@fmlib/interfaces';

import { Observable } from 'rxjs';
import { format, startOfDay, subMonths } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class FilterService {
	private global: GlobalStore;

	constructor(
		private translate: TranslateService,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	getCurrent(type: string): any {
		const f = JSON.parse(localStorage.getItem('fm_filter'));

		if (!f || !f[type]) {
			return null;
		} else {
			return f[type];
		}
	}

	setCurrent(filter: string, type: string): void {
		let f = JSON.parse(localStorage.getItem('fm_filter'));

		if (!f) {
			f = {};
		}

		f[type] = filter;

		localStorage.setItem('fm_filter', JSON.stringify(f));
	}

	load(type: string): any {
		const params = {
			page: 1,
			sort: 'name',
			per_page: 1000,
			conditions: {
				filterType: type,
			},
		};

		return this.FM_Api.connect('userfilters').query(params);
	}

	save(f: UserFilter): Promise<any> {
		if (f.id) {
			const id = f.id;
			delete f.id;
			return this.FM_Api.connect('userfilters').put({ id: id }, f).toPromise();
		} else {
			return this.FM_Api.connect('userfilters').post(f);
		}
	}

	delete(f: UserFilter): Observable<ArrayBuffer> {
		return this.FM_Api.connect('userfilters').delete({ id: f.id });
	}

	getDateUtc(date): string {
		const timestring = 'T00:00:00Z';
		return format(date, 'yyyy-MM-dd') + timestring;
	}

	getDateFilters(): UserFilter[] {
		const begin_today = startOfDay(new Date());
		const begin_one_month = subMonths(startOfDay(new Date()), 1);
		const begin_six_months = subMonths(startOfDay(new Date()), 6);

		const f: UserFilter[] = [
			/*{
				id: 'date0',
				name: this.translate.instant('ALL'),
			},
			*/
			{
				id: 'date1',
				name: this.translate.instant('TODAY'),
				filter: { $gte: this.getDateUtc(begin_today) },
			},
			{
				id: 'date2',
				name: this.translate.instant('LAST_30DAYS'),
				filter: { $gte: this.getDateUtc(begin_one_month) },
			},
			{
				id: 'date3',
				name: this.translate.instant('LAST_6_MONTHS'),
				filter: { $gte: this.getDateUtc(begin_six_months) },
			},
		];

		return f;
	}

	updateUserFilters(): any {
		this.FM_Api.connect('userfilters').query(
			{
				page: 1,
				sort: 'name',
				per_page: 1000,
			},
			(data) => {
				this.global.userfilters = data;
				return this.global.userfilters;
			}
		);
	}
}
