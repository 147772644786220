import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { GlobalService } from '../services/global.service';

@Directive({
	selector: '[fmSetting]',
})
export class FmSettingDirective {
	@Input() set fmSetting(val: string) {
		this.setting = val;
		this.updateView();
	}

	private setting: string;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private FM_Global: GlobalService
	) {}

	updateView(): void {
		if (this.FM_Global.getSetting(this.setting)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
