<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="MANAGE_ATTRIBUTES"></h3>
	</div>

	<div class="modal-body">
		<div class="section-actions">
			<a
				[routerLink]=""
				(click)="addAttribute()"
				translate="ADD_ATTRIBUTE"
				stop-propagation></a>
			<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL" stop-propagation></a>
		</div>

		<div class="fm-message notice" *ngIf="!attributes?.length">
			<i class="fm-icon fa fa-list"></i>
			<span translate="NO_ATTRIBUTES_SET"></span>
		</div>

		<ul #attributeEditor class="attribute-editor" *ngIf="attributes?.length > 0">
			<li
				*ngFor="let attr of attributes; index as i; trackBy: 'index' | trackByProperty"
				class="fm-list-item"
				[ngClass]="{ error: attr.isError }">
				<div class="list-cell">
					<div class="form-row">
						<label class="label" translate="KEY"></label>
						<div class="field-editor">
							<input
								class="fm-input"
								[name]="'key_' + i"
								type="text"
								[(ngModel)]="attr.key"
								placeholder="key"
								(change)="checkKeys()"
								required />
						</div>
					</div>
				</div>
				<div class="list-cell">
					<div class="form-row">
						<label class="label" translate="VALUE"></label>
						<div class="field-editor" *ngIf="attr.dataType === DataType.CURRENCY">
							<prefix-input
								[name]="'value_' + i"
								placeholder="value"
								[required]="true"
								[(ngModel)]="attr.value"></prefix-input>
						</div>
						<div class="field-editor" *ngIf="attr.dataType !== DataType.CURRENCY">
							<input
								class="fm-input"
								*ngIf="attr.dataType === DataType.NUMBER"
								type="number"
								[name]="'value_' + i"
								[(ngModel)]="attr.value"
								[min]="0"
								placeholder="value"
								required />
							<input
								class="fm-input"
								*ngIf="attr.dataType === DataType.STRING"
								type="text"
								[name]="'value_' + i"
								[(ngModel)]="attr.value"
								placeholder="value"
								required />
							<input
								class="fm-input"
								*ngIf="attr.dataType === DataType.DATETIME"
								type="date"
								[name]="'value_' + i"
								[(ngModel)]="attr.value"
								placeholder="value"
								required />
							<input
								class="fm-input"
								*ngIf="attr.dataType === DataType.BOOLEAN"
								type="text"
								[name]="'value_' + i"
								[(ngModel)]="attr.value"
								placeholder="value"
								required />
						</div>
					</div>
				</div>
				<div class="list-cell">
					<div class="form-row">
						<label class="label" translate="TYPE"></label>
						<div class="field-editor">
							<select
								class="fm-select"
								[name]="'datatype' + i"
								[(ngModel)]="attr.dataType"
								(change)="changeType(attr)"
								required>
								<option
									[value]="op"
									*ngFor="let op of dataTypeOptions"
									[translate]="op | uppercase"></option>
							</select>
							<span class="input-icons"
								><i class="fa-light fa-circle-chevron-down"></i
							></span>
						</div>
					</div>
				</div>
				<div class="item-controls">
					<a [routerLink]="" *ngIf="attr.isLocked"><i class="fa fa-lock"></i></a>
					<a
						[routerLink]=""
						*ngIf="!attr.isLocked"
						class="remove-item"
						(click)="removeAttribute(i)"
						><i class="fa fa-times"></i
					></a>
				</div>
			</li>
		</ul>
	</div>

	<div class="modal-footer">
		<div class="fm-message error" *ngIf="hasDuplicates">
			Attribute keys must be unique, please verify to save.
		</div>
		<button class="fm-button" translate="OK" [disabled]="hasDuplicates"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
