import { Pipe, PipeTransform } from '@angular/core';

import { DataService } from '../services/data.service';

@Pipe({ name: 'beaconRegion' })
export class BeaconRegionPipe implements PipeTransform {
	constructor(private FM_Data: DataService) {}

	transform(value: string): string {
		let r = '---';
		if (value) {
			const regions = this.FM_Data.beaconRegions();
			const reg = regions.find(({ id }) => id === value);

			if (reg) {
				r = reg.name;
			}
		}

		return r;
	}
}
