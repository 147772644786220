<div class="modal-header">
	<h3 class="modal-title" translate="NOTIFICATIONS"></h3>
</div>

<div class="modal-body fm-audit">
	<section class="filter-panel">
		<div class="fm-sidebar fm-filterlist">
			<div class="filter-section">
				<div class="filter-group">
					<fm-filter class="fm-filter" [ngClass]="{ active: true }">
						<h3 translate="FILTER_BY_STATUS"></h3>
						<div class="filter-wrap">
							<ul>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: !filters.isArchived }">
									<a
										[routerLink]=""
										(click)="filterStatus('current')"
										translate="CURRENT"></a>
								</li>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: filters.isArchived }">
									<a
										[routerLink]=""
										(click)="filterStatus('archived')"
										translate="ARCHIVED"></a>
								</li>
							</ul>
						</div>
					</fm-filter>

					<fm-filter class="fm-filter">
						<h3 translate="FILTER_BY_LEVEL"></h3>
						<div class="filter-wrap">
							<ul>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: !filters.severity }">
									<a
										[routerLink]=""
										(click)="filterSeverity()"
										translate="ALL"></a>
								</li>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: filters.severity === 'info' }">
									<a
										[routerLink]=""
										(click)="filterSeverity('info')"
										translate="INFO"></a>
								</li>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: filters.severity === 'warning' }">
									<a
										[routerLink]=""
										(click)="filterSeverity('warning')"
										translate="WARNING"></a>
								</li>
								<li
									class="fm-savedfilter"
									[ngClass]="{ active: filters.severity === 'error' }">
									<a
										[routerLink]=""
										(click)="filterSeverity('error')"
										translate="ERROR"></a>
								</li>
							</ul>
						</div>
					</fm-filter>

					<fm-filter class="fm-filter">
						<h3 translate="FILTER_BY_DATE"></h3>
						<div class="filter-wrap">
							<ul>
								<li
									class="fm-savedfilter"
									*ngFor="
										let filter of dateFilters;
										trackBy: 'id' | trackByProperty
									"
									(click)="loadFilter(filter)"
									[ngClass]="{ active: isActive(filter) }">
									<a [routerLink]="">
										<span class="filter-status"
											><i class="fa fa-check"></i
										></span>
										{{ filter.name }}
									</a>
								</li>
							</ul>
						</div>
					</fm-filter>
				</div>
			</div>
		</div>
	</section>

	<section class="list-panel">
		<div class="modal-actions" *ngIf="search.list.length">
			<a [routerLink]="" (click)="archiveAllNotifications()" translate="ARCHIVE_ALL"></a>
			<a [routerLink]="" (click)="deleteAllNotifications()" translate="CLEAR_ALL"></a>
		</div>

		<section
			class="fm-list-wrap"
			infinite-scroll
			[infiniteScrollDisabled]="search.isLoading"
			[scrollWindow]="false"
			[infiniteScrollThrottle]="0"
			(scrolled)="loadList()">
			<div
				class="fm-message notice"
				*ngIf="search.total <= 0 && !search.isLoading && !search.isFailed">
				<span translate="NO_NOTIFICATIONS"></span>
			</div>
			<div class="fm-list">
				<a
					[routerLink]=""
					class="fm-list-item"
					*ngFor="let notif of search.list"
					(click)="selectItem(notif)"
					[ngClass]="{ selected: notif.id === selected.id }">
					<div class="list-cell fm-list-detail">
						<div class="detail-main">
							<span> {{ notif.content | translate | limitString : 60 }}</span>
							<ul class="detail">
								<li>
									<label translate="DATE"></label> :
									{{ notif.created | customDate }}
								</li>
								<li>
									<label translate="TYPE"></label> :
									{{ notif.notifType | translate }}
								</li>
							</ul>
						</div>
						<span
							class="list-action"
							[ngClass]="notif.severity"
							[innerText]="notif.severity"></span>
					</div>
				</a>
			</div>
		</section>

		<div class="panel-loader">
			<fm-loader [loading]="search.isLoading" [error]="search.isFailed"></fm-loader>
		</div>

		<list-status
			[loading]="search.isLoading"
			[count]="search.count"
			[total]="search.total"></list-status>
	</section>

	<section class="detail-panel">
		<div *ngIf="!selected">
			<div class="fm-message notice">
				<span translate="NO_DETAIL"></span>
			</div>
		</div>

		<section class="notification-detail" *ngIf="selected">
			<h4 class="panel-title">{{ selected.created | customDate }}</h4>
			<div class="section-actions">
				<a
					[routerLink]=""
					*ngIf="!selected.isArchived"
					(click)="setArchive(selected, true)"
					translate="ARCHIVE"></a>
				<a
					[routerLink]=""
					*ngIf="selected.isArchived"
					(click)="setArchive(selected, false)"
					translate="UNARCHIVE"></a>
				<a
					[routerLink]=""
					class="delete"
					(click)="deleteNotification(selected)"
					translate="DELETE"></a>
			</div>
			<ul class="notification-content">
				<li>
					<label translate="TYPE"></label>
					<div>
						<span [translate]="selected.notifType"></span> (<span
							translate="{{ selected.severity }}"></span
						>)
					</div>
				</li>
				<li>
					<label translate="MESSAGE"></label>
					<div [translate]="selected.content"></div>
				</li>
				<li *ngIf="selected.platformTask">
					<a
						href="#"
						class="fm-button"
						(click)="goToTask(selected.platformTask)"
						translate="VIEW_TASK"
						stop-propagation></a>
				</li>
			</ul>
		</section>
	</section>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="CLOSE"></button>
</div>
