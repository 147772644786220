<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_ZONE"></h3>
</div>

<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<div class="fm-quicksearch">
				<input
					#searchInput
					type="text"
					class="fm-input"
					[(ngModel)]="search.qs"
					placeholder="{{ 'SEARCH' | translate }}..."
					(ngModelChange)="initSearch()"
					[ngModelOptions]="{ standalone: true }" />
				<a
					[routerLink]=""
					class="quicksearch-clear"
					(click)="clearQuicksearch()"
					*ngIf="search.qs"
					><i class="fa fa-times"></i
				></a>
			</div>

			<section
				class="fm-picker-list"
				infinite-scroll
				[infiniteScrollDisabled]="search.isLoading"
				[scrollWindow]="false"
				[infiniteScrollThrottle]="0"
				(scrolled)="loadList()">
				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of zones.list; trackBy: 'id' | trackByProperty"
						(click)="selectItem(item)"
						[ngClass]="{ selected: selected?.id === item.id }">
						<div class="list-cell fm-list-detail">
							<div class="list-cell">{{ item.zone.name }}</div>
							<div class="fm-list-detail">
								<div class="detail-info" *ngIf="item.zone.isActive">
									<span class="status-dot active"></span>
								</div>
								<div class="detail-info" *ngIf="!item.zone.isActive">
									<span class="status-dot inactive"></span>
								</div>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="zones.isLoading" [error]="zones.isFailed"></fm-loader>

			<list-status
				[loading]="zones.isLoading"
				[count]="zones.count"
				[total]="zones.total"></list-status>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
