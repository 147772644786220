import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm/confirm-dialog.component';
import { FilterDialogComponent } from '../dialogs/filter-dialog.component';

import { DataType, ModalSizes, ProfileType } from '@fmlib/enums';
import { GlobalStore, Partner, UserFilter } from '@fmlib/interfaces';
import { DataService, FilterService, GlobalService, MessageService } from '@fm/services';

import { ListData } from 'projects/manage/src/app/services/data.service';

import { isEmpty, sortBy } from 'lodash-es';

@Component({
	selector: 'filterlist',
	templateUrl: './filterlist.component.html',
})
export class FilterListComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() filters;
	@Input() settings;
	@Input() objtype: ProfileType;

	@Output() filtersChange = new EventEmitter();
	@Output() update = new EventEmitter();

	global: GlobalStore;
	currentFilter: UserFilter;
	ProfileType = ProfileType;

	search_timeout;
	savedFilters;
	standardFilters;
	view;

	beaconLevels: number[];
	payloadActions: ListData[];
	payloadTypes: ListData[];
	partnerList: Partner[];

	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private FM_Data: DataService,
		private FM_Filters: FilterService,
		private FM_Global: GlobalService,
		private FM_Message: MessageService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.beaconLevels = this.FM_Data.beaconLevels();
		this.payloadActions = this.FM_Data.getPayloadActions();
		this.payloadTypes = this.FM_Data.getPayloadTypes();

		this.view = {
			showSavedFilters: false,
		};

		// DO WE SHOW PARTNERS
		this.settings.showPartners =
			this.settings && this.settings.showPartners && this.global.partners.from.length > 0;

		this.partnerList = [];

		if (this.settings.showPartners) {
			this.global.partners.from.forEach((item) => {
				if (this.isType(ProfileType.APP) && item.shareApps) {
					if (item.company) {
						this.partnerList.push(item.company);
					}
				}

				if (this.isType(ProfileType.ASSET) && item.shareAssets) {
					if (item.company) {
						this.partnerList.push(item.company);
					}
				}

				if (this.isType(ProfileType.SITE) && item.shareSites) {
					if (item.company) {
						this.partnerList.push(item.company);
					}
				}
			});
		}
	}

	ngAfterViewInit(): void {
		this.runupdate();
	}

	runupdate(): void {
		this.filtersChange.emit(this.filters);
		this.update.emit();
	}

	getSetting(setting: string): any {
		return this.FM_Global.getSetting(setting);
	}

	setupFilters(data): void {
		if (data) {
			this.standardFilters = data.filter(({ isGlobal }) => isGlobal);

			// ALL NON GLOBALS
			this.savedFilters = data.filter((item) => {
				return !item.isGlobal || item.isGlobal === false;
			});

			// PARSE FILTERS
			this.savedFilters.forEach((item) => {
				if (typeof item.filter === DataType.STRING) {
					item.filter = JSON.parse(item.filter);
				}
			});
		} else {
			this.standardFilters = [];
		}

		// ADD STANDARD
		this.standardFilters.push({
			id: '0',
			filter: {
				isActive: true,
			},
			isGlobal: true,
			name: this.translate.instant('ACTIVE_ONLY'),
		});

		this.standardFilters.push({
			id: '1',
			filter: {
				isActive: false,
			},
			isGlobal: true,
			name: this.translate.instant('INACTIVE_ONLY'),
		});

		/*
			if(this.global.user.isSuper){
				this.standardFilters.push({
					id: '2',
					filter: {
						jedi: 'true'
					},
					isGlobal: true,
					name: 'Search All Companies'
				});
			}
			*/

		this.standardFilters = sortBy(this.standardFilters, 'name');

		this.view.loadingFilters = false;
	}

	// UPDATING LIST
	updateFilters(): void {
		this.view.loadingFilters = true;

		// LOAD SAVED FILTERS
		this.FM_Filters.load(this.settings.objtype).then(
			(data) => {
				this.setupFilters(data);
			},
			() => {
				this.setupFilters(null);
			}
		);
	}

	isActive(f: UserFilter): boolean {
		return this.currentFilter && this.currentFilter.id === f.id;
	}

	isFiltered(): boolean {
		let test;
		if (this.filters) {
			test = JSON.parse(JSON.stringify(this.filters));
		}
		return !isEmpty(test);
	}

	hasDateFilter(f: UserFilter): boolean {
		return f.updated !== undefined || f.created !== undefined || f.lastSeen !== undefined;
	}

	showSavedFilters(): void {
		this.view.showSavedFilters = true;
		this.updateFilters();
	}

	loadFilter(f: UserFilter): void {
		this.view.showSavedFilters = false;
		this.currentFilter = f;
		this.filters = this.FM_Data.clone(f.filter);

		this.runupdate();
	}

	cancelFilter(): void {
		this.view.showSavedFilters = false;
	}

	clearFilter(): void {
		if (!isEmpty(this.filters)) {
			this.currentFilter = null;
			this.filters = {};

			this.runupdate();
		}
	}

	// SAVE A FILTER
	saveFilter(): void {
		const dialogRef = this.dialog.open(FilterDialogComponent, {
			panelClass: ModalSizes.Confirm,
			data: {
				filter: this.currentFilter,
			},
		});

		dialogRef.afterClosed().subscribe((filter) => {
			if (filter) {
				const f: UserFilter = {
					name: filter.name,
					filterType: this.objtype,
					isGlobal: filter.isGlobal,
					filter: JSON.stringify(this.filters),
				};

				// IF ID WE UPDATE
				if (filter.id) {
					f.id = filter.id;
				}

				this.FM_Filters.save(f).then((response) => {
					this.currentFilter = response;
					this.view.showSavedFilters = false;
					this.FM_Message.addSuccessMessage('FILTER_SAVED');

					this.updateFilters();
				});
			}
		});
	}

	// REMOVE A FILTER
	removeFilter(f: UserFilter): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			panelClass: ModalSizes.Confirm,
			data: {
				message: 'WARNING_REMOVE',
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				f.isRemoving = true;

				this.FM_Filters.delete(f).subscribe(() => {
					this.FM_Message.addSuccessMessage('FILTER_DELETED');
					this.updateFilters();
				});
			}
		});
	}

	isType(type: ProfileType): boolean {
		return this.objtype === type;
	}

	// CLEANUP
	ngOnDestroy(): void {
		clearTimeout(this.search_timeout);
	}
}
