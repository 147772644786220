import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ProfileType } from '@fmlib/enums';
import { AdminUser, GlobalStore, Profile, SiteType } from '@fmlib/interfaces';
import { ApiService, GlobalService, MessageService } from '@fm/services';

@Injectable()
export class ProfileService {
	global: GlobalStore;

	private _state: Subject<any> = new Subject<any>();
	private profile: Profile;

	constructor(
		private router: Router,
		private FM_Api: ApiService,
		private FM_Global: GlobalService,
		private FM_Message: MessageService
	) {
		this.global = this.FM_Global.get();
	}

	init(type: ProfileType, settings): Profile {
		// setup profile
		this.profile = {
			type: type,
			isLoading: false,
			isFailed: false,
			warnings: {},
			//
			showCreate: true,
			showDuplicate: true,
		};

		settings.populate = this.populate(type);

		switch (type) {
			case ProfileType.ADMINUSER:
				this.profile.showDuplicate = false;
				this.profile.setPassword = false;
				break;
			case ProfileType.ASSET:
				this.profile.showDuplicate = this.global.user.isSuper;
				break;
			case ProfileType.PAYLOAD:
			case ProfileType.PROGRAM:
				this.profile.showDuplicate = false;
				break;
			case ProfileType.BEACON:
			case ProfileType.NFC:
				this.profile.showActivity = true;
				this.profile.showCreate = false;
				this.profile.showDuplicate = false;
				this.profile.showPulse = true;
				break;
			case ProfileType.COMPANY:
				this.profile.showDuplicate = false;
				this.profile.showSwitch = true;
				break;
			case ProfileType.DEVICE:
				this.profile.showCreate = false;
				this.profile.showDuplicate = false;
				break;
			case ProfileType.GATEWAY:
				this.profile.showActivity = true;
				this.profile.showDuplicate = false;
				break;
			case ProfileType.PARTNER:
				this.profile.showCreate = false;
				this.profile.showDuplicate = false;
				break;
		}

		return this.profile;
	}

	populate(type: ProfileType): string {
		const p = {
			adminuser: 'company groups defaultCompany',
			asset: 'brands district division region suppliers',
			beacon: 'asset division site taxonomies zone',
			company: 'children',
			device: 'tasks',
			experience: 'apps payload targetProfiles sites',
			gateway: 'collectors.gateway parent.gateway site zone',
			journey: 'payload sites',
			nfc: 'asset division site taxonomies zone',
			partner: 'apps assets partner sites zones',
			// payload: '',
			program: 'assets',
			push: 'apps targetProfiles',
			site: 'assets district divisions region siteType taxonomies',
			task: '',
			zone: 'company taxonomies',
		};

		return p[type];
	}

	getDefault(type: ProfileType): any {
		const o: any = {};

		o.adminuser = {
			isActive: true,
			isSuper: false,
			provider: 'fm',
			groups: [],
		};

		o.app = {
			isActive: true,
			scanModeAndroid: 'SCAN_MODE_BALANCED',
			tags: [],
		};

		o.asset = {
			isActive: true,
			value: 0.0,
			attributes: [],
			tags: [],
		};

		o.beacon = {
			isActive: true,
			txPower: 4,
			tags: [],
			taxonomies: [],
		};

		o.company = {
			isActive: true,
			isDemo: false,
			branding: 'footmarks',
			contacts: [],
			partners: [],
			allowedIps: [],
		};

		o.experience = {
			isActive: false,
			allowRepeats: false,
			gender: 'both',
			maxAllowedInterval: 'none',
			apps: [],
			beaconEvents: ['enter'],
			tags: [],
			targetProfiles: [],
			schedules: [],
			sites: [],
			zones: [],
		};

		o.gateway = {
			isActive: true,
		};

		o.group = {
			isActive: true,
			permissions: [],
		};

		o.payload = {
			isActive: true,
			showNotif: false,
			content: {},
			attributes: [],
			tags: [],
		};

		o.program = {
			isActive: true,
			properties: [],
			assets: [],
			tags: [],
		};

		o.nfc = {
			isActive: true,
			txPower: 4,
			tags: [],
			taxonomies: [],
		};

		o.journey = {
			isActive: true,
			maxAllowedInterval: 'none',
			schedules: [],
			sites: [],
			steps: [],
			tags: [],
		};

		o.site = {
			isActive: true,
			isManualAddress: false,
			lat: 0,
			lng: 0,
			attributes: [],
			tags: [],
			taxonomies: [],
			zones: [],
			visitDuration: 60,
			walkByDuration: 1,
			bounceDuration: 5,
			captureDuration: 5,
			captureMinZoneCount: 1,
			autoProvisionRadius: this.FM_Global.getSetting('auto-provision-distance'),
			autoProvisionHorizontalAccuracy: this.FM_Global.getSetting(
				'auto-provision-horizontal-accuracy'
			),
		};

		o.task = {
			isActive: true,
		};

		o.zone = {
			isActive: true,
			advertisementInterval: 300,
			broadcastTypes: [
				{ mode: 'eddystone', active: false },
				{ mode: 'ibeacon', active: true },
				{ mode: 'physicalWeb', active: true },
				{ mode: 'open', active: false },
				{ mode: 'private', active: true },
				{ mode: 'secure', active: false },
				{ mode: 'eddystoneService', active: false },
			],
			isSiteWide: false,
			sitewideType: 'any',
			txPower: 2,
			startTime: 0,
			endTime: 0,
			regionId: 'AF21CFAA-7B9D-49DE-8CB2-0799AE810877',
			attributes: [],
			taxonomies: [],
			tags: [],
		};

		// if no specific type use the default
		const defaultObj =
			o[type] !== undefined
				? o[type]
				: {
						isActive: true,
						tags: [],
				  };

		return defaultObj;
	}

	getSetting(setting: string): any {
		return this.FM_Global.getSetting(setting);
	}

	loadBannerOptions(): Observable<any> {
		let params: HttpParams = new HttpParams();
		params = params.append('sort', 'name');
		params = params.append('per_page', 100);
		return this.FM_Api.get('bannerrestrictions', params);
	}

	loadSiteTypes(): Observable<SiteType[]> {
		let params: HttpParams = new HttpParams();
		params = params.append('sort', 'name');
		params = params.append('per_page', 100);
		return this.FM_Api.get('sitetypes', params);
	}

	loadValidations(objType: string): Observable<any> {
		let params: HttpParams = new HttpParams();
		params = params.append('sort', 'name');
		params = params.append('per_page', 100);
		params = params.append('conditions', JSON.stringify({ objType: objType }));

		return this.FM_Api.get('validationrules', params);
	}

	resetLoginAttempts(objId: string): Observable<AdminUser> {
		return this.FM_Api.put(`adminusers/${objId}`, {
			populate: 'company groups',
			isLockedOut: false,
			failedLoginAttempts: null,
		});
	}

	setState(state: string): void {
		this._state.next(state);
	}

	stateChange(): Observable<any> {
		return this._state.asObservable();
	}

	goToItem(...params: string[]): void {
		this.router.navigate([params[0]], { skipLocationChange: true }).then(() => {
			this.router.navigate(params);
		});
	}

	checkBatch(batchId: string): Promise<any> {
		return new Promise((resolve) => {
			this.FM_Api.connect('batchprogress').get({ id: batchId }, (res) => {
				if (res && res.percentComplete === 100) {
					resolve(res);
					return;
				} else {
					setTimeout(() => {
						this.checkBatch(batchId).then(resolve);
					}, 400);
				}
			});
		});
	}

	getNamePluralized(name: ProfileType): string {
		if (name === ProfileType.COMPANY) {
			return 'companies';
		} else if (name === ProfileType.PUSH) {
			return 'pushes';
		} else {
			return name + 's';
		}
	}

	addNew(response, profile, message): Promise<any> {
		return new Promise((resolve) => {
			this.FM_Global.updateCount(profile.type, 1);

			if (!profile.isModal) {
				this.router
					.navigate([this.getNamePluralized(profile.type)], { skipLocationChange: true })
					.then(() =>
						this.router.navigate([this.getNamePluralized(profile.type), response.id])
					);
			}

			resolve(null);
		});
	}

	update(obj, temp, profile, message): Observable<any> {
		const query: any = {
			id: obj.id,
		};

		if (this.populate(profile.type) !== undefined) {
			query.populate = this.populate(profile.type);
		}

		return this.FM_Api.connect(this.getNamePluralized(profile.type))
			.put(query, temp)
			.pipe(
				catchError(() => {
					this.FM_Message.addErrorMessage('REQUEST_ERROR');
					profile.isBusy = false;
					return EMPTY;
				}),
				tap(() => {
					this.FM_Message.addSuccessMessage(message);
					profile.isBusy = false;
				})
			);
	}

	reload(): void {
		const returnUrl = window.location.pathname.substring(1).split('/');

		this.router.navigate([returnUrl[0]], { skipLocationChange: true }).then(() => {
			this.router.navigate(returnUrl);
		});
	}

	get(obj, profile): void {
		this.FM_Api.get(this.getNamePluralized(profile.type) + '/' + obj.id)
			.pipe(
				tap((response) => {
					obj = response;
				})
			)
			.subscribe();
	}

	updateReadonly(obj, objtype): void {
		// SET READONLY
		if (obj.isArchived || obj.isShared || !this.FM_Global.hasPermission(objtype, 'update')) {
			obj.isReadonly = true;
		} else {
			obj.isReadonly = false;
		}
	}

	saveError(profile): void {
		this.FM_Message.addErrorMessage('REQUEST_ERROR');
		profile.isBusy = false;
	}
}
