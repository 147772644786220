<!-- APP/COMPANY -->
<div
	class="profile-image"
	[ngClass]="mode"
	*ngIf="isType(ProfileType.APP) || isType(ProfileType.COMPANY)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="obj.image && obj.image.pathFragment"
			[src]="getCdnImage(obj.image)" />
		<fm-icon *ngIf="!obj.image || !obj.image.pathFragment" [type]="objtype"></fm-icon>
	</div>
	<ng-container *ngIf="showEdit">
		<upload-cdn-image class="image-upload" [obj]="obj" [objtype]="objtype"></upload-cdn-image>
	</ng-container>
</div>

<!-- EXPERIENCE/JOURNEY/PUSH -->
<div
	class="profile-image delivered"
	[ngClass]="mode"
	*ngIf="
		isType(ProfileType.EXPERIENCE) || isType(ProfileType.JOURNEY) || isType(ProfileType.PUSH)
	">
	<div class="image-wrap">
		<h4 translate="DELIVERED"></h4>
		<div class="detail-info">
			<fm-delivered [obj]="obj"></fm-delivered>
		</div>
	</div>
</div>

<!-- ADMINUSER -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.ADMINUSER)">
	<div class="image-wrap">
		<img alt="profile image" width="100%" height="100%" *ngIf="obj.image" [src]="obj.image" />
		<div *ngIf="!obj.image && obj.firstname && obj.lastname" class="text">
			{{ obj.firstname.charAt(0) }}{{ obj.lastname.charAt(0) }}
		</div>
		<fm-icon *ngIf="!obj.image && (!obj.firstname || !obj.lastname)" [type]="objtype"></fm-icon>
	</div>
	<ng-container *ngIf="showEdit">
		<upload-cdn-image class="image-upload" [obj]="obj" [objtype]="objtype"></upload-cdn-image>
	</ng-container>
</div>

<!-- ASSET -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.ASSET)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="obj && obj.images && obj.images.length"
			[src]="getCdnImage(obj.images[0])" />
		<fm-icon *ngIf="!obj || !obj.images || !obj.images.length" [type]="objtype"></fm-icon>
	</div>
	<ng-container *ngIf="showEdit">
		<upload-cdn-image class="image-upload" [obj]="obj" [objtype]="objtype"></upload-cdn-image>
	</ng-container>
</div>

<!-- BEACON -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.BEACON)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="obj.beaconType"
			[src]="getBeaconImage(obj.beaconType)" />
		<fm-icon *ngIf="!obj.beaconType" [type]="objtype"></fm-icon>
	</div>
</div>

<!-- NFC -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.NFC)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="obj.nfcType"
			[src]="getNFCImage(obj.nfcType)" />
		<fm-icon *ngIf="!obj.nfcType" [type]="objtype"></fm-icon>
	</div>
</div>

<!-- PAYLOAD -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.PAYLOAD)">
	<div class="image-wrap">
		<payload-preview class="payload-preview" *ngIf="obj.type" [payload]="obj"></payload-preview>
		<fm-icon *ngIf="!obj.type" [type]="objtype"></fm-icon>
	</div>
</div>

<!-- GATEWAY -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.GATEWAY)">
	<div class="image-wrap">
		<i class="fm-icon fa-solid fa-bezier-curve" *ngIf="obj.gatewayType === 'standard'"></i>
		<i class="fm-icon fa-solid fa-router" *ngIf="obj.gatewayType === 'lorawangateway'"></i>
		<i
			class="fm-icon fa-solid fa-circle-nodes"
			*ngIf="obj.gatewayType === 'lorawancollector'"></i>
	</div>
</div>

<!-- DEVICE/TASK/ZONE -->
<div
	class="profile-image"
	[ngClass]="mode"
	*ngIf="
		isType(ProfileType.DEVICE) ||
		isType(ProfileType.PROGRAM) ||
		isType(ProfileType.TASK) ||
		isType(ProfileType.ZONE)
	">
	<div class="image-wrap">
		<fm-icon [type]="objtype"></fm-icon>
	</div>
</div>

<!-- GEOFENCE -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.GEOFENCE)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="hasLatLng()"
			[src]="getMap('G')" />
		<fm-icon *ngIf="!hasLatLng()" [type]="objtype"></fm-icon>
	</div>
</div>

<!-- SITE -->
<div class="profile-image" [ngClass]="mode" *ngIf="isType(ProfileType.SITE)">
	<div class="image-wrap">
		<img
			alt="profile image"
			width="100%"
			height="100%"
			*ngIf="hasLatLng()"
			[src]="getMap('S')" />
		<fm-icon *ngIf="!hasLatLng()" [type]="objtype"></fm-icon>
		<!--
		<div class="text">
			{{obj.state | stateAbbrev}}
		</div>
		-->
	</div>

	<ng-container *ngIf="showEdit">
		<div class="image-upload" (click)="goToState('location')">Edit Location</div>
	</ng-container>
</div>
