import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep, includes, isEqual, sortBy, without } from 'lodash-es';

import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-type',
	templateUrl: './filter-type.component.html',
})
export class FilterTypeComponent implements OnInit, OnChanges {
	@Input() filter: string[];
	@Input() typelist: string;
	// EVENTS
	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	global: GlobalStore;

	editor;
	currentFilter: string[];

	selected: string[];
	options: string[];
	optionList: string[];
	lengthLimit: number;
	noResults: boolean;

	constructor(private FM_Global: GlobalService) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.editor = {};
		this.selected = [];

		this.lengthLimit = 100;

		if (this.typelist === 'gatewayTypes') {
			this.options = ['standard', 'lorawangateway', 'lorawancollector'];
		} else {
			this.options = cloneDeep(this.global.company[this.typelist]);
		}

		this.initFilter();
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = cloneDeep(this.filter);
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		this.selected = sortBy(this.selected);

		if (!this.selected.length) {
			delete this.filter;
		} else {
			this.filter = cloneDeep(this.selected);
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);
			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	addItem(item: string): void {
		this.selected.push(item);
		this.runupdate();
	}

	clearItem(item: string): void {
		this.selected = without(this.selected, item);
		this.runupdate();
	}

	filterClick(item: string): void {
		if (this.isActive(item)) {
			this.clearItem(item);
		} else {
			this.addItem(item);
		}
	}

	isActive(filter: string): boolean {
		return includes(this.selected, filter);
	}

	getList(val: string): string[] {
		// USE OPTION LIST
		const l = this.options.filter((item) => {
			return item.match(new RegExp('\\b' + val, 'i'));
		});

		return l;
	}

	initFilter(): void {
		this.selected = this.options.filter((item) => {
			return includes(this.filter, item);
		});

		this.optionList = sortBy(this.options);
	}

	clearFilter(): void {
		this.selected = [];
	}
}
