import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { cloneDeep, isEmpty, isEqual, remove } from 'lodash-es';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-quicksearch',
	templateUrl: './filter-quicksearch.component.html',
	styleUrls: ['./filter-quicksearch.component.less'],
})
export class FilterQuicksearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() filter;
	@Input() placeholder: string;
	@Input() allowTags = true;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<string>();

	currentFilter;
	tags: string[];
	searchText: string;

	private _qsChanged: Subject<any> = new Subject<any>();

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	get hasText(): boolean {
		return this.searchText && this.searchText !== '';
	}

	ngOnInit(): void {
		this.placeholder = this.placeholder ? this.placeholder : 'TAG';
		this.tags = [];

		if (!isEmpty(this.filter)) {
			this.tags = cloneDeep(this.filter);
		}

		this._qsChanged.pipe(debounceTime(400)).subscribe((model) => {
			this.searchText = model;
			this.runupdate();
		});
	}

	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = cloneDeep(this.filter);
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		this.filter = [];

		if (this.tags.length) {
			this.filter = cloneDeep(this.tags);
		}

		if (this.searchText) {
			this.filter.push(this.searchText);
		}

		if (!this.filter.length) {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit(this.searchText);
		}
	}

	checkKey(e: KeyboardEvent): void {
		if (this.allowTags) {
			switch (e.key) {
				case 'Enter':
					if (this.hasText) {
						this.tags.push(this.searchText);
						this.searchText = '';
						this._qsChanged.next(null);
					}
					break;
				case 'Backspace':
					if (!this.searchText || this.searchText === '') {
						this.tags.pop();
						this._qsChanged.next(null);
					}
					break;
			}
		}
	}

	setText(): void {
		if (this.allowTags && this.hasText) {
			this.tags.push(this.searchText);
			this.searchText = '';
			this._qsChanged.next(null);
		}
	}

	performSearch(text: string): void {
		this._qsChanged.next(text);
	}

	removeTag(tag: string): void {
		remove(this.tags, (t) => t === tag);
		this.runupdate();
	}

	clearFilter(): void {
		this.tags = [];
		this.searchText = '';
		delete this.filter;
		this.runupdate();
	}

	ngOnDestroy(): void {
		this._qsChanged.unsubscribe();
	}
}
