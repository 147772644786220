import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LogService {
	log(...msgs): void {
		// let args = Array.prototype.slice.call(arguments);
		if (window.console) {
			console.log(...msgs);
		}
	}

	info(...msgs): void {
		if (window.console) {
			console.info(...msgs);
		}
	}
}
