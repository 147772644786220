import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '@fm/services';

import { cloneDeep, pull } from 'lodash-es';

@Component({
	selector: 'settings-dialog',
	templateUrl: './settings-dialog.component.html',
})
export class SettingsDialogComponent implements OnInit {
	isLoading: boolean;
	isFailed: boolean;

	settingsTotal: number;
	settings: any[];
	selected: any[];
	filtered: any[];

	qs: string;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SettingsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService
	) {
		this.FM_Api = FM_Api;
	}

	ngOnInit(): void {
		this.settings = [];
		this.isLoading = true;

		if (this.data.setting.value.selected?.length) {
			this.selected = cloneDeep(this.data.setting.value.selected);
		} else {
			this.selected = [];
		}

		this.FM_Api.connect('setting').query(
			{ key: this.data.setting.key },
			(data) => {
				this.settingsTotal = data.length;
				this.settings = data;

				this.isLoading = false;
			},
			() => {
				this.isFailed = true;
			}
		);
	}

	filterList(list: any[], qs: string): any[] {
		this.filtered = this.settings;
		if (qs) {
			this.filtered = list.filter((obj) => {
				return obj.name.toLowerCase().indexOf(qs.toLowerCase()) !== -1;
			});
		}
		return this.filtered;
	}

	// QUICKSEARCH
	clearQs(): void {
		this.qs = '';
	}

	// CHECK IF SELECTED
	isSelected(s): boolean {
		return this.selected.includes(s);
	}

	// REMOVE ITEM FROM SELECTED
	removeItem(s): void {
		this.selected = pull(this.selected, s);
	}

	// ADD ITEM TO SELECTED
	selectItem(s): void {
		const isSelected = this.selected.includes(s);

		if (!isSelected) {
			this.selected.push(s);
		} else {
			this.removeItem(s);
		}
	}

	// REMOVE ALL SELECTED
	clearAll(): void {
		this.selected = [];
	}

	// MODAL ACTIONS
	ok(): void {
		this.data.setting.value = {
			options: '',
			selected: cloneDeep(this.selected),
		};
		this.dialogRef.close(this.data.setting);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
