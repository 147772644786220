import { AdminUser } from './adminuser';
import { Company } from './company';

export enum Environment {
	DEVELOPEMENT = 'development',
	PRODUCTION = 'production',
	PRODUCTIONEU = 'production-eu',
}

export interface Language {
	key: string;
	name: string;
}

export interface GlobalConfig {
	current: Environment;
	endpoints: any;
	isDebug: boolean;
	options: any;
	server: string;
	version: string;

	timeout: any;
	token: string;

	allowedCompanies: string[];
}

export interface GlobalCompany extends Company {
	id: string;
	name: string;

	parent: any;
	sharingWith: any;
}

export interface GlobalUser extends AdminUser {
	id: string;
	groups: any;
	isDev: boolean;
	showSuper: boolean;
}

export interface GlobalStore {
	authenticated: boolean;
	branding?: string;
	cdn?: {
		internal: string;
		external: string;
	};

	company?: GlobalCompany;
	companysettings?: any;
	config?: GlobalConfig;
	group?: any;

	language: any;
	overview?: any;
	pageTitle?: string;

	partners?: {
		from: any;
		to: any;
	};

	parent?: any;
	permissions?: any;
	permissionFilters?: any;

	notifications: any;
	socket?: any;
	switchCompany?: any;
	tooltips: any;
	timeout: any;

	user?: GlobalUser;

	userfilters?: any;
	usersettings?: any;
}
