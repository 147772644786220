<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="SAVE_SEARCH"></h3>
	</div>
	<div class="modal-body">
		<div class="form-row fm-setting">
			<div class="field-editor">
				<input
					name="name"
					class="fm-input"
					type="text"
					#name="ngModel"
					[(ngModel)]="filter.name"
					placeholder="NAME"
					required />
				<input-status class="input-status" [obj]="name"></input-status>
				<span
					class="error-message"
					*ngIf="name.errors?.required"
					translate="REQUIRED_FIELD"></span>
			</div>
		</div>
		<!--
		<div class="form-row" *ngIf="global.user.isSuper">
			<div class="fm-message warning">Global filters are viewable to all companies</div>
			<label class="label" translate="IS_GLOBAL"></label>
			<div class="field-toggle-wrap">
				<fm-toggle [(ngModel)]="filter.isGlobal"></fm-toggle>
			</div>
		</div>
		-->
	</div>

	<div class="modal-footer">
		<button class="fm-button" translate="OK"></button>
		<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
	</div>
</form>
