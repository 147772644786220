import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nesting' })
export class NestingPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return '';
		}
		return value.split(',').join(' > ');
	}
}
