<form
	#modalForm="ngForm"
	name="modalForm"
	class="modal-form"
	(ngSubmit)="ok(modalForm)"
	novalidate="novalidate"
	autocomplete="nope">
	<div class="modal-header">
		<h3 class="modal-title" [translate]="profile.title"></h3>
	</div>
	<div class="modal-body">
		<fm-loader [loading]="profile.isLoading"></fm-loader>
		<ng-container *ngIf="!profile.isLoading">
			<fieldset ngModelGroup="basics">
				<profile-basics [obj]="obj" [objtype]="profile.type">
					<div class="form-row" *ngIf="profile.type === 'district'">
						<div class="fm-setting">
							<div class="field-text">
								<label class="label" translate="REGION"></label>
								<div class="field-help" translate="DISTRICT_REGION_HELP"></div>
							</div>
							<div
								class="field-editor"
								*ngIf="!regions?.length"
								translate="ERROR_REGION_FOR_DISTRICT"></div>
							<div class="field-editor" *ngIf="regions?.length">
								<select
									class="fm-select"
									name="region"
									#region="ngModel"
									[(ngModel)]="obj.region"
									[compareWith]="compareIds"
									required>
									<option [value]="undefined" translate="SELECT_REGION"></option>
									<option
										[ngValue]="region"
										*ngFor="
											let region of regions;
											trackBy: 'id' | trackByProperty
										">
										{{ region.name }}
									</option>
								</select>
								<span
									class="error-message"
									*ngIf="region.errors?.required"
									translate="REQUIRED_FIELD"></span>
							</div>
						</div>
					</div>
				</profile-basics>
			</fieldset>
		</ng-container>
	</div>

	<div class="modal-footer" *ngIf="profile.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!profile.isBusy">
		<button class="fm-button" [disabled]="modalForm.invalid" translate="SAVE"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
