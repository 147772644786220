import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatHeader' })
export class FormatHeaderPipe implements PipeTransform {
	transform(word: string): string {
		const output = word
			.split(/(?=[A-Z])/)
			.map((s) => s.toUpperCase())
			.join('_');

		return output;
	}
}
