import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { ProfileType } from '@fmlib/enums';
import { GlobalCompany, GlobalStore, GlobalUser, Language } from '@fmlib/interfaces';
import { ConfigService } from '@fm/services';

import { map } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';

import { find, isUndefined, some } from 'lodash-es';

export const DATE_NULL = '0001-01-01T00:00:00.000Z';

@Injectable({ providedIn: 'root' })
export class GlobalService {
	private global: GlobalStore;
	private languages: Language[];

	private _company: Subject<GlobalCompany> = new Subject<GlobalCompany>();
	private _switch: Subject<any> = new Subject<any>();
	private _token: Subject<string> = new Subject<string>();
	private _branding: Subject<string> = new Subject<string>();

	constructor(
		private FM_Config: ConfigService,
		private http: HttpClient,
		private translate: TranslateService
	) {
		this.languages = [
			{
				key: 'en-US',
				name: 'English',
			},
			{
				key: 'zh-CN',
				name: 'Chinese (simplified)',
			},
			{
				key: 'zh-TW',
				name: 'Chinese (traditional)',
			},
			{
				key: 'es',
				name: 'Spanish (ES)',
			},
			{
				key: 'es-MX',
				name: 'Spanish (LA)',
			},
		];

		this.global = {
			authenticated: false,
			notifications: {},
			timeout: {
				api: 30000,
				loading: 500,
				search: 500,
			},
			language: this.currentLanguage(),
			tooltips: [],
		};
	}

	init(): Promise<void> {
		return new Promise((resolve) => {
			if (this.global.config) {
				resolve(null);
			} else {
				this.FM_Config.init().then(() => {
					this.global.config = this.FM_Config.get();
					resolve(null);
				});
			}
		});
	}

	isLoggedIn(): Observable<boolean> {
		return of(this.global.authenticated);
	}

	get(): GlobalStore {
		return this.global;
	}

	// GET CURRENT LANG
	currentLanguage(): Language {
		let l = this.translate.currentLang;
		let lang = this.languages.find(({ key }) => key === l);

		// FORCE ENGLISH
		lang = this.languages.find(({ key }) => key === 'en-US');

		if (isUndefined(lang)) {
			l = 'en-US';
			lang = this.languages.find(({ key }) => key === 'en-US');

			// SET BOTH LIBRARIES

			this.translate.use('en-US');
		} else {
			this.translate.use(lang.key);
		}

		return lang;
	}

	getCompanyList(): any {
		const companyArray = this.global.company.sharingWith.map((o) => o.id);
		companyArray.push(this.global.company.id);
		return companyArray;
	}

	getSubDomain(): string {
		const urlParts = window.location.host.split('.');
		// [sub, domain, com]
		return urlParts[0];
	}

	// LANG FUNCTIONS
	getLanguages(): Language[] {
		return this.languages;
	}

	setLanguage(lang: Language): void {
		this.global.language = lang;

		// SET BOTH LIBRARIES
		this.translate.use(lang.key);
	}

	getSetting(setting: string): any {
		if (this.global && this.global.companysettings) {
			const s = this.global.companysettings.find(({ key }) => key === setting);
			const data = s ? s.value : '';
			return data;
		} else {
			return null;
		}
	}

	setBranding(): void {
		this.http
			.get('/assets/json/branding.json')
			.pipe(
				map((response) => {
					const f: any = find(response, { domain: window.location.hostname });
					this.global.branding = f ? f.branding : 'footmarks';

					this._branding.next(this.global.branding);
				})
			)
			.subscribe();
	}

	updateBranding(): void {
		console.log(this.global.branding);
		this._branding.next(this.global.branding);
	}

	// CHANGE ENV
	setEnv(newEnv: string): void {
		this.global.config = this.FM_Config.updateEnv(newEnv);
	}

	setCompany(company: GlobalCompany): void {
		this.global.company = company;
		this.global.branding = company.branding ? company.branding : 'footmarks';
		this.global.partners = { from: [], to: [] };

		this._company.next(company);
		this._branding.next(this.global.branding);
	}

	setUser(user: GlobalUser): void {
		this.global.user = user;

		// CHECK IF SUPER
		const hasSuperGroup = some(this.global.user.groups, { isSuper: true });
		this.global.user.isSuper = hasSuperGroup;
		this.global.user.showSuper = hasSuperGroup;
	}

	hasPermission(type: string, permission: string): boolean {
		const perm = this.global.permissions[type];
		return perm && perm.actions && perm.actions.allowed.indexOf(permission) !== -1;
	}

	// COMPANY_SWITCH
	switchCompany(id: string): void {
		this.global.switchCompany = id;
		this._switch.next(id);
	}

	onSwitch(): Observable<any> {
		return this._switch.asObservable();
	}

	companyChange(): Observable<GlobalCompany> {
		return this._company.asObservable();
	}

	brandingChange(): Observable<string> {
		return this._branding.asObservable();
	}

	getCounts(): any {
		return {
			adminuser: this.global.company.adminuserCount,
			app: this.global.company.appCount,
			asset: this.global.company.assetCount,
			beacon: this.global.company.beaconCount,
			device: this.global.company.deviceCount,
			experience: this.global.company.experienceCount,
			gateway: this.global.company.gatewayCount,
			geofence: this.global.company.geofenceCount,
			journey: this.global.company.journeyCount,
			nfc: this.global.company.nfcCount,
			partner: this.global.company.partnerCount,
			payload: this.global.company.payloadCount,
			push: this.global.company.pushCount,
			site: this.global.company.siteCount,
			task: this.global.company.taskCount,
			zone: this.global.company.zoneCount,
		};
	}

	updateCount(type, count): void {
		this.global.overview[type] = this.global.overview[type] + count;
	}

	hasPartners(type: ProfileType): boolean {
		return this.global.partners.from.some((item) => {
			if (type === ProfileType.APP && item.shareApps) {
				return item;
			}

			if (type === ProfileType.ASSET && item.shareAssets) {
				return item;
			}

			if (type === ProfileType.SITE && item.shareSites) {
				return item;
			}
		});
	}

	// LOGOUT TRACK
	logout(reason?: string): void {
		this._token.next(reason);
	}

	onLogout(): Observable<any> {
		return this._token.asObservable();
	}
}
