<form
	#modalForm="ngForm"
	name="modalForm"
	class="modal-form"
	(ngSubmit)="modalForm.valid && ok(modalForm)"
	novalidate="novalidate"
	autocomplete="nope">
	<div class="modal-header">
		<h3 class="modal-title" translate="{{ profile.title }}"></h3>
	</div>

	<div class="modal-body">
		<div class="form-row fm-setting">
			<div class="field-text">
				<label class="label" translate="NAME"></label>
				<div class="field-help" translate="NAME_HELP"></div>
			</div>
			<div class="field-editor">
				<input
					class="fm-input"
					name="name"
					type="text"
					#name="ngModel"
					[(ngModel)]="obj.name"
					placeholder="{{ 'NAME' | translate }}"
					required />
				<input-status class="input-status" [obj]="name"></input-status>
				<span
					class="error-message"
					*ngIf="name.errors?.required"
					translate="REQUIRED_FIELD"></span>
			</div>
		</div>

		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="SITETYPE_MODE"></label>
					<div class="field-help" translate="SITETYPE_MODE_HELP"></div>
				</div>

				<div class="field-editor">
					<label class="checkbox-list-item" *ngFor="let mode of sitetypeModes">
						<input
							type="radio"
							name="sitetypeMode"
							[(ngModel)]="obj.sitetypeMode"
							value="{{ mode }}"
							[disabled]="obj.isReadonly"
							required />
						{{ mode | uppercase | translate }}
					</label>

					<!--
					<span class="error-message" *ngIf="sitetypeMode.invalid" translate="REQUIRED"></span>
					-->
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer" *ngIf="profile.isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>
	<div class="modal-footer" *ngIf="!profile.isBusy">
		<button class="fm-button" [disabled]="hasErrors()" translate="SAVE"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
