import { Component, Input } from '@angular/core';

@Component({
	selector: 'fm-select',
	template: `<div class="field-info" *ngIf="readonly">{{ model | uppercase | translate }}</div>
		<div *ngIf="!readonly">
			<select class="fm-select" name="formSelect" #formSelect="ngModel" [(ngModel)]="value">
				<option value="op.id" *ngFor="let op of options">{{ op.name }}</option>
			</select>
			<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span
			><input-status class="input-status" [obj]="formSelect"></input-status>
		</div>`,
})
export class FmSelectComponent {
	// bindings
	@Input() value;
	@Input() options;
	@Input() readonly: boolean;

	// required
	form;
	model;

	/*
	ngOnInit(): void {
		
		this.ngModel.$formatters.push((modelValue) => {
			return modelValue;
		});

		this.ngModel.$parsers.push((viewValue) => {
			return viewValue;
		});

		this.ngModel.$render = () => {
			this.model = this.ngModel.$viewValue;
		};
		
	}
	*/

	update(): void {
		// this.ngModel.$setViewValue(this.model);
	}
}
