import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'google-dialog',
	templateUrl: './google-dialog.component.html',
})
export class GoogleDialogComponent implements OnInit {
	global: GlobalStore;
	googleapi;

	showFrame: boolean;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<GoogleDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.googleapi = {
			redirect_uri: window.location.protocol + '//' + window.location.host + '/googleapi',
			client_id: this.getSetting('google-proximity-client-id'),
			client_secret: this.getSetting('google-proximity-client-secret'),
			project_id: this.getSetting('google-proximity-project-id'),
			scope: 'https://www.googleapis.com/auth/userlocation.beacon.registry',
			access_type: 'offline',
			response_type: 'code',
		};
	}

	getSetting(setting): string {
		const s = this.global.companysettings.find(({ key }) => key === setting);
		let link = '';

		if (s) {
			link = s.value;
		}
		return link;
	}

	saveSettings(): void {
		const client_id = this.global.companysettings.find(
			({ key }) => key === 'google-proximity-client-id'
		);
		const client_secret = this.global.companysettings.find(
			({ key }) => key === 'google-proximity-client-secret'
		);
		const project_id = this.global.companysettings.find(
			({ key }) => key === 'google-proximity-project-id'
		);

		const obs = [];

		// GOOGLE ACTIVE
		obs.push(
			this.FM_Api.connect('companysettings').put(
				{ id: client_id.id },
				{ value: this.googleapi.client_id }
			)
		);

		// SAVE REFRESH
		obs.push(
			this.FM_Api.connect('companysettings').put(
				{ id: client_secret.id },
				{ value: this.googleapi.client_secret }
			)
		);

		// SAVE TOKEN
		obs.push(
			this.FM_Api.connect('companysettings').put(
				{ id: project_id.id },
				{ value: this.googleapi.project_id }
			)
		);

		forkJoin(obs).subscribe(() => {
			const url =
				'https://accounts.google.com/o/oauth2/v2/auth?' +
				'response_type=' +
				this.googleapi.response_type +
				'&client_id=' +
				this.googleapi.client_id +
				'&redirect_uri=' +
				this.googleapi.redirect_uri +
				'&scope=' +
				this.googleapi.scope +
				'&access_type=' +
				this.googleapi.access_type;

			window.location.href = url;
		});
	}

	// MODAL ACTIONS
	ok(): void {
		this.saveSettings();
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
