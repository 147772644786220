<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_LOCATION"></h3>
</div>
<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<h4 class="panel-title" translate="SELECT_SITE"></h4>

			<div class="search-header">
				<div class="fm-quicksearch">
					<input
						type="text"
						#searchInput
						class="fm-input"
						placeholder="{{ 'SEARCH' | translate }}"
						matInput
						[formControl]="myControl" />
				</div>

				<div class="search-filter">
					<select class="fm-select" [(ngModel)]="filters.siteType" (change)="initSites()">
						<option [value]="undefined" translate="SELECT_TYPE"></option>
						<option
							[value]="op.id"
							*ngFor="let op of siteTypes"
							[translate]="op.name"></option>
					</select>
				</div>
			</div>

			<section
				class="fm-picker-list"
				infinite-scroll
				[infiniteScrollDisabled]="sites.isLoading"
				[scrollWindow]="false"
				[infiniteScrollThrottle]="0"
				(scrolled)="loadSites()">
				<div class="fm-message notice" *ngIf="sites.total === 0 && !sites.isLoading">
					<fm-icon type="site"></fm-icon>
					<span translate="NO_SITES"></span>
				</div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of sites.list; trackBy: 'id' | trackByProperty"
						(click)="selectSite(item)"
						[ngClass]="{ selected: item.id === selected.site?.id }">
						<div class="list-cell wrapped">
							<profile-image
								[obj]="item"
								[objtype]="ProfileType.SITE"
								mode="size_50"></profile-image>
						</div>
						<div class="list-cell fm-list-detail">
							<div class="detail-main">
								{{ item.name }}
								<ul class="detail">
									<li [innerText]="item.street | emptyString"></li>
									<li>{{ item.city }}, {{ item.state }} {{ item.postalCode }}</li>
									<li *fmSetting="'smart-connect'">
										{{ 'ZONES' | translate }} :
										{{ item.zoneCount | emptyNumber }}
									</li>
								</ul>
							</div>
							<div class="detail-info" *ngIf="item.isActive">
								<span class="status-dot active"></span>
							</div>
							<div class="detail-info" *ngIf="!item.isActive">
								<span class="status-dot inactive"></span>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="sites.isLoading"></fm-loader>

			<list-status
				[loading]="sites.isLoading"
				[count]="sites.list.length"
				[total]="sites.total"></list-status>
		</div>

		<div class="select-panel" *fmSetting="'smart-connect'">
			<h4 class="panel-title" translate="SELECT_ZONE"></h4>

			<div class="fm-quicksearch">
				<input
					type="text"
					class="fm-input"
					[(ngModel)]="zones.qs"
					placeholder="{{ 'SEARCH' | translate }}..."
					(ngModelChange)="initZones()" />
			</div>

			<section
				class="fm-picker-list"
				infinite-scroll
				[infiniteScrollDisabled]="zones.isLoading"
				[scrollWindow]="false"
				[infiniteScrollThrottle]="0"
				(scrolled)="loadZones()">
				<div class="fm-message notice" *ngIf="!zones.isLoading && zones.total === 0">
					<fm-icon type="zone"></fm-icon>
					<span translate="NO_ZONES"></span>
				</div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of zones.list; trackBy: 'id' | trackByProperty"
						(click)="selectZone(item.zone)"
						[ngClass]="{ selected: item.zone?.id === selected.zone?.id }">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">{{ item.zone.name }}</div>
							<div class="detail-info" *ngIf="item.zone.isActive">
								<span class="status-dot active"></span>
							</div>
							<div class="detail-info" *ngIf="!item.zone.isActive">
								<span class="status-dot inactive"></span>
							</div>
						</div>
					</a>
				</div>
			</section>

			<fm-loader [loading]="zones.isLoading"></fm-loader>

			<list-status
				[loading]="zones.isLoading"
				[count]="zones.list.length"
				[total]="zones.total"></list-status>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
