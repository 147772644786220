<div class="modal-header">
	<h3 class="modal-title" translate="CHOOSE_STATES"></h3>
</div>

<div class="modal-body">
	<div class="fm-picker">
		<div class="source-panel">
			<div class="fm-quicksearch">
				<input
					#searchInput
					type="text"
					class="fm-input"
					[(ngModel)]="search"
					placeholder="{{ 'SEARCH' | translate }}..."
					(ngModelChange)="initSearch()"
					[ngModelOptions]="{ standalone: true }" />
				<a
					[routerLink]=""
					class="quicksearch-clear"
					(click)="clearQuicksearch()"
					*ngIf="search"
					><i class="fa fa-times"></i
				></a>
			</div>

			<section class="fm-picker-list">
				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of states; trackBy: 'id' | trackByProperty"
						(click)="selectItem(item)"
						[ngClass]="{ selected: isSelected(item) }">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">{{ item.label }}</div>
						</div>
					</a>
				</div>
			</section>

			<list-status [count]="states?.length" [total]="states?.length"></list-status>
		</div>

		<div class="select-panel">
			<h4 class="panel-title" translate="SELECTED"></h4>
			<div class="modal-actions">
				<a [routerLink]="" (click)="clearAll()" translate="CLEAR_ALL"></a>
			</div>

			<section class="fm-picker-list selected-list">
				<div
					class="fm-message notice"
					*ngIf="selected?.length === 0"
					translate="NO_SELECTED"></div>

				<div class="fm-list">
					<a
						[routerLink]=""
						class="fm-list-item"
						*ngFor="let item of selected; trackBy: 'id' | trackByProperty"
						(click)="removeItem(item)">
						<div class="list-cell fm-list-detail">
							<div class="detail-main">{{ item.label }}</div>
						</div>
					</a>
				</div>
			</section>

			<div
				class="fm-list-count"
				translate="SELECTED_COUNT"
				[translateParams]="{ count: selected?.length }"></div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
