import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchParams } from '@fmlib/interfaces';

import { ApiService } from '@fm/services';

import { cloneDeep, forOwn, groupBy, keys, reject, some, sortBy } from 'lodash-es';

@Component({
	selector: 'profiles-dialog',
	templateUrl: './profiles-dialog.component.html',
})
export class ProfilesDialogComponent implements OnInit {
	activity;
	groups;
	profiles;
	selected;
	totalUsers: number;
	userinput;
	view;
	currentGroup;
	count;
	profileGroups;
	total: number;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ProfilesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService
	) {}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.view = {
			isLoading: false,
			isFailed: false,
		};

		this.userinput = {};

		this.selected = cloneDeep(this.data.selected);

		this.initSearch();
	}

	setProfileGroup(group): void {
		this.currentGroup = group;
		this.count = group.profiles.length;
		this.total = group.profiles.length;
	}

	isProfileActive(p): boolean {
		return some(this.selected, { id: p.id });
	}

	clearProfileGroup(): void {
		delete this.currentGroup;
		this.count = keys(this.groups).length;
		this.total = keys(this.groups).length;
	}

	// CLEAR ALL
	clearAll(): void {
		this.selected = [];

		this.profiles.forEach((item) => {
			item.isSelected = false;
		});
	}

	// REMOVE ITEM FROM SELECTED
	removeItem(obj): void {
		const item = this.profiles.find(({ id }) => id === obj.id);
		//IS THE ITEM LOADED
		if (item) {
			item.isSelected = false;
		}
		this.selected = reject(this.selected, obj);
	}

	// ADD ITEM TO SELECTED
	selectItem(obj): void {
		if (!obj.isSelected) {
			obj.isSelected = true;
			this.selected.push(obj);
		} else {
			obj.isSelected = false;
			this.removeItem(obj);
		}
	}

	// PROFILE HELPERS
	getProfileActivity(obj): string {
		let val = '0';

		if (this.activity) {
			const o = this.activity.find(({ key }) => key === obj.id);

			if (o && o.value !== 0) {
				val = ((100 * parseInt(o.value, 10)) / this.totalUsers).toFixed(2);
			}
		}
		return val + '% of active users';
	}

	initData(): void {
		const profs = [];

		forOwn(this.groups, (value, key) => {
			let groupTotal;
			const group: any = {};

			group.key = key;
			group.profiles = value;
			groupTotal = 0;

			value.forEach((item) => {
				const o = this.activity.find(({ key }) => key === item.id);
				if (o) {
					groupTotal = groupTotal + parseInt(o.value, 10);
				}
			});

			if (groupTotal !== 0) {
				groupTotal = ((100 * groupTotal) / this.totalUsers).toFixed(2);
			} else {
				groupTotal = 0;
			}

			group.activity = groupTotal + '% of active users';

			profs.push(group);
		});

		this.profileGroups = sortBy(profs, 'key');

		this.clearProfileGroup();

		this.view.isLoading = false;
	}

	loadActivity(): void {
		// GET THE PROFILE ACTIVITY
		this.FM_Api.connect('profileactivities').query(
			{ per_page: '200' }, // FIXED AMOUNT ... OR SO I AM TOLD
			(res) => {
				// GET THEM BY A GROUP
				this.totalUsers = res[0].totalUsers;
				this.activity = res[0].profiles;

				this.initData();
			}
		);
	}

	initSearch(): void {
		this.profileGroups = [];
		this.view.isLoading = true;

		const params: SearchParams = {
			page: 1,
			sort: 'name',
			per_page: 200,
			conditions: {},
		};

		if (this.userinput.searchText && this.userinput.searchText !== '') {
			params.conditions.$or = [
				{
					name: {
						$regex: this.userinput.searchText,
						$options: 'i',
					},
				},
				{
					group: {
						$regex: this.userinput.searchText,
						$options: 'i',
					},
				},
			];
		}

		// GET THE PROFILES
		this.FM_Api.connect('profiles').query(params, (response) => {
			this.profiles = response;

			// GET THEM BY A GROUP
			this.groups = groupBy(response, (item) => {
				return item.group;
			});

			this.loadActivity();
		});
	}

	toggleProfiles(): void {
		// do something ?
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.selected);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
