import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
	transform(array: any[], propName: string): any[] {
		// WHY IS THIS PIPE RUNNIG SO MUCH!
		// console.log('array', array);

		return array.sort((a, b) => {
			if (a[propName] < b[propName]) {
				return -1;
			} else if (a[propName] > b[propName]) {
				return 1;
			} else {
				return 0;
			}
		});
	}
}
