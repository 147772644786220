import { Pipe, PipeTransform } from '@angular/core';
import { addMinutes, format } from 'date-fns';

@Pipe({
	standalone: true,
	name: 'simpleDate',
})
export class SimpleDatePipe implements PipeTransform {
	transform(value: string): string {
		if (value && value !== '') {
			const d = new Date(value);
			const utcDate = addMinutes(d, d.getTimezoneOffset());
			return format(utcDate, 'M/d/yy');
		}

		return '---';
	}
}
