import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { BroadcastService } from './broadcast.service';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
	constructor(public broadcastService: BroadcastService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((e) => {
				switch (e.status) {
					case 0:
						this.broadcastService.responseError.next(e);
						break;
					case 401:
						this.broadcastService.authError.next(e.error);
						break;
					case 403:
						this.broadcastService.responseError.next(e);
						break;
				}
				return throwError(() => e);
			})
		);
	}
}
