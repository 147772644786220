<form #modalForm="ngForm" name="modalForm" class="modal-form" (ngSubmit)="modalForm.valid && ok()">
	<div class="modal-header">
		<h3 class="modal-title" translate="ADD_NOTIFICATION"></h3>
	</div>

	<div class="modal-body" *ngIf="isLoading">
		<fm-loader [loading]="isLoading"></fm-loader>
	</div>

	<div class="modal-body" *ngIf="!isLoading && mode === 'add'">
		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="NOTIFICATION_TYPE"></label>
					<div class="field-help" translate="NOTIFICATION_TYPE_HELP"></div>
				</div>
				<div class="field-editor">
					<select
						class="fm-select"
						name="alertType"
						[(ngModel)]="notification.notifType"
						required>
						<option [value]="undefined" translate="SELECT_TYPE"></option>
						<option
							[value]="op.id"
							*ngFor="let op of notifTypes"
							[translate]="op.name | uppercase"></option>
					</select>
					<span class="input-icons">
						<i class="fa-light fa-circle-chevron-down"></i>
					</span>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="NOTIFICATION_LEVEL"></label>
					<div class="field-help" translate="NOTIFICATION_LEVEL_HELP"></div>
				</div>
				<div class="field-editor">
					<select
						class="fm-select"
						name="alertLevel"
						[(ngModel)]="notification.severity"
						required>
						<option [value]="undefined" translate="SELECT_LEVEL"></option>
						<option
							[value]="op.id"
							*ngFor="let op of severityLevels"
							[translate]="op.name | uppercase"></option>
					</select>
					<span class="input-icons">
						<i class="fa-light fa-circle-chevron-down"></i>
					</span>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="fm-setting">
				<div class="field-text">
					<label class="label" translate="NOTIFICATION_CONTENT"></label>
					<div class="field-help" translate="NOTIFICATION_CONTENT_HELP"></div>
				</div>

				<div class="field-toggle-wrap">
					<textarea
						class="fm-textarea"
						placeholder="{{ 'TEXT' | translate }}"
						[(ngModel)]="notification.content"
						required></textarea>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-body" *ngIf="!isLoading && mode === 'manage'">
		<!--<admin-notifications [(ngModel)]="adminuser"></admin-notifications>-->
	</div>

	<div class="modal-footer" *ngIf="isBusy">
		<i class="fa fa-circle-notch fa-spin loader"></i>
	</div>

	<div class="modal-footer" *ngIf="!isBusy">
		<button class="fm-button" translate="OK"></button>
		<a [routerLink]="" class="fm-button" (click)="cancel()" translate="CANCEL"></a>
	</div>
</form>
