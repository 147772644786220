import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { cloneDeep, isEqual } from 'lodash-es';

@Component({
	selector: 'filter-input',
	template: `<div class="filter-wrap">
		<input
			type="{{ type }}"
			class="fm-input"
			[ngModel]="val"
			(ngModelChange)="updateVal($event)"
			placeholder="{{ placeholder | translate }}" />
	</div>`,
})
export class FilterInputComponent implements OnInit, OnChanges, OnDestroy {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	@Input() placeholder: string;
	@Input() type: string;

	currentFilter: string;
	val: string;

	valChanged: Subject<string> = new Subject<string>();

	ngOnInit(): void {
		// INIT THE VALUE
		if (this.filter) {
			this.val = this.filter;
		}

		this.valChanged.pipe(debounceTime(400), distinctUntilChanged()).subscribe((model) => {
			this.val = model;
			this.runupdate();
		});
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		if (this.val && this.val !== '') {
			this.filter = this.val;
		} else {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	updateVal(query: string): void {
		this.valChanged.next(query);
	}

	clearFilter(): void {
		this.val = '';
	}

	ngOnDestroy(): void {
		this.valChanged.unsubscribe();
	}
}
