<div class="modal-header">
	<h3 class="modal-title" translate="RAW_BTS"></h3>
</div>

<div class="modal-body fm-audit">
	<fm-loader [loading]="isLoading" [error]="isFailed"></fm-loader>

	<section class="list-panel">
		<div class="fm-message notice">
			<span [innerText]="bts.created | simpleDate"></span>

			<div class="reload-wrapper" [ngClass]="{ paused: !timer.isRunning }">
				<div class="spinner pie"></div>
				<div class="filler pie"></div>
				<div class="mask"></div>
				<div
					class="timer fa fa-pause"
					*ngIf="timer.isRunning"
					(click)="toggleTimer()"></div>
				<div
					class="timer fa fa-play"
					*ngIf="!timer.isRunning"
					(click)="toggleTimer()"></div>
			</div>
			<a [routerLink]="" (click)="reload()" translate="RELOAD"></a>
		</div>

		<div class="fm-list">
			<a
				class="fm-list-item"
				[routerLink]=""
				(click)="loadDetail('assets')"
				[ngClass]="{ selected: bts.view === 'assets' }">
				<div class="list-cell">
					<span translate="ASSETS"></span>
				</div>
				<div class="list-cell">
					<span class="list-action" [innerText]="bts.assets.length"></span>
				</div>
			</a>

			<a
				class="fm-list-item"
				[routerLink]=""
				(click)="loadDetail('devices')"
				[ngClass]="{ selected: bts.view === 'devices' }">
				<div class="list-cell">
					<span translate="DEVICES"></span>
				</div>
				<div class="list-cell">
					<span class="list-action" [innerText]="bts.devices.length"></span>
				</div>
			</a>
		</div>
	</section>

	<section class="detail-panel">
		<h4 class="panel-title" [innerText]="bts.created | customDate"></h4>
		<div class="form-options">
			<div class="btn-group">
				<label
					class="btn"
					(click)="detail.view = 'rawjson'"
					[ngClass]="{ active: mode === 'rawjson' }">
					<h5 class="title" translate="SEND_TO_DEVICE"></h5>
				</label>
				<label
					class="btn"
					(click)="detail.view = 'macaddresses'"
					[ngClass]="{ active: mode === 'macaddresses' }">
					<h5 class="title" translate="TRIGGER_PUSH"></h5>
				</label>
			</div>
		</div>

		<ul class="address-list" *ngIf="detail.view === 'macaddresses'">
			<li *ngFor="let d of detail.data" [innerText]="d.mac"></li>
		</ul>
		<pre *ngIf="detail.view === 'rawjson'" class="pulse-output">
			<code [innerText]="detail.data | json"></code>
		</pre>
	</section>
</div>

<div class="modal-footer">
	<button class="fm-button" (click)="ok()" translate="CLOSE"></button>
</div>
