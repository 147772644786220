import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, GlobalService, MessageService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';
import { EMPTY, catchError } from 'rxjs';

@Component({
	selector: 'create-validation-dialog',
	templateUrl: './create-validation-dialog.component.html',
})
export class CreateValidationDialogComponent implements OnInit {
	global: GlobalStore;
	profile;
	settings;
	allOptions;
	validationrule;
	validationrules;
	validationOptions;
	validationTypes;

	editor;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<CreateValidationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(MessageService) private FM_Messages: MessageService
	) {
		this.global = this.FM_Global.get();

		this.settings = {
			endpoint: this.FM_Api.connect('validationrules'),
			populate: '',
		};
	}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.profile = {
			title: 'ADD_VALIDATION',
			type: 'validationrules',
			isLoading: false,
			isFailed: false,
		};

		this.validationTypes = ['site', 'asset', 'zone'];

		this.allOptions = {
			asset: ['assetType'],
			site: ['siteType', 'divisions'],
			zone: ['zoneType'],
		};

		if (this.data.selected) {
			this.validationrule = cloneDeep(this.data.selected);
			this.validationrule.prop = this.validationrule.rules[0].objProp;
		} else {
			this.validationrule = {};
		}

		this.profile.isLoading = true;

		this.loadValidations();

		setTimeout(() => {
			this.profile.isLoading = false;
		}, 400);
	}

	setType(): void {
		this.validationOptions = this.allOptions[this.validationrule.objType];
	}

	// LOAD COMPANY VALIDATIONS
	loadValidations(): void {
		const params = {
			page: 1,
			sort: 'name',
			conditions: {},
		};

		this.FM_Api.connect('validationrules').query(params, (data) => {
			if (data.length) {
				this.validationrules = data;
			}
		});
	}

	saveComplete(response, message): void {
		this.FM_Messages.addSuccessMessage(message);
		this.dialogRef.close(response);
		this.loadValidations();
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	saveError(): void {
		this.FM_Messages.addErrorMessage('REQUEST_ERROR');
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	hasErrors(): boolean {
		return false;
	}

	// SAVE ZONE
	ok(): any {
		if (this.profile.isBusy) return true;

		this.profile.isBusy = true;

		const v = this.validationrules.find(
			({ objType }) => objType === this.validationrule.objType
		);
		let temp: any = {};

		if (v) {
			temp.rules = v.rules;

			// PREPARE THE SAVE
			temp.rules.push({
				prop: this.validationrule.prop,
				validation: true,
			});

			this.settings.endpoint
				.put({ id: v.id }, temp)
				.pipe(
					catchError(() => {
						this.saveError();
						return EMPTY;
					})
				)
				.subscribe((response) => {
					this.saveComplete(response, 'CREATED_VALIDATION');
				});
		} else {
			// PREPARE THE SAVE
			temp = {
				objType: this.validationrule.objType,
				rules: [
					{
						prop: this.validationrule.prop,
						validation: true,
					},
				],
			};

			this.settings.endpoint.post(
				temp,
				(response) => {
					this.saveComplete(response, 'CREATED_VALIDATION');
				},
				() => {
					this.saveError();
				}
			);
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
