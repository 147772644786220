import { NgModule } from '@angular/core';
import { FiltersModule } from '../filter/filter.module';

import { SharedModule } from '../shared/shared.module';

import { NotificationsAddDialogComponent } from './notifications-add-dialog.component';
import { NotificationsDialogComponent } from './notifications-dialog.component';

const modules = [
	// DIALOGS
	NotificationsAddDialogComponent,
	NotificationsDialogComponent,
];

@NgModule({
	imports: [FiltersModule, SharedModule],
	declarations: modules,
	exports: modules,
})
export class NotificationsModule {}
