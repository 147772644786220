import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'metersToMiles' })
export class MeterPipe implements PipeTransform {
	transform(value: number): string {
		if (!value) {
			return '';
		}
		value = Math.round(value * 0.000621371);
		return value.toString();
	}
}
