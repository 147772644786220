import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'prefix-input',
	templateUrl: './prefix-input.component.html',
	styleUrls: ['./prefix-input.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PrefixInputComponent),
			multi: true,
		},
	],
})
export class PrefixInputComponent implements OnInit, ControlValueAccessor {
	@Input() value;
	@Input() placeholder: string;
	@Input() prefix: string;
	@Input() readonly: boolean;
	@Input() required: boolean;

	ngOnInit(): void {
		this.prefix = this.prefix ? this.prefix : '$';
	}

	updateValue(value): void {
		if (typeof this.onChange === 'function') {
			this.onChange(value);
		}
	}

	// NG MODEL FUNCTIONS
	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// do nothing
	}

	onChange = (_: any): any => {};
	onTouched = (_: any): any => {};
}
