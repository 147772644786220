import { Component, Input } from '@angular/core';

@Component({
	selector: 'list-status',
	template: `<div class="fm-list-count" *ngIf="loading" translate="LOADING"></div>
		<div
			class="fm-list-count"
			*ngIf="!loading && count > 0"
			translate="LOADED_COUNT"
			[translateParams]="{ count: count, total: total }"></div>
		<div class="fm-list-count" *ngIf="!loading && total === 0" translate="NO_RECORDS"></div> `,
})
export class ListStatusComponent {
	@Input() count: number;
	@Input() total: number;
	@Input() loading: boolean;
}
