import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { ProfileType } from '@fmlib/enums';

import { AppService, GlobalService } from '@fm/services';

import { Company, GlobalStore } from '@fmlib/interfaces';

import { animate, style, transition, trigger } from '@angular/animations';
import { AppView } from '../services/app.service';

export const openComp = trigger('openComp', [
	transition(':enter', [
		style({ opacity: 0, transform: 'scale(0.95)', visibility: 'hidden' }),
		animate('500ms', style({ opacity: 1, transform: 'scale(1)', visibility: 'visible' })),
	]),
	transition(':leave', [
		style({ opacity: 1, transform: 'scale(1)', visibility: 'visible' }),
		animate('500ms', style({ opacity: 0, transform: 'scale(0.95)', visibility: 'hidden' })),
	]),
]);

@Component({
	selector: 'app-base',
	templateUrl: './app-base.component.html',
	styleUrls: ['./app-base.component.less'],
	providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
	animations: [openComp],
})
export class AppBaseComponent implements OnInit {
	global: GlobalStore;

	ProfileType = ProfileType;

	get isBasic(): boolean {
		return this.FM_App.isBasic;
	}

	get changingCompany(): boolean {
		return this.FM_App.changingCompany;
	}

	get newCompany(): Company {
		return this.FM_App.newCompany;
	}

	get view(): AppView {
		return this.FM_App.view;
	}

	constructor(
		private renderer: Renderer2,
		private FM_App: AppService,
		private FM_Global: GlobalService
	) {}

	ngOnInit(): void {
		this.global = this.FM_Global.get();

		this.FM_App.init();

		// IF COMPANY IS CHANGED
		this.FM_Global.brandingChange().subscribe(() => {
			this.renderer.setAttribute(document.body, 'class', '');
			this.renderer.addClass(document.body, this.global.branding);
		});
	}
}
