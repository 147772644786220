import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { GlobalConfig } from '@fmlib/interfaces';
import { ConfigService, LogService } from '@fm/services';

import { add, formatISO, isBefore } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private config: GlobalConfig;

	private httpOptions: any;

	constructor(
		private http: HttpClient,
		private FM_Config: ConfigService,
		private FMLog: LogService
	) {}

	init(): void {
		this.config = this.FM_Config.get();

		this.FMLog.info('auth : init');

		// TOKEN VALIDATION STUFF
		const lastUsed = localStorage.getItem('fm_lastused');

		// TOKEN EXPIRES IN 1 HOUR
		const expire = add(new Date(lastUsed), { hours: 1 });

		// LAST TIME WE EXITED
		if (!lastUsed || isBefore(new Date(), expire)) {
			// TOKEN BLAH
			this.config.token = localStorage.getItem('fm_token');
			this.config.allowedCompanies = JSON.parse(localStorage.getItem('fm_companies'));

			// ATTEMPT TO SET TOKEN
			this.setToken(this.config.token);

			// UPDATE EXIT
			localStorage.setItem('fm_lastused', formatISO(new Date()));
		} else {
			this.FMLog.info('token expired');
			this.clearToken();
		}

		// ON EXIT WE SET TIMER
		window.onbeforeunload = (): void => {
			localStorage.setItem('fm_lastused', formatISO(new Date()));
		};
	}

	check(email: string): Observable<any> {
		return this.http
			.post(this.config.endpoints.auth + '/oauth/check', {
				username: email,
				grant_type: 'password',
			})
			.pipe(timeout(this.config.timeout.api));
	}

	getToken(email: string, password: string, totpcode: string): Observable<any> {
		const data = {
			username: email,
			password: password,
			totpcode: totpcode,
			grant_type: 'password',
		};
		const params = new URLSearchParams();

		for (const key in data) {
			if (data[key]) {
				params.append(key, data[key]);
			}
		}

		return this.http
			.post(this.config.endpoints.auth + '/oauth/token', params.toString(), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + this.config.token,
				},
			})
			.pipe(
				timeout(this.config.timeout.api),
				tap((res: any) => {
					if (!res.codeRequired) {
						this.setToken(res.accessToken);
						this.setCompanies(res.allowedCompanies);
					}
				})
			);
	}

	hasToken(): boolean {
		return this.config.token && this.config.token !== undefined;
	}

	// SET OUR TOKEN
	setToken(token: string): void {
		if (token) {
			this.config.token = token;

			this.httpOptions = {
				headers: { Authorization: 'Bearer ' + this.config.token },
			};

			localStorage.setItem('fm_token', this.config.token);
		}
	}

	// SET OUR TOKEN
	setCompanies(companies: string[]): void {
		/*
		companies = [
			'5417302d31c870100350101a', // FOOTMARK CODY
			'5dc5c8c813cb8d3bf0f83b81',
			'5cae1b980fa8d7329c877752',
		];
		*/

		this.config.allowedCompanies = companies;
		localStorage.setItem('fm_companies', JSON.stringify(companies));
	}

	checkCompany(companyId: string): boolean {
		const allowed = this.config.allowedCompanies.includes(companyId);

		if (!this.config.allowedCompanies.length || allowed) {
			return true;
		} else {
			return false;
		}
	}

	// CLEAR THE USER AND TOKEN INFO
	clearToken(): void {
		this.config.token = null;
		this.httpOptions = {};
		localStorage.removeItem('fm_token');
	}

	verifyAccess(): Observable<any> {
		const httpOptions: any = {
			headers: { Authorization: 'Bearer ' + this.config.token },
			responseType: 'text',
		};

		return this.http.get(this.config.endpoints.auth + '/oauth/verifyaccess', httpOptions).pipe(
			timeout(this.config.timeout.api),
			catchError(() => {
				return EMPTY;
			})
		);
	}

	verifyToken(token: string): Observable<any> {
		return this.http.get(this.config.endpoints.auth + `/security/token/${token}`).pipe(
			timeout(this.config.timeout.api),
			catchError(() => {
				return EMPTY;
			})
		);
	}

	logout(): Observable<any> {
		return this.http.delete(this.config.endpoints.auth + '/oauth/token', this.httpOptions).pipe(
			timeout(this.config.timeout.api),
			tap(() => this.clearToken())
		);
	}

	swapToken(accessToken: string, idToken: string): Observable<any> {
		return this.http
			.post(
				this.config.endpoints.auth + '/oauth/swap',
				{
					grant_type: 'password',
					accessToken: accessToken,
					idToken: idToken,
				},
				this.httpOptions
			)
			.pipe(
				timeout(this.config.timeout.api),
				tap((data: any) => this.setToken(data.accessToken))
			);
	}

	switchCompany(companyId: string): Observable<any> {
		return this.http
			.post(
				this.config.endpoints.auth + '/oauth/switch',
				{ next: companyId },
				this.httpOptions
			)
			.pipe(timeout(this.config.timeout.api));
	}

	verifyEmail(email: string): Observable<any> {
		return this.http.post(
			this.config.endpoints.api + '/security/verify',
			{ email: email },
			this.httpOptions
		);
	}

	resetPassword(token: string, password: string): Observable<any> {
		return this.http
			.post(this.config.endpoints.api + '/security/reset', {
				token: token,
				password: password,
			})
			.pipe(timeout(this.config.timeout.api));
	}

	expirePassword(email: string, old_password: string, password: string): Observable<any> {
		return this.http
			.post(this.config.endpoints.auth + '/oauth/expired', {
				username: email,
				oldpassword: old_password,
				password: password,
				grant_type: 'password',
			})
			.pipe(timeout(this.config.timeout.api));
	}
}
