import { Injectable } from '@angular/core';

import { ProfileType } from '@fmlib/enums';
import { GlobalStore } from '@fmlib/interfaces';
import { DataService, GlobalService } from '@fm/services';

import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuService {
	private global: GlobalStore;

	private _menu: Subject<any> = new Subject<any>();

	constructor(
		private FM_Data: DataService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	init(): void {
		const menu = {
			reports: [],
			network: [],
			asset: [],
			associate: [],
			connect: [],
			system: [],
		};

		// BASIC
		menu.reports.push({
			title: 'DASHBOARD',
			icon: 'fa fa-tachometer-alt',
			parent: 'dashboard',
			state: ['dashboard'],
		});

		if (this.FM_Global.hasPermission('reports', 'view')) {
			menu.reports.push({
				title: 'Reports',
				icon: 'fa-solid fa-chart-bar',
				parent: 'reports',
				state: ['reports'],
			});
		}
		// EXPORTS
		if (this.FM_Global.hasPermission('scheduledexport', 'view')) {
			menu.reports.push({
				title: 'EXPORTS',
				icon: 'fa-solid fa-file-export',
				parent: 'exports',
				state: ['exports'],
			});
		}
		// REQUESTS
		if (this.global.user?.isDev) {
			menu.reports.push({
				title: 'REQUESTS',
				icon: 'fa-solid fa-paper-plane',
				parent: 'requests',
				state: ['requests'],
			});
		}

		if (this.FM_Global.hasPermission('app', 'view')) {
			menu.network.push({
				title: 'APPS',
				icon: this.FM_Data.typeInfo(ProfileType.APP).icon,
				parent: 'app',
				state: ['apps'],
			});
		}

		if (
			this.FM_Global.hasPermission('zone', 'view') &&
			this.FM_Global.getSetting('smart-connect')
		) {
			menu.network.push({
				title: 'ZONES',
				icon: this.FM_Data.typeInfo(ProfileType.ZONE).icon,
				parent: 'zone',
				state: ['zones'],
			});
		}

		if (this.FM_Global.hasPermission('site', 'view')) {
			menu.network.push({
				title: 'SITES',
				icon: this.FM_Data.typeInfo(ProfileType.SITE).icon,
				parent: 'site',
				state: ['sites'],
			});
		}

		if (
			this.FM_Global.hasPermission('geofence', 'view') &&
			this.FM_Global.getSetting('fm-geofence-active')
		) {
			menu.network.push({
				title: 'GEOFENCES',
				icon: this.FM_Data.typeInfo(ProfileType.GEOFENCE).icon,
				parent: 'geofence',
				state: ['geofences'],
			});
		}

		if (this.FM_Global.hasPermission('beacon', 'view')) {
			menu.network.push({
				title: 'BEACONS',
				icon: this.FM_Data.typeInfo(ProfileType.BEACON).icon,
				parent: 'beacon',
				state: ['beacons'],
			});
		}

		if (
			this.FM_Global.hasPermission('nfc', 'view') &&
			this.FM_Global.getSetting('smart-connect')
		) {
			menu.network.push({
				title: 'NFCS',
				icon: this.FM_Data.typeInfo(ProfileType.NFC).icon,
				parent: 'nfc',
				state: ['nfcs'],
			});
		}

		// PROGRAMS
		if (
			this.FM_Global.hasPermission('program', 'view') &&
			this.FM_Global.getSetting('asset-tracking')
		) {
			menu.asset.push({
				title: 'PROGRAMS',
				icon: this.FM_Data.typeInfo(ProfileType.PROGRAM).icon,
				parent: 'program',
				state: ['programs'],
			});
		}

		// ASSET TRACKING
		if (
			this.FM_Global.hasPermission('asset', 'view') &&
			this.FM_Global.getSetting('asset-tracking')
		) {
			menu.asset.push({
				title: 'ASSETS',
				icon: this.FM_Data.typeInfo(ProfileType.ASSET).icon,
				parent: 'asset',
				state: ['assets'],
			});
		}

		if (
			this.FM_Global.hasPermission('gateway', 'view') &&
			this.FM_Global.getSetting('asset-tracking')
		) {
			menu.asset.push({
				title: 'GATEWAYS',
				icon: this.FM_Data.typeInfo(ProfileType.GATEWAY).icon,
				parent: 'gateway',
				state: ['gateways'],
			});
		}

		// SMART CONNECTT
		if (
			this.FM_Global.hasPermission('payload', 'view') &&
			this.FM_Global.getSetting('smart-connect')
		) {
			menu.connect.push({
				title: 'PAYLOADS',
				icon: this.FM_Data.typeInfo(ProfileType.PAYLOAD).icon,
				parent: 'payload',
				state: ['payloads'],
			});
		}

		if (
			this.FM_Global.hasPermission('experience', 'view') &&
			this.FM_Global.getSetting('smart-connect')
		) {
			menu.connect.push({
				title: 'EXPERIENCES',
				icon: this.FM_Data.typeInfo(ProfileType.EXPERIENCE).icon,
				parent: 'experience',
				state: ['experiences'],
			});
		}

		if (
			this.FM_Global.hasPermission('journey', 'view') &&
			this.FM_Global.getSetting('smart-connect')
		) {
			menu.connect.push({
				title: 'JOURNEYS',
				icon: this.FM_Data.typeInfo(ProfileType.JOURNEY).icon,
				parent: 'journey',
				state: ['journeys'],
			});
		}

		// SMART ASSOCIATE
		if (
			this.FM_Global.hasPermission('device', 'view') &&
			this.FM_Global.getSetting('smart-associate')
		) {
			menu.associate.push({
				title: 'DEVICES',
				icon: this.FM_Data.typeInfo(ProfileType.DEVICE).icon,
				parent: 'device',
				state: ['devices'],
			});
		}

		if (
			this.FM_Global.hasPermission('push', 'view') &&
			this.FM_Global.getSetting('smart-associate') &&
			this.FM_Global.getSetting('fm-push-active') &&
			this.global.user?.isSuper
		) {
			menu.associate.push({
				title: 'PUSH',
				icon: this.FM_Data.typeInfo(ProfileType.PUSH).icon,
				parent: 'push',
				state: ['pushes'],
			});
		}

		if (
			this.FM_Global.hasPermission('task', 'view') &&
			this.FM_Global.getSetting('smart-associate')
		) {
			menu.associate.push({
				title: 'TASKS',
				icon: this.FM_Data.typeInfo(ProfileType.TASK).icon,
				parent: 'task',
				state: ['tasks'],
			});
		}

		// SYSTEM
		if (this.FM_Global.hasPermission('adminuser', 'view')) {
			menu.system.push({
				title: 'ADMINUSERS',
				icon: this.FM_Data.typeInfo(ProfileType.ADMINUSER).icon,
				parent: 'adminuser',
				state: ['adminusers'],
			});
		}

		if (this.FM_Global.hasPermission('company', 'view')) {
			/*
			menu.system.push({
				title: 'COMPANIES',
				icon: this.FM_Data.typeInfo('company').icon,
				parent: 'company',
				state: 'companys',
			});
			*/

			menu.system.push({
				title: 'COMPANY',
				icon: this.FM_Data.typeInfo(ProfileType.COMPANY).icon,
				parent: 'company',
				state: ['companies', this.global.company.id],
			});
		}

		if (this.FM_Global.hasPermission('partner', 'view')) {
			menu.system.push({
				title: 'PARTNERS',
				icon: this.FM_Data.typeInfo(ProfileType.PARTNER).icon,
				parent: 'partner',
				state: ['partners'],
			});
		}

		if (
			this.FM_Global.hasPermission('platformtask', 'view') &&
			this.FM_Global.getSetting('site-upload-access')
		) {
			menu.system.push({
				title: 'TASKS',
				icon: this.FM_Data.typeInfo(ProfileType.PLATFORMTASK).icon,
				parent: 'platformtask',
				state: ['platformtasks'],
			});
		}

		this._menu.next(menu);
	}

	getMenu(): Observable<any> {
		return this._menu.asObservable();
	}

	update(): void {
		this.init();
	}
}
