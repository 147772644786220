import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataService } from '@fm/services';
import { TypeInfo } from '@fmlib/interfaces';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'fm-icon',
	template: '<i class="fm-icon" [ngClass]="icon"></i>',
})
export class FmIconComponent implements OnInit {
	@Input() type;

	icon: string;

	constructor(private FM_Data: DataService) {}

	ngOnInit(): void {
		const typeInfo: TypeInfo = this.FM_Data.typeInfo(this.type);
		this.icon = typeInfo ? typeInfo.icon : '';
	}
}
