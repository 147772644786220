import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { cloneDeep, isEqual } from 'lodash-es';

@Component({
	selector: 'filter-geocoordinates',
	template: `<div class="filter-wrap">
		<div class="filter-custom-input">
			<div>
				<input
					placeholder="{{ 'LAT' | translate }}"
					name="lat"
					class="fm-input"
					type="number"
					[min]="-85"
					[max]="85"
					[ngModel]="geo.lat"
					(ngModelChange)="updateGeo($event, 'lat')" />
			</div>
			<div>
				<input
					placeholder="{{ 'LNG' | translate }}"
					name="lng"
					class="fm-input"
					type="number"
					[min]="-180"
					[max]="180"
					[ngModel]="geo.lng"
					(ngModelChange)="updateGeo($event, 'lng')" />
			</div>
			<div class="filter-custom-clear" (click)="clearCustom()">
				<i class="fa fa-times"></i>
			</div>
		</div>
		<ul>
			<li class="datepicker">
				<select
					class="fm-select"
					name="distance"
					[ngModel]="geo.distance"
					(ngModelChange)="updateGeo($event, 'distance')">
					<option *ngFor="let op of distanceOptions" [value]="op.id">
						{{ op.name | translate }}
					</option>
				</select>
			</li>
		</ul>
	</div>`,
})
export class FilterGeocoordinatesComponent implements OnInit {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	currentFilter;
	distanceOptions;
	geo;
	list;

	valChanged: Subject<string> = new Subject<string>();

	ngOnInit(): void {
		this.geo = {};

		this.distanceOptions = [
			{
				id: 1000,
				name: '1 km',
			},
			{
				id: 5000,
				name: '5 km',
			},
			{
				id: 10000,
				name: '10 km',
			},
			{
				id: 50000,
				name: '50 km',
			},
			{
				id: 100000,
				name: '100 km',
			},
			{
				id: 500000,
				name: '500 km',
			},
		];

		this.initFilter();

		this.valChanged.pipe(debounceTime(400)).subscribe((model) => {
			this.geo = model;
			this.runupdate();
		});
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		if (this.geo.lat && this.geo.lng && this.geo.distance) {
			this.filter = cloneDeep(this.geo);
		} else {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	updateGeo(val, type: string): void {
		this.geo[type] = val;
		this.valChanged.next(this.geo);
	}

	clearCustom(): void {
		delete this.geo.lat;
		delete this.geo.lng;
		this.runupdate();
	}

	initFilter(): void {
		this.currentFilter = cloneDeep(this.filter);
		this.geo = this.filter ? cloneDeep(this.filter) : {};

		if (!this.geo.distance) {
			this.geo.distance = 1000;
		}
	}

	clearFilter(): void {
		delete this.filter;
		this.geo = {
			distance: 1000,
		};
	}

	ngOnDestroy(): void {
		this.valChanged.unsubscribe();
	}
}
