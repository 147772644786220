import { Injectable } from '@angular/core';

import { ApiService, DataService } from '@fm/services';

@Injectable()
export class ExperienceService {
	private settings: any;

	constructor(
		private FM_Api: ApiService,
		private FM_Data: DataService
	) {
		this.settings = {
			populate: 'apps payload targetProfiles sites',
		};
	}

	populate(): string {
		return this.settings.populate;
	}

	save(obj, source): Promise<any> {
		let p;

		// PREPARE THE SAVE... UPDATE CRAP!!!!
		let temp = {
			name: obj.name,
			description: obj.description,
			isActive: obj.isActive,
			allowRepeats: obj.allowRepeats || false,
			maxAllowed: obj.maxAllowed || 0,
			maxAllowedInterval: obj.maxAllowedInterval || 'none',
			maxPerUser: obj.maxPerUser || 0,
			maxPerUserTtl: obj.maxPerUserTtl || 0,
			retarget: obj.retarget,
			hitCount: obj.hitCount,
			hitTimespan: obj.hitTimespan,
			beaconEvents: obj.beaconEvents,
			gender: obj.gender,
			matchAllTargets: obj.matchAllTargets || false,
			schedules: obj.schedules,
			payload: obj.payload,
			targetProfiles: obj.limitProfiles ? obj.targetProfiles : [],
			apps: obj.limitApps ? obj.apps : [],
			appMode: obj.appMode ? obj.appMode : 'any',
			appsExcluded: obj.appTarget === 'exclude',
			sites: obj.limitSites ? obj.sites : [],
			sitesExcluded: obj.siteTarget === 'exclude',
			tags: obj.tags,
		};

		// FLATTENT THE DATA
		temp = this.FM_Data.flattenData(temp);

		// SAVE OR UPDATE
		if (!obj.id) {
			p = this.FM_Api.connect('experiences').post({ populate: this.settings.populate }, temp);
		} else {
			// GOING TO PRUNE DATA
			temp = this.FM_Data.getChanges(source, temp);

			p = this.FM_Api.connect('experiences')
				.put(
					{
						id: obj.id,
						populate: this.settings.populate,
					},
					temp
				)
				.toPromise();
		}

		return p;
	}
}
