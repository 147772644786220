import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalService, ToolsService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';
import { tap } from 'rxjs';
import { SharedModule } from '../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'fm-news',
	templateUrl: './news.component.html',
})
export class NewsComponent implements OnInit {
	@Input() view;

	global: GlobalStore;
	news;

	constructor(
		private http: HttpClient,
		private FM_Global: GlobalService,
		private FM_Tools: ToolsService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		const version = this.global.config.version.substr(
			0,
			this.global.config.version.lastIndexOf('.')
		);

		this.http
			.get('/assets/json/versions.json')
			.pipe(
				tap((res) => {
					this.news = res[version];
					this.FM_Tools.setNews();
				})
			)
			.subscribe();
	}

	closeNews(): void {
		this.view.showNews = false;
	}
}
