import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Directive({
	selector: '[controlPanel]',
})
export class ControlPanelDirective implements OnInit {
	@Input() controlPanel;
	@Output() controlPanelEvent = new EventEmitter<string>();

	clickInside: boolean;

	@HostListener('click')
	clickin(): void {
		this.clickInside = true;
	}

	@HostListener('document:click')
	clickout(): void {
		if (!this.clickInside) {
			this.closePanel();
		}
		this.clickInside = false;
	}

	ngOnInit(): void {
		this.clickInside = true;
	}

	closePanel(): void {
		this.controlPanel = null;

		this.controlPanelEvent.emit(this.controlPanel);
	}

	clickClose(e: any): any {
		/*
		if (e.target === this.$element) {
			return false;
		}
		*/

		if (this.controlPanel) {
			this.closePanel();
		}
	}

	escClose(e): void {
		if (e.which === 27) {
			// 27 = esc key
			e.preventDefault();
			this.closePanel();
		}
	}
}
