import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@fm/services';

import { isEqual, isUndefined } from 'lodash-es';

@Component({
	selector: 'filter-settings',
	template: `<div class="filter-wrap">
		<ul class="fm-filter-actions">
			<li (click)="clearFilter()" [ngClass]="{ active: isActive() }" translate="ALL"></li>
			<li
				(click)="applyFilter(option.key)"
				[ngClass]="{ active: filter.require2FA === option.key }"
				*ngFor="let option of options"
				[translate]="option.label"></li>
		</ul>
	</div>`,
})
export class FilterSettingsComponent implements OnInit, OnChanges {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	options: any[];

	private currentFilter;

	constructor(
		private FM_Data: DataService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.options = [
			{
				key: true,
				label: this.translate.instant('MFA_ACTIVE'),
			},
			{
				key: { $ne: true },
				label: this.translate.instant('MFA_INACTIVE'),
			},
		];
	}

	ngOnChanges(): void {
		if (this.isActive()) {
			this.clearFilter();
		}

		this.currentFilter = {
			require2FA: this.FM_Data.clone(this.filter.require2FA),
		};
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		if (this.currentFilter && !isEqual(this.currentFilter.require2FA, this.filter.require2FA)) {
			this.currentFilter = {
				require2FA: this.FM_Data.clone(this.filter.require2FA),
			};
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	isActive(): boolean {
		return isUndefined(this.filter.require2FA);
	}

	applyFilter(status: boolean): void {
		if (status) {
			this.filter.require2FA = status;
		} else {
			delete this.filter.require2FA;
		}

		this.runupdate();
	}

	showAll(): void {
		delete this.filter.require2FA;
		this.runupdate();
	}

	clearFilter(): void {
		delete this.filter.require2FA;
	}
}
