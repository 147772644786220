import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore, SearchParams } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'select-zone-dialog',
	templateUrl: './select-zone-dialog.component.html',
})
export class SelectZoneDialogComponent implements OnInit, AfterViewInit {
	global: GlobalStore;
	selected;
	zones;
	search;

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SelectZoneDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.zones = {
			isLoading: false,
			isFailed: false,
		};

		this.search = {};
		this.selected = this.data.settings.selected;

		this.initSearch();
	}

	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	loadList(): void {
		if (this.zones.isLoading || this.zones.page > this.zones.pages) return;

		this.zones.isLoading = true;

		const params: SearchParams = {
			page: this.zones.page,
			sort: 'zoneName',
			populate: 'zone',
		};

		params.conditions = cloneDeep(this.data.settings.filter);

		if (this.search.qs && this.search.qs !== '') {
			params.conditions.zoneName = { $regex: '\\b' + this.search.qs, $options: 'i' };
		}

		this.FM_Api.connect('sitezones').query(params, (data, headers) => {
			this.zones.list = this.zones.list.concat(data);
			this.zones.page = this.zones.page + 1;
			this.zones.pages = parseInt(headers.get('X-Pages-Total'), 10);
			this.zones.total = parseInt(headers.get('X-Records-Total'), 10);

			this.zones.count = this.zones.list.length;

			this.zones.isLoading = false;
		});
	}

	initSearch(): void {
		this.zones.page = 1;
		this.zones.pages = 1;
		this.zones.total = 0;
		this.zones.list = [];

		this.loadList();
	}

	// SET SELECTED COMPANY
	selectItem(obj): void {
		this.selected = obj;
	}

	clearQuicksearch(): void {
		this.search.qs = null;
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.selected.zone);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
