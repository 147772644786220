<nav class="site-navbar">
	<ul class="site-navlist" *ngIf="menus?.reports.length">
		<li
			*ngFor="let item of menus.reports; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
	<ul class="site-navlist" *ngIf="menus?.network.length">
		<li class="navlist-title" translate="NETWORK"></li>
		<li
			*ngFor="let item of menus.network; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
	<ul class="site-navlist" *ngIf="menus?.asset.length">
		<li class="navlist-title" translate="SMART_TRACKING"></li>
		<li
			*ngFor="let item of menus.asset; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
	<ul class="site-navlist" *ngIf="menus?.connect.length">
		<li class="navlist-title" translate="SMART_CONNECT"></li>
		<li
			*ngFor="let item of menus.connect; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
	<ul class="site-navlist" *ngIf="menus?.associate.length">
		<li class="navlist-title" translate="SMART_ASSOCIATE"></li>
		<li
			*ngFor="let item of menus.associate; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
	<ul class="site-navlist" *ngIf="menus?.system.length">
		<li class="navlist-title" translate="SYSTEM"></li>
		<li
			*ngFor="let item of menus.system; trackBy: 'index' | trackByProperty"
			[ngClass]="{ active: stateActive(item) }">
			<a [routerLink]="item.state" routerLinkActive="active">
				<span class="fm-icon" [ngClass]="item.icon"></span>
				<span class="nav-title" [translate]="item.title"></span>
			</a>
		</li>
	</ul>
</nav>
