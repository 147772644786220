import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, fromEvent } from 'rxjs';

import { UtilsService } from '@fm/services';

import * as d3 from 'd3';

@Component({
	standalone: true,
	selector: 'login-bkgd',
	template: '<div class="canvas-container"></div>',
})
export class LoginBkgdComponent implements OnInit, OnDestroy {
	private dataset;

	private svg;
	private mode;
	private width;
	private height;

	private resizeObservable$: Observable<Event>;
	private resizeSubscription$: Subscription;

	constructor(private FM_Utils: UtilsService) {}

	ngOnInit(): void {
		this.mode = 'rect';
		this.dataset = [];

		this.width = window.innerWidth;
		this.height = window.innerHeight;

		this.gridData();

		// CREATE SVG
		this.svg = d3.select('.canvas-container').append('svg');
		this.svg.attr('width', this.width).attr('height', this.height);
		this.draw();

		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
			this.updateWindow();
		});
	}

	addItem(xpos: number, ypos: number, size: number, spacing: number): any {
		return {
			x: xpos * spacing, // FM_Utils.getRandom(0, 1),
			y: ypos,
			speed: this.FM_Utils.getRandomInt(2000, 5000),
			scale: 1,
			size: size,
			alpha: this.FM_Utils.getRandom(0.0, 0.3),
			color: this.FM_Utils.getRandomColor(),
		};
	}

	gridData(): void {
		let i, j, xpos, ypos, startX, size, spacing;

		startX = 0;
		ypos = 0;
		xpos = 0;
		spacing = 200;
		size = 200;

		// BIG ITEMS
		for (i = 0; i < 10; i++) {
			for (j = 0; j < 15; j++) {
				this.dataset.push(this.addItem(xpos, ypos, size, spacing));
				xpos = xpos + 1;
			}

			ypos = ypos + spacing;
			xpos = startX;
		}

		startX = 0;
		ypos = 0;
		xpos = 0;
		spacing = 100;
		size = 10;

		for (i = 0; i < 20; i++) {
			for (j = 0; j < 30; j++) {
				size = i % 2 === 1 && j % 2 === 1 ? 100 : 10;
				this.dataset.push(this.addItem(xpos, ypos, size, spacing));
				xpos = xpos + 1;
			}

			ypos = ypos + spacing;
			xpos = startX;
		}
	}

	/*
	randData () {
		let i;
		for(i= 0; i< 100; i++){
			dataset.push({
				x 		: FM_Utils.getRandom(0, 1),
				y 		: FM_Utils.getRandom(0, 1),
				speed 	: FM_Utils.getRandomInt(2000, 5000),
				scale 	: FM_Utils.getRandom(0, 1),
				size 	: FM_Utils.getRandomInt(10, 100),
				a 		: FM_Utils.getRandom(0.0, 0.15)
			});
		}
	}

	randData();
	*/

	/*
	move () {

		let coordinates = [0, 0];
		coordinates = d3.mouse(this);
		let x = coordinates[0];
		let y = coordinates[1];

		// REPOSISITON
		svg.selectAll('rect')
		.attr('x', (d, i)=> { return dataset[i].x * width * (width / x); })
		.attr('y', (d, i) =>{ return dataset[i].y * height * (height / y); })
	}
	*/

	draw(): void {
		// let m = d3.mouse(this);

		const dset = this.dataset;

		if (this.mode === 'circles') {
			this.svg
				.selectAll('circle')
				.data(this.dataset)
				.enter()
				.append('circle')
				.attr('id', (d, i) => {
					return 'item_' + i;
				})
				.attr('stroke-width', 2)
				.style('stroke-opacity', 0.25)
				.attr('r', (d, i) => {
					return dset[i].size;
				})
				.attr('cx', (d, i) => {
					return dset[i].x;
				})
				.attr('cy', (d, i) => {
					return dset[i].y;
				});
		} else {
			this.svg
				.selectAll('rect')
				.data(this.dataset)
				.enter()
				.append('rect')
				.style('fill-opacity', 0)
				.style('stroke-opacity', 0)

				.attr('x', (d, i) => {
					return dset[i].x - dset[i].size * 0.5;
				})
				.attr('y', (d, i) => {
					return dset[i].y - dset[i].size * 0.5;
				})
				.attr('width', (d, i) => {
					return dset[i].size;
				})
				.attr('height', (d, i) => {
					return dset[i].size;
				})
				.transition()
				// .attr('width', 0)
				// .attr('height', 0)

				// TRANSITION

				.duration((d, i) => {
					return dset[i].speed;
				})
				.style('fill-opacity', (d, i) => {
					return dset[i].alpha;
				})
				.style('stroke-opacity', 0.5);

			/*
			svg.selectAll('rect').on('mouseover', () =>{
				d3.select(this)
					.style('stroke', '#0F0');
			})
			.on('mouseout', () =>{
				d3.select(this)
					.style('stroke', '#FFF');
			});
			*/
		}

		/*
		d3.select('body').on('mousemove', () => {
			this.move();
		});
		*/
	}

	move(): void {
		const dset = this.dataset;
		// REPOSISITON
		this.svg
			.selectAll('rect')
			.transition()
			.duration((d, i) => {
				return dset[i].speed;
			})
			.style('fill-opacity', (d, i) => {
				return dset[i].alpha * this.FM_Utils.getRandom(0.0, 1.0);
			});
	}

	updateWindow(): void {
		const dset = this.dataset;

		this.width = window.innerWidth;
		this.height = window.innerHeight;

		this.svg.attr('width', this.width).attr('height', this.height);

		// REPOSISITON
		this.svg
			.selectAll('rect')
			.transition()
			.duration((d, i) => {
				return dset[i].speed;
			})
			.style('fill-opacity', (d, i) => {
				return dset[i].alpha * this.FM_Utils.getRandom(0.0, 1.0);
			})
			.attr('x', (d, i) => {
				return dset[i].x - dset[i].size * 0.5;
			})
			.attr('y', (d, i) => {
				return dset[i].y - dset[i].size * 0.5;
			});
	}

	// CLEANUP
	ngOnDestroy(): void {
		// REPOSISITON
		this.svg.selectAll('rect').remove();

		this.resizeSubscription$.unsubscribe();
	}
}
