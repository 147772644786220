import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	forwardRef,
} from '@angular/core';
import {
	AbstractControl,
	AsyncValidator,
	ControlValueAccessor,
	NG_ASYNC_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';

import { Observable, Subject, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { GlobalService, ToolsService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { ZxcvbnResult } from '@zxcvbn-ts/core/dist/types';
import { SharedModule } from '../shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'password-validate',
	templateUrl: './password-validate.component.html',
	styleUrls: ['./password-validate.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordValidateComponent),
			multi: true,
		},
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: forwardRef(() => PasswordValidateComponent),
			multi: true,
		},
	],
})
export class PasswordValidateComponent
	implements OnInit, OnDestroy, ControlValueAccessor, AsyncValidator
{
	@Output() passwordChange = new EventEmitter();

	@Input() token: string;
	@Input() user;

	@ViewChild('passwordMain', { read: ElementRef }) passwordMain;
	@ViewChild('passwordMatch', { read: ElementRef }) passwordMatch;

	password: string;

	protected MIN_STRENGTH = 2;

	private passwordOptions;

	global: GlobalStore;
	checks;
	passwordConfirm;
	passwordReuse: boolean;
	passwordStrength: number;
	showingPassword: boolean;
	warning;

	passwordUpdate: Subject<string> = new Subject<string>();

	constructor(
		private FM_Global: GlobalService,
		private FM_Tools: ToolsService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.checks = {};

		this.passwordReuse = this.FM_Global.getSetting('password-reuse-restriction');

		this.initPasswordOptions();

		this.passwordUpdate.subscribe((model) => {
			this.password = model;
			this.onChange(this.password);
		});
	}

	ngOnDestroy(): void {
		this.passwordUpdate.unsubscribe();
	}

	async initPasswordOptions(): Promise<any> {
		this.passwordOptions = await this.loadOptions();
		zxcvbnOptions.setOptions(this.passwordOptions);
	}

	async loadOptions(): Promise<any> {
		const zxcvbnCommonPackage = await import(
			/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
		);
		const zxcvbnEnPackage = await import(
			/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en'
		);

		return {
			dictionary: {
				...zxcvbnCommonPackage.dictionary,
				...zxcvbnEnPackage.dictionary,
			},
			graphs: zxcvbnCommonPackage.adjacencyGraphs,
			translations: zxcvbnEnPackage.translations,
		};
	}

	checkUse(pass: string): Observable<any> {
		const params: any = {
			password: pass,
		};

		if (!pass) {
			return of(null);
		} else {
			if (this.token) {
				params.token = this.token;
			} else {
				params.username = this.user.email;

				if (this.user.old_password) {
					params.currentPassword = this.user.old_password;
				}
			}

			return this.FM_Tools.passwordReuse(params);
		}
	}

	checkStength(pass: string): boolean {
		const r = zxcvbn(pass) as ZxcvbnResult;
		this.passwordStrength = pass && r ? (pass.length > 7 && r.score) || 0 : null;

		this.warning = r ? r.feedback.warning : null;

		return this.passwordStrength >= this.MIN_STRENGTH;
	}

	checkUsername(username: string, pass: string): boolean {
		return username.toLowerCase() !== pass.toLowerCase();
	}

	updatePassword(): void {
		this.passwordUpdate.next(this.password);
	}

	togglePassword(): void {
		const type = this.passwordMain.nativeElement.type;

		if (type === 'text') {
			this.showingPassword = false;
			this.passwordMain.nativeElement.type = 'password';
			this.passwordMatch.nativeElement.type = 'password';
		} else {
			this.showingPassword = true;
			this.passwordMain.nativeElement.type = 'text';
			this.passwordMatch.nativeElement.type = 'text';
		}
	}

	// NG MODEL FUNCTIONS
	writeValue(value: any): void {
		this.password = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// do nothing
	}

	onChange = (_: any): any => {};
	onTouched = (_: any): any => {};

	passwordChecks(): boolean {
		this.checks = {};

		if (!this.password) {
			this.password = '';
		} else {
			this.checks.length = this.password && this.password.length >= 8 ? 'valid' : undefined;
			this.checks.lower = this.password && /[a-z]/.test(this.password) ? 'valid' : undefined;
			this.checks.number = this.password && /\d/.test(this.password) ? 'valid' : undefined;
			this.checks.upper = this.password && /[A-Z]/.test(this.password) ? 'valid' : undefined;

			// NOT CURRENTLY REQUIRED
			// this.checks.special = this.password && /[#?!@$%^&*-]/.test(this.password) ? 'valid' : undefined;

			// DO PASSWORDS MATCH
			this.checks.match = this.password === this.passwordConfirm ? 'valid' : undefined;

			// CHECK PASSWORD STRENGTH
			this.checks.strength = this.checkStength(this.password) ? 'valid' : undefined;

			if (this.user) {
				if (this.user.username) {
					this.checks.username = this.checkUsername(this.user.username, this.password)
						? 'valid'
						: undefined;
				} else if (this.user.email) {
					this.checks.username = this.checkUsername(this.user.email, this.password)
						? 'valid'
						: undefined;
				}
			}
		}

		return Object.keys(this.checks).every((v) => this.checks[v] === 'valid');
	}

	// VALIDATION
	validate(control: AbstractControl): Observable<ValidationErrors | null> {
		if (!control.value) {
			return of({ invalid: true });
		}

		const passedChecks = this.passwordChecks();

		// ADD VALIDATOR ERRORS
		if (!passedChecks) {
			return of({ invalid: true });
		} else if (this.user.id || this.token) {
			return of(control.value).pipe(
				delay(400),
				distinctUntilChanged(),
				switchMap((value) => this.checkUse(value)),
				map((isValid: boolean) => {
					this.checks.reused = !isValid;
					return isValid ? null : { invalid: true };
				}, first()),
				catchError(() => {
					this.checks.error = true;
					return of({ invalid: true });
				})
			);
		} else if (!this.user.id) {
			// NEW USER
			return of(null);
		}
	}

	onValidationChange = (): any => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidationChange = fn;
	}
}
