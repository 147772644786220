import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm/confirm-dialog.component';

import { ModalSizes } from '@fmlib/enums';

import { reject } from 'lodash-es';

const NOOP = (): any => {};

@Component({
	selector: 'taxonomies-list',
	templateUrl: './taxonomies-list.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TaxonomiesListComponent),
			multi: true,
		},
	],
})
export class TaxonomiesListComponent implements OnInit, ControlValueAccessor {
	@Input() value;
	@Input() readonly: boolean;

	search;

	constructor(private dialog: MatDialog) {}

	ngOnInit(): void {
		this.search = {};
		this.search.filtered = this.value;
	}

	async loadModal(): Promise<void> {
		const { TaxonomiesDialogComponent } = await import(
			/* webpackPrefetch: true */
			'./taxonomies-dialog.component'
		);

		const dialogRef = this.dialog.open(TaxonomiesDialogComponent, {
			panelClass: ModalSizes.Select,
			data: {
				selected: this.value,
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.value = selected;
				this.onChange(this.value);
			}
		});
	}

	// REMOVE TAXONOMY
	removeItem(t): void {
		this.value = reject(this.value, t);
		this.onChange(this.value);
	}

	// REMOVE ALL
	removeAll(): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			panelClass: ModalSizes.Confirm,
			data: {
				message: 'WARNING_REMOVE_ALL',
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.value = [];
				this.onChange(this.value);
			}
		});
	}

	filterList(list: any[], qs: string): any[] {
		if (qs) {
			this.search.filtered = list.filter((obj) => {
				return obj.name.toLowerCase().indexOf(qs.toLowerCase()) !== -1;
			});
		} else {
			this.search.filtered = list;
		}

		return this.search.filtered;
	}

	// NG MODEL FUNCTIONS
	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// do nothing
	}

	protected onChange: (_: any) => void = NOOP;
	protected onTouched: (_: any) => void = NOOP;
}
