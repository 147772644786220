import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[stop-propagation]',
})
export class StopPropagationDirective {
	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		event.stopPropagation();
		event.preventDefault();
	}
}
